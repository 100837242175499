import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

import dot_com from '../../assets/images/domain_img01.png';
import dot_net from '../../assets/images/domain_img02.png';
import dot_xyz from '../../assets/images/xyz.png';
import dot_biz from '../../assets/images/domain_img04.png';
import dot_info from '../../assets/images/domain_img05.png';
import { useSelector } from 'react-redux';
import { currencies } from '../../constants/currencies';

const DomainList = () => {
    const { currency } = useSelector(state => state.currency);
    const domainPrice = {
        BDT: {
            dot_com: "TK 1199",
            dot_net: "TK 1599",
            dot_biz: "TK 1899",
            dot_info: "TK 2151",
            dot_xyz: "TK 350"
        },
        USD: {
            dot_com: "$10.42",
            dot_net: "$13.90",
            dot_biz: "$16.51",
            dot_info: "$18.26",
            dot_xyz: "$2.99"
        }
    }
    return (
        <div className="domain-list">
            <Container>
                <Row>
                    <Col md={{ span: 10, offset: 1 }}>
                        {currency === currencies.BDT ?
                            <ul>
                                <li>
                                    <Image src={dot_com} />
                                    <span>{domainPrice.BDT.dot_com}</span>
                                </li>
                                <li>
                                    <Image src={dot_net} />
                                    <span>{domainPrice.BDT.dot_net}</span>
                                </li>
                                <li>
                                    <Image src={dot_biz} />
                                    <span>{domainPrice.BDT.dot_biz}</span>
                                </li>
                                <li className="d-md-none d-xl-block">
                                    <Image src={dot_info} />
                                    <span>{domainPrice.BDT.dot_info}</span>
                                </li>
                                <li className="res-none d-md-none d-xl-block">
                                    <Image src={dot_xyz} />
                                    <span>{domainPrice.BDT.dot_xyz}</span>
                                </li>
                            </ul>
                            : <ul>
                                <li>
                                    <Image src={dot_com} />
                                    <span>{domainPrice.USD.dot_com}</span>
                                </li>
                                <li>
                                    <Image src={dot_net} />
                                    <span>{domainPrice.USD.dot_net}</span>
                                </li>
                                <li>
                                    <Image src={dot_biz} />
                                    <span>{domainPrice.USD.dot_biz}</span>
                                </li>
                                <li className="d-md-none d-xl-block">
                                    <Image src={dot_info} />
                                    <span>{domainPrice.USD.dot_info}</span>
                                </li>
                                <li className="res-none d-md-none d-xl-block">
                                    <Image src={dot_xyz} />
                                    <span>{domainPrice.USD.dot_xyz}</span>
                                </li>
                            </ul>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DomainList;
