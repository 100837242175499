import React, { createContext } from "react";
import { v4 as uuid } from "uuid";

export const FaqContext = createContext();
const FaqContextProvider = (props) => {
  //   Faq Default
  const faqDefault = [
    {
      id: uuid(),
      question: "What is web hosting?",
      answer:
        "Web hosting is the service that allows your website or web application available to be viewed by others on the internet. Everything on the internet usually needs a web host. <br/> For example, if you have any business and you want to sell your products globally then it is very essential to make it available on the web and for that, you will need a web hosting service."
    },
    {
      id: uuid(),
      question: "What is domain name?",
      answer:
        "A domain name is simply a human-readable form of an IP address or in other words, a domain name is the address of your website that people type in the browser URL bar to visit your website. Every website has a domain name, and it’s completely unique to that site."
    },
    {
      id: uuid(),
      question: " What is shared web hosting?",
      answer:
        "Shared web hosting is a type of hosting service that allows multiple websites to share a physical web server and its resources under the hosted websites or web applications. Shared web hosting is low in cost and gives a high range of response and performance."
    },
    {
      id: uuid(),
      question: "What is Disk Space & Bandwidth??",
      answer:
        "Disk space is the amount of space available on the server for storing the content of your website or web application. That content includes files, images, videos, databases, among others. <br/> <br/> Bandwidth in website hosting is the amount of data that a website can deliver to its visitors in a given amount of time."
    },
    {
      id: uuid(),
      question: "What is Business Hosting?",
      answer:
        "In business hosting, you will get more powerful web hosting resources and configuration than shared hosting. Business hosting helps in filling the gap between the shared hosting and superior level VPS or dedicated server. "
    },
    {
      id: uuid(),
      question: "What is SSL security?",
      answer:
        "SSL stands for Secure Sockets Layer. SSL is a security protocol that generates an encrypted link between a web server and a web browser. An SSL certificate authenticates a website's identity and enables an encrypted connection. Companies and organizations require to add an SSL certificate to their websites to secure online transactions and keep their customer's information private and secure."
    },
    {
      id: uuid(),
      question: "Can I buy a domain name without buying hosting?",
      answer:
        "Yes, you can buy domain names without buying/having a hosting package with us."
    },

    {
      id: uuid(),
      question: "Will you provide domain control panel?",
      answer:
        " Yes, you will get the domain control panel including all the necessary accesses."
    },

    {
      id: uuid(),
      question: "Is your technical support 24x7?",
      answer:
        " We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services."
    },
    {
      id: uuid(),
      question: "Do you offer a money-back guarantee?",
      answer:
        " We offer a hassle-free 30 DAYS money-back guarantee on Shared, Business and Reseller web hosting packages. If you are unsatisfied for any reason, simply send us a refund request ticket, and we'll back your money within 7 working days"
    },
    {
      id: uuid(),
      question: "What payment methods do you accept?",
      answer:
        " All of the accounts are set up on a pre-pay basis. Although levohost.com reserves the right to change the prices of accounts or services at any time all pricing is guaranteed for the period of prepayment. Payments are accepted by Bkash, Roket, Internet Bank Transfers/ Cheque/ Cash deposit to our bank a/c for customers in Bangladesh. For customers outside Bangladesh, Payments are accepted through Paypal, skrill, Payoneer."
    }
    ,
    {
      id: uuid(),
      question: "Do you offer web designing, maintenance and programming services?",
      answer:
        "Yes, We do offer Web Designing, Maintenance and Programming services. Please contact with our support team (support@levohost.com) for more details."
    }


  ];
  // Faq shared hosting
  const faqSharedHosting = [
    {
      id: uuid(),
      question: " What is Web Hosting?",
      answer:
        "Web hosting is the service that allows your website or web application available to be viewed by others on the internet. Everything on the internet usually needs a web host. <br/>  For example, if you have any business and you want to sell your products globally then it is very essential to make it available on the web and for that, you will need a web hosting service."
    },
    {
      id: uuid(),
      question: "What is shared web hosting?",
      answer:
        "Shared web hosting is a type of hosting service that allows multiple websites to share a physical web server and its resources under the hosted websites or web applications. Shared web hosting is low in cost and gives a high range of response and performance."
    },
    {
      id: uuid(),
      question: "What is Disk Space & Bandwidth?",
      answer:
        "Disk space is the amount of space available on the server for storing the content of your website or web application. That content includes files, images, videos, databases, among others.<br/> <br/>  Bandwidth in website hosting is the amount of data that a website can deliver to its visitors in a given amount of time.."
    },
    {
      id: uuid(),
      question: "Should I need to register a domain to buy a Shared Hosting plan?",
      answer:
        "You do not need to buy a domain name, but you must have a domain name to set up with your hosting plan."
    },
    {
      id: uuid(),
      question: "Can I upgrade my hosting plan at a later stage?",
      answer:
        "Yes, You can upgrade your hosting plan at any time. For this, simply contact our support center by support ticket mentioning the package you want to upgrade. "
    },
    {
      id: uuid(),
      question: "Can I get a free SSL with the Shared Hosting plan?",
      answer:
        "Yes, you will get a free SSL certificate with any of our shared hosting plans."
    },
    {
      id: uuid(),
      question: " Will Shared Web Hosting work with my WordPress website?",
      answer:
        "Yes, With our Softaculous one-click installer you’ll get your WordPress website up and running in no time."
    },
    {
      id: uuid(),
      question: " Does your hosting come with a control panel?",
      answer:
        "YES, With our cPanel’s responsive interface, you can manage your web hosting from anywhere, whether you are using a laptop, desktop, tablet, or smartphone."
    },
    {
      id: uuid(),
      question: " Will my account be backed up?",
      answer:
        "We backup all of your data daily & weekly basis to protect against hardware failure or accidental erasure. Our Daily & weekly data backups that ensure the Website's safekeeping of files and databases. Backups are kept for up to a month. All backups are kept separately from hosting storage."
    },
    {
      id: uuid(),
      question: "Can you help me to transfer from my current web host?",
      answer:
        "If you have hosted your website with another hosting company and want to migrate it to LevoHost, you can easily do it in a hassle-free manner. All you need to do is choose a hosting plan and then submit the migration request form. LevoHost Support Team will then schedule the migration and complete it for you free of cost."
    }


  ];
  // Faq cheap hosting
  const faqCheapHosting = [
    {
      id: uuid(),
      question: " What is Web Hosting?",
      answer:
        "Web hosting is the service that allows your website or web application available to be viewed by others on the internet. Everything on the internet usually needs a web host. <br/>  For example, if you have any business and you want to sell your products globally then it is very essential to make it available on the web and for that, you will need a web hosting service."
    },
    {
      id: uuid(),
      question: "What is cheap web hosting?",
      answer:
        "Shared web hosting is a type of hosting service that allows multiple websites to share a physical web server and its resources under the hosted websites or web applications. Shared web hosting is low in cost and gives a high range of response and performance."
    },
    {
      id: uuid(),
      question: "What is Disk Space & Bandwidth?",
      answer:
        "Disk space is the amount of space available on the server for storing the content of your website or web application. That content includes files, images, videos, databases, among others.<br/> <br/>  Bandwidth in website hosting is the amount of data that a website can deliver to its visitors in a given amount of time.."
    },
    {
      id: uuid(),
      question: "Should I need to register a domain to buy a Shared Hosting plan?",
      answer:
        "You do not need to buy a domain name, but you must have a domain name to set up with your hosting plan."
    },
    {
      id: uuid(),
      question: "Can I upgrade my hosting plan at a later stage?",
      answer:
        "Yes, You can upgrade your hosting plan at any time. For this, simply contact our support center by support ticket mentioning the package you want to upgrade. "
    },
    {
      id: uuid(),
      question: "Can I get a free SSL with the Shared Hosting plan?",
      answer:
        "Yes, you will get a free SSL certificate with any of our shared hosting plans."
    },
    {
      id: uuid(),
      question: " Will Shared Web Hosting work with my WordPress website?",
      answer:
        "Yes, With our Softaculous one-click installer you’ll get your WordPress website up and running in no time."
    },
    {
      id: uuid(),
      question: " Does your hosting come with a control panel?",
      answer:
        "YES, With our cPanel’s responsive interface, you can manage your web hosting from anywhere, whether you are using a laptop, desktop, tablet, or smartphone."
    },
    {
      id: uuid(),
      question: " Will my account be backed up?",
      answer:
        "We backup all of your data daily & weekly basis to protect against hardware failure or accidental erasure. Our Daily & weekly data backups that ensure the Website's safekeeping of files and databases. Backups are kept for up to a month. All backups are kept separately from hosting storage."
    },
    {
      id: uuid(),
      question: "Can you help me to transfer from my current web host?",
      answer:
        "If you have hosted your website with another hosting company and want to migrate it to LevoHost, you can easily do it in a hassle-free manner. All you need to do is choose a hosting plan and then submit the migration request form. LevoHost Support Team will then schedule the migration and complete it for you free of cost."
    }
  ];

  // Faq Business hosting
  const faqBusinessHosting = [
    {
      id: uuid(),
      question: "What is Business Hosting?",
      answer:
        "In business hosting, you will get more powerful web hosting resources and configuration than shared hosting. Business hosting helps in filling the gap between the shared hosting and superior level VPS or dedicated server. "
    },
    {
      id: uuid(),
      question: "Is business hosting the right choice for small businesses?",
      answer:
        "Definitely yes, business hosting will give you more powerful hosting resources and configuration than shared hosting. <br/> If you have a small to moderate level or even high-level business website then our business hosting plan can be a great choice of you. "
    },
    {
      id: uuid(),
      question: "What kind of Business hosting plan do I need??",
      answer:
        "Talk to one of our web hosting experts who will review your needs and suggest a tailored hosting solution that will match your specific business reality and needs."
    },
    {
      id: uuid(),
      question: " Can I get a free SSL with Business Hosting packages?",
      answer:
        "Yes, You will get a free let's encrypt SSL certificate with any of our business hosting packages."
    },
    {
      id: uuid(),
      question: "Can I get a free Domain Registration with Business Hosting packages? ",
      answer:
        "Yes, You will get a FREE .COM domain registration with any of our business hosting packages above. For that, you must purchase at least 1 year of the business hosting package."
    },
    {
      id: uuid(),
      question: "If I already have a website, can I transfer it to your Web hosting?",
      answer:
        "If you have hosted your website with another hosting company and want to migrate it to LevoHost, you can easily do it in a hassle-free manner. All you need to do is choose a hosting plan and then submit the migration request form. LevoHost Support Team will then schedule the migration and complete it for you free of cost."
    },
    {
      id: uuid(),
      question: " Can I Upgrade from my Shared Hosting packages to Business Hosting?",
      answer:
        "Yes, you can upgrade from your current shared hosting package to any of our business hosting packages at any time. NO setup fee needed."
    },
    {
      id: uuid(),
      question: "Will my account be backed up?",
      answer:
        "We backup all of your data daily & weekly basis to protect against hardware failure or accidental erasure. Our Daily & weekly data backups that ensure the Website's safekeeping of files and databases. Backups are kept for up to a month. All backups are kept separately from hosting storage."
    },
    {
      id: uuid(),
      question: "Do you offer a money-back guarantee?",
      answer:
        "We offer a hassle-free 30 DAYS money-back guarantee to our Business web hosting packages. If you are unsatisfied for any reason, simply send us a refund request ticket, and we'll back your money within 7 working days."
    }
  ];
  // Faq Business hosting
  const faqResellerHosting = [
    {
      id: uuid(),
      question: "What is Reseller Hosting? ",
      answer:
        "Reseller web hosting is a Web hosting business model in which a Web hosting provider allows a number of or all of their Web hosting services to be sold by an individual or third-party organization. Reseller web hosting enables an individual or third-party organization to act as a Web hosting provider without the need to build, operate, and manage a Web hosting infrastructure."
    },
    {
      id: uuid(),
      question: " What is White labeled Reseller Hosting? ",
      answer:
        "White-labeled reseller hosting is a service provided by one company that other parties re-brand in order to hide the connection to the company owner. This feature is significant when you are trying to resell the hosting services of other companies."
    },
    {
      id: uuid(),
      question: "Do you offer Reseller Hosting Plans for Bulk buyers?",
      answer:
        "YES, levohost.com offers Reseller Hosting Plans for bulk buyers"
    },
    {
      id: uuid(),
      question: "What is the uptime of your servers?",
      answer:
        "We understand the uptime importance. We’re so confident in our tech, that our 99.99% Uptime Guarantee will put your $$ back in your pocket if your site goes down."
    },
    {
      id: uuid(),
      question: "Can you help me to transfer from my current web host?",
      answer:
        "If you have hosted your website with another hosting company and want to migrate it to LevoHost, you can easily do it in a hassle-free manner. All you need to do is choose a hosting plan and then submit the migration request form. LevoHost Support Team will then schedule the migration and complete it for you free of cost."
    },
    {
      id: uuid(),
      question: "If my business grows, can I upgrade at any time?",
      answer:
        "Yes, You can upgrade your Reseller hosting plan at any time. For this, simply contact our support center by support ticket mentioning the package you want to upgrade. "
    },

    {
      id: uuid(),
      question: "Do you offer a money-back guarantee?",
      answer:
        "We offer a hassle-free 30 DAYS money-back guarantee to our Reseller web hosting packages. If you are unsatisfied for any reason, simply send us a refund request ticket, and we'll back your money within 7 working days.."
    },
    {
      id: uuid(),
      question: " Can I get a free SSL with Reseller Hosting packages?",
      answer:
        "Yes, You will get a free let's encrypt SSL certificate with any of our Reseller hosting packages."
    },
    {
      id: uuid(),
      question: "Is your technical support 24x7?",
      answer:
        "We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services."
    }
  ];
  // Faq Business hosting
  const faqVPSHosting = [
    {
      id: uuid(),
      question: "What Is A VPS Hosting?",
      answer:
        "Virtual Private Server hosting, aka VPS hosting, is a hosting service that uses virtualization technology to give you dedicated (private) resources on a server with multiple users. VPS is a more secure, faster and stable solution than shared web hosting."
    },
    {
      id: uuid(),
      question: "Can I get root access?",
      answer:
        "YES, you will get full root acces. You can take full control of your VPS from our customer portal."
    },
    {
      id: uuid(),
      question: "What’s the difference between managed and unmanaged VPS hosting?",
      answer:
        "With unmanaged cloud VPS hosting, the web hosting provider is only responsible for the physical server and the server's availability. That means the user is totally responsible for the regular maintenance, upgrades, installation of software, etc. <br/> In managed cloud VPS hosting, the web hosting provider is totally responsible for handling all of your data backup needs. They conduct regular service checks to make sure a smooth data backup process."
    },
    {
      id: uuid(),
      question: "How do I connect to my VPS?",
      answer:
        "You will need to log in to your VPS via SSH (Secure Shell), using the IP address, user name, and password provided to your email when you received your order confirmation. From a Windows platform, we recommend that you log in using the Putty software. On a Linux distribution, you can connect directly via the terminal."
    },
    {
      id: uuid(),
      question: "Is my VPS backed up?",
      answer:
        "levohost.com does not take backups of the data hosted on your VPS. You are totally responsible for ensuring that your VPS is backed up. To make backups, you can use the following options: manual backup (snapshots), or automated backup."
    },
    {
      id: uuid(),
      question: "What is the uptime of your servers?",
      answer:
        "We understand the uptime importance. We’re so confident in our tech, that our 99.99% Uptime Guarantee will put your $$ back in your pocket if your site goes down."
    },
    {
      id: uuid(),
      question: "Do you provide a Static IP address for VPS plans?",
      answer:
        "YES, All VPS hosting plans provide the user with a Static IP address."
    }
    ,
    {
      id: uuid(),
      question: "Is your technical support 24x7?",
      answer:
        "We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services."
    }
    ,
    {
      id: uuid(),
      question: "What if I’m not satisfied with the server? Is there any money back guarantee?  ",
      answer:
        "Unfortunately, NO Money Back Guarantee on cloud Linux VPS hosting. In a rare incident whereby you are not satisfied with the server or our services, please get in touch with our billing department & we will see what we can do in the first 72 hours of service. "
    }



  ];
  // Faq Business hosting
  const faqDedicatedHosting = [
    {
      id: uuid(),
      question: "What Is A Dedicated Hosting?",
      answer:
        "A dedicated server is a single computer in a network that is entirely dedicated to an individual, organization, or application. Dedicated servers are ideal for large organizations or websites with much higher traffic."
    },
    {
      id: uuid(),
      question: "How many IP addresses can I have?",
      answer:
        "By default every Dedicated Server is provided with 1 static IP address You can buy as many required at additional cost."
    },
    {
      id: uuid(),
      question: "Can i upgrade my Dedicated Server?",
      answer:
        "Hardware changes can be made at any time to any of our servers. Changes may affect your setup and monthly/yearly renewal charges."
    },
    {
      id: uuid(),
      question: "Who will have control of the server?",
      answer:
        "This is your own dedicated server. You will have complete root access control over the server."
    },
    {
      id: uuid(),
      question: "Do I need managed server or a dedicated server?",
      answer:
        "If you don’t want to maintain server setup install update patches and DevOps then you should go with our managed dedicated server package and if you can maintain the server on your own then you should select an unmanaged dedicated server package."
    },
    {
      id: uuid(),
      question: "What access do I get?",
      answer:
        "You get root SSH access & administrator privileges to control, manage the server."
    },
    {
      id: uuid(),
      question: "What are the Operating System (OS) options available?",
      answer:
        "We provide CentOS, Fedora, Ubuntu, Debian option in OS. You will get the option to choose the OS at the time of sign-up process"
    },
    {
      id: uuid(),
      question: "How long does it take to set up a dedicated server?",
      answer:
        "Our stock servers are generally installed within an hour from the point of order, But some software options can increase this installation time."
    },
    {
      id: uuid(),
      question: "What payment methods do you accept?",
      answer:
        "All of the accounts are set up on a pre-pay basis. Although levohost.com reserves the right to change the prices of accounts or services at any time all pricing is guaranteed for the period of prepayment. Payments are accepted by Bkash, Roket, Internet Bank Transfers/ Cheque/ Cash deposit to our bank a/c for customers in Bangladesh. For customers outside Bangladesh, Payments are accepted through Paypal, skrill, Payoneer."
    }
  ];
  // Faq Business hosting
  const faqSSLHosting = [
    {
      id: uuid(),
      question: "What is SSL security?",
      answer:
        "SSL stands for Secure Sockets Layer. SSL is a security protocol that generates an encrypted link between a web server and a web browser. An SSL certificate authenticates a website's identity and enables an encrypted connection. Companies and organizations require to add an SSL certificate to their websites to secure online transactions and keep their customer's information private and secure."
    },
    {
      id: uuid(),
      question: "What is TLS certificate?",
      answer:
        "Transport Layer Security, aka TLS, is a protocol that establishes an encrypted session between two computers on the Internet world. TLS is actually just a modern version of SSL"
    },
    {
      id: uuid(),
      question: "What does HTTPS mean?",
      answer:
        "HTTPS stands for hypertext transfer protocol. HTTPS is the secure extension or version of HTTP. HTTPS protocol is mainly used for providing security to the data sent between a website and the web browser."
    },
    {
      id: uuid(),
      question: "What are the types of SSL certificates?",
      answer:
        "There are different types of SSL certificates with different validation levels. The six main types are: EV SSL, OV SSL,DV SSL, Wildcard, MDC and UCC SSL"
    },
    {
      id: uuid(),
      question: "Do I need SSL?",
      answer:
        "Yes. If you have a website, SSL is needed. below are 6 Reasons why SSL is a must-have for any website: <br/> 1. Website Security <br/> 2. Search Engine Ranking <br/> 3. Trustworthy Brand <br/> 4. Satisfy PCI Requirements <br/> 5. Speed Up Your Site With HTTP/2 <br/> 6. Enhances User Experience <br/> "
    },
    {
      id: uuid(),
      question: "What is a certificate signing request or CSR?",
      answer:
        "A Certificate Signing Request, aka CSR, is a block of encoded text forwarded to a Certificate Authority (CA) when users apply for an SSL/TLS certificate."
    },
    {
      id: uuid(),
      question: "Will having SSL certificate help with SEO?",
      answer:
        "To give a safer web browsing experience to the user from 2018 onwards, Google has decided to flag the websites which do not have an SSL/TLS Certificate installed on their website. HTTPS provides websites with a great SEO ranking boost. Google rewards the sites with a valid SSL certificate by ranking them higher. "
    },
    {
      id: uuid(),
      question: "What type of customer service do you offer?",
      answer:
        "We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services."
    },
    {
      id: uuid(),
      question: "What payment methods do you accept?",
      answer:
        "All of the accounts are set up on a pre-pay basis. Although levohost.com reserves the right to change the prices of accounts or services at any time all pricing is guaranteed for the period of prepayment. Payments are accepted by Bkash, Roket, Internet Bank Transfers/ Cheque/ Cash deposit to our bank a/c for customers in Bangladesh. For customers outside Bangladesh, Payments are accepted through Paypal, skrill, Payoneer."
    }
  ];

  // Faq Business hosting
  const faqDomainRegisterBD = [
    {
      id: uuid(),
      question: "what is .BD Domain?",
      answer:
        "BD domain is the country code top-level domain (ccTLD) for Bangladesh. It is administered by Bangladesh Telecommunications Company Limited (BTCL). Currently, registration is available to anyone under the .COM.BD, .NET.BD, .GOV.BD, .ORG.BD, MIL.BD and .EDU.BD domain extension."
    },
    {
      id: uuid(),
      question: "What is the minimum registration period of .BD domain?",
      answer:
        ".BD domain must be registered for a minimum of 2 years."
    },
    {
      id: uuid(),
      question: "How long does it take for BD domain registration?",
      answer:
        "It takes a maximum of three (03) working days to activate the domain upon submitting order."
    },
    {
      id: uuid(),
      question: "What is the .BD domain renewal late fee after the domain expired?",
      answer:
        "Renewal late fee is 1200 TK"
    },
    {
      id: uuid(),
      question: "What is BTCL domain renewal period?",
      answer:
        "2/3/4/5/10 years."
    },
    {
      id: uuid(),
      question: "Will you give me the domain control panel? ",
      answer:
        "YES, you will get the full domain control panel."
    },
    {
      id: uuid(),
      question: "Is there any money-back guarantee for BD Domain? ",
      answer:
        "Unfortunately, NO Money Back Guarantee on .BD DOMAIN. Because BTCL does not have any money-back guarantee."
    },
    {
      id: uuid(),
      question: "Can I buy a domain name without buying hosting?",
      answer:
        "Yes, you can buy domain names without buying/having a hosting package with us."
    },
    {
      id: uuid(),
      question: "What payment methods do you accept?",
      answer:
        "All of the accounts are set up on a pre-pay basis. Although levohost.com reserves the right to change the prices of accounts or services at any time all pricing is guaranteed for the period of prepayment. Payments are accepted by Bkash, Roket, Internet Bank Transfers/ Cheque/ Cash deposit to our bank a/c for customers in Bangladesh. For customers outside Bangladesh, Payments are accepted through Paypal, skrill, Payoneer."
    }
  ];
  // Faq Business hosting
  const faqDomainRegister = [
    {
      id: uuid(),
      question: "What is a Domain Name?",
      answer:
        "A domain name is simply a human-readable form of an IP address or in other words, a domain name is the address of your website that people type in the browser URL bar to visit your website. Every website has a domain name, and it’s completely unique to that site."
    },
    {
      id: uuid(),
      question: "How long does it take before my domain name is active?",
      answer:
        "It takes 24-48 hours (usually much sooner) for your domain name to propagate to all of the root DNS servers and visible to all users of the internet. Sometimes propagation delays due to internet providers, they have to update their records to reflect the changes. "
    },
    {
      id: uuid(),
      question: "Can I buy a domain name without buying hosting?",
      answer:
        "Yes, you can buy domain names without buying/having a hosting package with us."
    },
    {
      id: uuid(),
      question: "Can I transfer my domain from my current registrar to levohost.com ?",
      answer:
        "Yes, you can transfer your domain name from your current registrar/provider to us. If you would like to transfer your domain to levohost.com, please visit our Domain Transfer page."
    },
    {
      id: uuid(),
      question: "For how many years can I buy any domains?",
      answer:
        "You can Purchase or buy domains from 1-10 years."
    },
    {
      id: uuid(),
      question: "How will I know when a domain is to be renewed? Will levohost.com send any renewal intimation to my mail id?",
      answer:
        "You can view the domain expiry date from our customer portal. levohost.com will send an email as a courtesy and it is the responsibility of the domain owner to renew before the expiry date."
    },
    {
      id: uuid(),
      question: "Is it possible to hide all my Domain-based information through WHOIS protection?",
      answer:
        "Yes, it is possible only for a specific domain name to have privacy protection. This will not disclose the identity of actual domain details."
    },
    {
      id: uuid(),
      question: "What is a sub domain?",
      answer:
        "A subdomain is a prefix added to your main domain name in order to help navigate and organize the sections of your website. They can help you organize your website. For example, blog.levohost.com. In this URL, blog is the subdomain. "
    },
    {
      id: uuid(),
      question: "Do you allow multiple domain names registration in a single transaction?",
      answer:
        "Yes, you can register multiple domain names in one go by selecting bulk domain registration."
    }
    ,
    {
      id: uuid(),
      question: "Do you offer a money-back guarantee?",
      answer:
        "Unfortunately, There are no money back gurantee on domain registration / renewal / transfer. For more please visit our TOS page"
    }
  ];
  // Faq Business hosting
  const faqDomainName = [
    {
      id: uuid(),
      question: "What is a Domain Name?",
      answer:
        "A domain name is simply a human-readable form of an IP address or in other words, a domain name is the address of your website that people type in the browser URL bar to visit your website. Every website has a domain name, and it’s completely unique to that site."
    },
    {
      id: uuid(),
      question: "How long does it take before my domain name is active?",
      answer:
        "It takes 24-48 hours (usually much sooner) for your domain name to propagate to all of the root DNS servers and visible to all users of the internet. Sometimes propagation delays due to internet providers, they have to update their records to reflect the changes."
    },
    {
      id: uuid(),
      question: "Can I buy a domain name without buying hosting?",
      answer:
        "Yes, you can buy domain names without buying/having a hosting package with us."
    },
    {
      id: uuid(),
      question: "Can I transfer my domain from my current registrar to levohost.com ?",
      answer:
        "Yes, you can transfer your domain name from your current registrar/provider to us. If you would like to transfer your domain to levohost.com, please visit our Domain Transfer page."
    },
    {
      id: uuid(),
      question: "For how many years can I buy any domains?",
      answer:
        "You can Purchase or buy domains from 1-10 years."
    },
    {
      id: uuid(),
      question: "How will I know when a domain is to be renewed? Will levohost.com send any renewal intimation to my mail id?",
      answer:
        "You can view the domain expiry date from our customer portal. levohost.com will send an email as a courtesy and it is the responsibility of the domain owner to renew before the expiry date."
    },
    {
      id: uuid(),
      question: "Is it possible to hide all my Domain-based information through WHOIS protection?",
      answer:
        "Yes, it is possible only for a specific domain name to have privacy protection. This will not disclose the identity of actual domain details."
    },
    {
      id: uuid(),
      question: "What is a sub domain?",
      answer:
        "A subdomain is a prefix added to your main domain name in order to help navigate and organize the sections of your website. They can help you organize your website. For example, blog.levohost.com. In this URL, blog is the subdomain."
    },
    {
      id: uuid(),
      question: "Do you allow multiple domain names registration in a single transaction?",
      answer:
        "Yes, you can register multiple domain names in one go by selecting bulk domain registration."
    }
  ];
  // Faq Business hosting
  const faqSharedWeb = [
    {
      id: uuid(),
      question: " What is Web Hosting?",
      answer:
        "Web hosting is the service that allows your website or web application available to be viewed by others on the internet. Everything on the internet usually needs a web host. <br/>  For example, if you have any business and you want to sell your products globally then it is very essential to make it available on the web and for that, you will need a web hosting service."
    },
    {
      id: uuid(),
      question: "What is shared web hosting?",
      answer:
        "Shared web hosting is a type of hosting service that allows multiple websites to share a physical web server and its resources under the hosted websites or web applications. Shared web hosting is low in cost and gives a high range of response and performance."
    },
    {
      id: uuid(),
      question: "What is Disk Space & Bandwidth?",
      answer:
        "Disk space is the amount of space available on the server for storing the content of your website or web application. That content includes files, images, videos, databases, among others. <br/> <br/>   Bandwidth in website hosting is the amount of data that a website can deliver to its visitors in a given amount of time.."
    },
    {
      id: uuid(),
      question: "Should I need to register a domain to buy a Shared Hosting plan?",
      answer:
        "You do not need to buy a domain name, but you must have a domain name to set up with your hosting plan."
    },
    {
      id: uuid(),
      question: "Can I upgrade my hosting plan at a later stage?",
      answer:
        "Yes, You can upgrade your hosting plan at any time. For this, simply contact our support center by support ticket mentioning the package you want to upgrade. "
    },
    {
      id: uuid(),
      question: "Can I get a free SSL with the Shared Hosting plan?",
      answer:
        "Yes, you will get a free SSL certificate with any of our shared hosting plans."
    },
    {
      id: uuid(),
      question: " Will Shared Web Hosting work with my WordPress website?",
      answer:
        "Yes, With our Softaculous one-click installer you’ll get your WordPress website up and running in no time."
    },
    {
      id: uuid(),
      question: " Does your hosting come with a control panel?",
      answer:
        "YES, With our cPanel’s responsive interface, you can manage your web hosting from anywhere, whether you are using a laptop, desktop, tablet, or smartphone."
    },
    {
      id: uuid(),
      question: " Will my account be backed up?",
      answer:
        "We backup all of your data daily & weekly basis to protect against hardware failure or accidental erasure. Our Daily & weekly data backups that ensure the Website's safekeeping of files and databases. Backups are kept for up to a month. All backups are kept separately from hosting storage."
    }
    ,
    {
      id: uuid(),
      question: " Can you help me to transfer from my current web host?",
      answer:
        "If you have hosted your website with another hosting company and want to migrate it to LevoHost, you can easily do it in a hassle-free manner. All you need to do is choose a hosting plan and then submit the migration request form. LevoHost Support Team will then schedule the migration and complete it for you free of cost."
    }
  ];
  // Faq Business hosting
  const faqBusiness = [
    {
      id: uuid(),
      question: "What is Business Hosting?",
      answer:
        "In business hosting, you will get more powerful web hosting resources and configuration than shared hosting. Business hosting helps in filling the gap between the shared hosting and superior level VPS or dedicated server."
    },
    {
      id: uuid(),
      question: "Is business hosting the right choice for small businesses?",
      answer:
        "Definitely yes, business hosting will give you more powerful hosting resources and configuration than shared hosting. <br/> If you have a small to moderate level or even high-level business website then our business hosting plan can be a great choice of you"
    },
    {
      id: uuid(),
      question: "What kind of Business hosting plan do I need??",
      answer:
        "Talk to one of our web hosting experts who will review your needs and suggest a tailored hosting solution that will match your specific business reality and needs."
    },
    {
      id: uuid(),
      question: " Can I get a free SSL with Business Hosting packages?",
      answer:
        "Yes, You will get a free let's encrypt SSL certificate with any of our business hosting packages."
    },
    {
      id: uuid(),
      question: "Can I get a free Domain Registration with Business Hosting packages? ",
      answer:
        "Yes, You will get a FREE .COM domain registration with any of our business hosting packages above. For that, you must purchase at least 1 year of the business hosting package."
    },
    {
      id: uuid(),
      question: "If I already have a website, can I transfer it to your Web hosting?",
      answer:
        "If you have hosted your website with another hosting company and want to migrate it to LevoHost, you can easily do it in a hassle-free manner. All you need to do is choose a hosting plan and then submit the migration request form. LevoHost Support Team will then schedule the migration and complete it for you free of cost."
    },
    {
      id: uuid(),
      question: " Can I Upgrade from my Shared Hosting packages to Business Hosting?",
      answer:
        "Yes, you can upgrade from your current shared hosting package to any of our business hosting packages at any time. NO setup fee needed."
    },
    {
      id: uuid(),
      question: "Will my account be backed up?",
      answer:
        "We backup all of your data daily & weekly basis to protect against hardware failure or accidental erasure. Our Daily & weekly data backups that ensure the Website's safekeeping of files and databases. Backups are kept for up to a month. All backups are kept separately from hosting storage."
    },
    {
      id: uuid(),
      question: "Do you offer a money-back guarantee?",
      answer:
        "We offer a hassle-free 30 DAYS money-back guarantee to our Business web hosting packages. If you are unsatisfied for any reason, simply send us a refund request ticket, and we'll back your money within 7 working days."
    }
  ];
  // Faq Business hosting
  const faqAffiliate = [
    {
      id: uuid(),
      question: "What is affiliate marketing?",
      answer:
        "Affiliate marketing is the way of promoting and selling another company's products/services for a commission. It works differently with every vendor—some companies will pay you per click on a link, while other companies will give you a percentage of the sale/referral."
    },
    {
      id: uuid(),
      question: "Will I get paid for the sales of domains?",
      answer:
        "NO, Commission is only paid for the web hosting packages; we do not pay commission for additional services like domains registration, Gsuite, SSL certificates, or others."
    },
    {
      id: uuid(),
      question: "Do I need a website to get started?",
      answer:
        "A website or social media are essential to get the best results with our Affiliate Marketing Program. After all, it is hard to sell web hosting when you are not using it yourself."
    },
    {
      id: uuid(),
      question: "Does Levohost have an affiliate program?",
      answer:
        "Yes - You can join it for free and the application will only take a few minutes! Check it out and register to the affiliate program from HERE. "
    },
    {
      id: uuid(),
      question: "What technical skills do I need to become an Affiliate?",
      answer:
        "You do not need any technical skills to become an Afilate."
    },
    {
      id: uuid(),
      question: "Is there a cost for signing up for Levohost Affiliate Program?",
      answer:
        "No, you can join Levohost Affiliate Program completely for free. "
    },
    {
      id: uuid(),
      question: "Will I get paid for web hosting renewals or upgrades?",
      answer:
        "NO, Levohost Affiliate Program pays for the initial purchase of a new user; we do not pay commission for web hosting renewals or upgrades."
    },
    {
      id: uuid(),
      question: "Where can I read your Affiliate Program Agreement?",
      answer:
        "The Levohost Affiliate Program Agreement can be found HERE."
    },
    {
      id: uuid(),
      question: "What happens if the client cancels the plan?",
      answer:
        "Levohost offers a 30-day money-back guarantee on certain hosting plans. During this trial period, the clients may still be able to cancel the plan. If your client cancels his plan during the trial period, the commission is also canceled."
    }
  ];
  return (
    <FaqContext.Provider
      value={{
        faqDefault,
        faqSharedHosting,
        faqCheapHosting,
        faqBusinessHosting,
        faqResellerHosting,
        faqVPSHosting,
        faqDedicatedHosting,
        faqSSLHosting,
        faqDomainRegisterBD,
        faqDomainRegister,
        faqAffiliate,
        faqDomainName,
        faqSharedWeb,
        faqBusiness
      }}
    >
      {props.children}
    </FaqContext.Provider>
  );
};

export default FaqContextProvider;
