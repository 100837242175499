import React, { useState } from 'react';
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { currencies } from '../../constants/currencies';


const BdDomainPrice = () => {
    const { currency } = useSelector(state => state.currency);
    const [bdDomainPrice, setBdDomainPrice] = useState([
        {
            id: uuidv4(),
            domainExtension: '.com.bd',
            description: 'Intended For Commercial Entities And Purposes',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        },
        {
            id: uuidv4(),
            domainExtension: '.org.bd',
            description: 'Intended for Not-for-profit entities',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        },
        {
            id: uuidv4(),
            domainExtension: '.info.bd',
            description: 'Intended for personal names, only individuals can register',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        },
        {
            id: uuidv4(),
            domainExtension: '.net.bd',
            description: 'Restricted to Bangladeshi Internet Service Provider infrastructure',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        },
        {
            id: uuidv4(),
            domainExtension: '.edu.bd',
            description: 'Restricted to Bangladeshi Educational institute, college and universities',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        },
        {
            id: uuidv4(),
            domainExtension: '.ac.bd',
            description: 'Restricted to Bangladeshi Academic institute, schools and coaching center',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        },
        {
            id: uuidv4(),
            domainExtension: '.gov.bd',
            description: 'Restricted to Government',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        },
        {
            id: uuidv4(),
            domainExtension: '.mill.bd',
            description: 'Restricted to Bangladesh Armed Forces ',
            BDT: {
                registration: 'BDT 1999 / 2yr',
                renewal: 'BDT 1999 / 2yr'
            },
            USD: {
                registration: '$ 1999 / 2yr',
                renewal: '$ 1999 / 2yr'
            }
        }
    ]);
    const [DomainDoc, setDomainDoc] = useState([
        {
            id: uuidv4(),
            purpose: 'For Personal Website/ Blog',
            requiredDocuments: 'Photocopy of National ID card',
            other: 'Full Name, Email, Phone'
        },
        {
            id: uuidv4(),
            purpose: 'For Business Website',
            requiredDocuments: 'Photocopy of business trade license and NID Photocopy',
            other: 'Full Name, Email, Phone'
        },
        {
            id: uuidv4(),
            purpose: 'For NGO Website',
            requiredDocuments: 'Permission letter on NGO’s official pad along with seal and signature and NID Photocopy',
            other: 'Full Name, Email, Phone'
        },
        {
            id: uuidv4(),
            purpose: 'For Educational institute website',
            requiredDocuments: 'Permission letter on Institution’s official pad along with seal and signature and NID Photocopy',
            other: 'Full Name, Email, Phone'
        },
        {
            id: uuidv4(),
            purpose: 'For individual accounts',
            requiredDocuments: 'Scanned Copy Of NID Or Passport',
            other: 'Full Name, Email, Phone'
        },
        {
            id: uuidv4(),
            purpose: 'For Govt. Office/ Institute',
            requiredDocuments: 'Application to Chief Engineer of BTCL, permission letter on Institution’s official pad along with seal and signature and NID Photocopy',
            other: 'Full Name, Email, Phone'
        }
        ,
        {
            id: uuidv4(),
            purpose: 'For .mill domain',
            requiredDocuments: 'forwarding letter from Bangladesh Armed Forces is required and NID Photocopy',
            other: 'Full Name, Email, Phone'
        }
    ])

    return (
        <div className="domain-price-bd">
            <Container>
                <Row>
                    <Col md={{ span: 10, offset: 1 }} className="text-center domain-price-bd-head">
                        <h3>.BD Domain <span>Pricing</span></h3>
                        <p>It is a great opportunity to open a new market in Bangladesh by registering your .BD domain name. Any person/organization from all over the world can apply for registration of domain with DOT BD and it takes a maximum of three (03) working days to activate the domain upon submitting order.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table hover responsive size="sm">
                            <thead>
                                <tr>
                                    <th>Domain Extension</th>
                                    <th className="text-center">Description</th>
                                    <th className="text-center">Registration</th>
                                    <th className="text-end">Renewal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bdDomainPrice.map(domainPrice => {
                                        return (
                                            <tr key={domainPrice.id}>
                                                <td>{domainPrice.domainExtension}</td>
                                                <td className="text-center">{domainPrice.description}</td>
                                                {currency === currencies.BDT ?
                                                    <>
                                                        <td className="text-center">{domainPrice.BDT.registration}</td>
                                                        <td className="text-end">{domainPrice.BDT.renewal}</td>
                                                    </>
                                                    : <>
                                                        <td className="text-center">{domainPrice.USD.registration}</td>
                                                        <td className="text-end">{domainPrice.USD.renewal}</td>
                                                    </>
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>

                        </Table>
                        <div className="table-hint">
                            <br />
                            <span className="d-block font-14 mb-1">*Payment is non-refundable. So, please know details of the summary & particulars of the invoice & proceed to make payment.</span>
                            <span className="d-block font-14 mb-1">*Forwarding letter should be in favor of Divisional Engineer (Administration & coordination), Bangladesh Telecommunications Company Limited (BTCL).</span>
                            <span className="d-block font-14 mb-1">*You must obey the terms & conditions of BTCL to avoid any suspension/deactivation of your domain name.</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="domain-document">
                            <h3>Prerequisite Documents For Domain Registration</h3>
                            <Table hover responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Purpose</th>
                                        <th className="text-center">Required Documents</th>
                                        <th className="text-end">Other Required Info</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        DomainDoc.map(DomainDocs => {
                                            return (
                                                <tr key={DomainDocs.id}>
                                                    <td>{DomainDocs.purpose}</td>
                                                    <td className="text-center">{DomainDocs.requiredDocuments}</td>
                                                    <td className="text-end">{DomainDocs.other}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BdDomainPrice;
