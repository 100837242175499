import React, {createContext} from 'react';
import {Container, Row, Col, Image, Button} from "react-bootstrap";

import feature_1 from '../../assets/images/9.svg';
import feature_2 from '../../assets/images/13.svg';
import feature_3 from '../../assets/images/16.svg';
import feature_4 from '../../assets/images/19.svg';
import feature_5 from '../../assets/images/17.svg';
import SectionHeading from "../SectionHeading/SectionHeading";

const Features = () => {

    return (
        <div className="feature-section">
            <Container>
                <SectionHeading
                    clasess="text-center"
                    heading="What Makes LevoHost Special?"
                    desc=" LevoHost offers the Best quality web hosting services at the lowest possible prices."/>
            </Container>
            <Container>
                <Row>
                    <Col md={{span: 10, offset:1}}>
                        <Row className="align-items-center feature-item">
                            <Col md={3}>
                                <div className="feature-img">
                                    <Image src={feature_1}/>
                                </div>
                            </Col>
                            <Col md={{span:7, offset:1}}>
                                <div className="feature-card">
                                    <h3>Up To 20X Faster Servers</h3>
                                    <p> For the best performance & faster loading times, we use Solid-State Drive (SSD), super fast HTTP/2, popular security tools and also a great combination of hardware and software to make them more faster. </p>
                                    <p>
                                         With 20X Faster Servers, websites load quickly, providing a smooth user experience. This results in enhanced Google SEO rankings, increased visibility, and higher conversions. Additionally, 20X Faster Servers lead to reduced downtime and improved overall performance. Upgrade to our 20X Faster Servers to stay ahead in the digital world and succeed online. </p>
                                    <Button rounded-pill  variant="outline-primary" className="lvh-btn rounded-pill">View more info!</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center feature-item">

                            <Col md={{span: 3, offset: 1, order:2}}>
                                <div className="feature-img">
                                    <Image src={feature_2}/>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="feature-card">
                                    <h3>Free Domain Registration</h3>
                                    <p> Getting a domain name is the starting point for every online project. That's why you will receive a free domain with our selected web hosting plans. If you haven't registered a domain name, get your domain name free for the first year with our selected web hosting plans. <br/> <br/> *When the first year elapses, you’ll be billed annually for the domain name at regular prices. </p>
                                    <Button variant="outline-primary" className="lvh-btn rounded-pill">View more info!</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center feature-item">
                            <Col md={3}>
                                <div className="feature-img">
                                    <Image src={feature_3}/>
                                </div>
                            </Col>
                            <Col md={{span:7, offset:1}}>
                                <div className="feature-card">
                                    <h3>SSL Certificate - Life Time Free</h3>
                                    <p>SSL stands for Secure Sockets Layer. SSL is a security protocol that generates an encrypted link between a web server and a web browser. We are offering a free ‘Let’s Encrypt’ SSL to protect your website with HTTPS — encrypting traffic and instantly boosting SEO with Google.</p>
                                    <Button variant="outline-primary" className="lvh-btn rounded-pill">View more info!</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center feature-item">
                            <Col md={{span: 3, offset: 1, order: 2}}>
                                <div className="feature-img">
                                    <Image src={feature_4}/>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="feature-card">
                                    <h3> 24/7/365 Technical Support</h3>
                                    <p>We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services. Whether you need advanced PHP Hosting support or have a question about how to get your blog set up, the Levohost support team is always here.</p>
                                    <Button variant="outline-primary" className="lvh-btn rounded-pill">View more info!</Button>
                                </div>
                            </Col>
                        </Row>


                        <Row className="align-items-center feature-item">
                            <Col md={3}>
                                <div className="feature-img">
                                    <Image src={feature_5}/>
                                </div>
                            </Col>
                            <Col md={{span:7, offset:1}}>
                                <div className="feature-card">
                                    <h3> 30 Days Money Back Guarantee</h3>
                                    <p>We offer a hassle-free 30 DAYS money-back guarantee on Shared & Business web hosting packages.
                                        <br/>
                                        <br/>

If you are unsatisfied for any reason, simply send us a refund request ticket, and we'll back your money within 7 working days.</p>
                                    <Button variant="outline-primary" className="lvh-btn rounded-pill">View more info!</Button>
                                </div>
                            </Col>
                        </Row>


                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default Features;
