import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const ListFeatures = () => {
    return (
        <div className="list-features">
            <Container>
                <Row>
                    <Col md={12} className="text-center">
                        <h3> Advantages of Self Managed Dedicated Server </h3>
                        <p> </p>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col md={4}>
                        <ul>
                            <li>
                                <span className="material-icons">done</span>
                                <span> Much cheaper than fully managed hosting</span>
                            </li>

                            <li>
                                <span className="material-icons">done</span>
                                <span>Server Resources are not shared</span>
                            </li>
                            <li>
                                <span className="material-icons">done</span>
                                <span>You can pick the level of security</span>
                            </li>

                        </ul>
                    </Col>
                    <Col md={4}>
                        <ul>
                        <li>
                                <span className="material-icons">done</span>
                                <span>Reliability</span>
                            </li>
                            <li>
                                <span className="material-icons">done</span>
                                <span>Scalability</span>
                            </li>

                            <li>
                                <span className="material-icons">done</span>
                                <span>Flexibility</span>
                            </li>

                        </ul>
                    </Col>
                    <Col md={4}>
                        <ul>
                            <li>
                                <span className="material-icons">done</span>
                                <span> Can be configured to meet your specific needs </span>
                            </li>
                            <li>
                                <span className="material-icons">done</span>
                                <span>  More customization</span>
                            </li>
                            <li>
                                <span className="material-icons">done</span>
                                <span>You have the freedom to install any applications you like, and whenever you like </span>
                            </li>
      

                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ListFeatures;
