import React from "react";
import { Accordion, Container } from "react-bootstrap";

const FaqSecTos = () => {
  return (
    <div className="faq-section mb-5">
      <Container>
        <div className="faq-inner">
          <Accordion className="accordion-faq" defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                1. Acceptable Use Policy (AUP)
              </Accordion.Header>
              <Accordion.Body>
              <p>
                  LevoHost's Acceptable Use Policy is designed to protect
                  LevoHost, its Users and others from illegal, malicious,
                  harmful, damaging and inappropriate behavior by Users of
                  LevoHost's services. You agree that you and your End users
                  will use the services in full compliance with the LevoHost
                  Terms of Service (TOS) and the AUP.
                </p>
                <h4>
                  <u>
                    By using any service provided by LevoHost you agree that:
                  </u>
                </h4>
                <ul>
                  <li>
                    You will not make any inappropriate or illegal communication
                    to any Newsgroup, Chat Facility, Mailing List, or another
                    Internet Forum;
                  </li>
                  <li>
                    You will not violate any applicable laws, rules,
                    regulations, ordinances, or other such requirements of any
                    applicable Federal, State or local government;
                  </li>
                  <li> You will not transmit any unsolicited commercial or bulk email, not to engage in any spamming or Mail Bombing activities;</li>
                  <li> You will not make or allow any unauthorized access to LevoHost and/or third-party websites, servers, or hosting accounts and/or services;</li>
                  <li>  You will not allow any remote code execution of malicious software;</li>
                  <li> You will not cause denial of service attacks (DDoS), port scans or other endangering and invasive procedures against LevoHost servers and facilities or the servers and facilities of other network hosts or Internet users; </li>
                  <li>You will not forge the signature or code of any other person or involve in any activity to attempt to deceive other persons regarding the actual identity of the User; </li>
                  <li> You will not host or operate an audio and/or video streaming service;</li>
                  <li> You will not distribute, display, store or otherwise make available any content or material that you are not authorized to, whether because prohibited by law, agreement or any other reason. This includes but is not limited to copyrighted video, music, files, photos, designs, software, personally identifiable or protected confidential information, etc.</li>
                  <li> You will not harm or seek to harm minors in any way; </li>
                  <li> You will not host websites that make use of crypto pools/contain mining scripts, promote cryptocurrency money-making schemes and crypto airdrop sites;</li>
                  <li> You will not impersonate any person or entity;</li>
                  <li> You will not upload unacceptable material which includes: IRC bots, warez, image, file storage, mirror, or banner-ad services, sites promoting illegal activities, Brute Force Programs, Mail Bombers, and Spam Scripts; </li>
                  <li> you will not Promotes, encourages or engages in child pornography or the exploitation of children; </li>
      
                </ul>
                <br/>
                <p>If your use of the Services results in a violation of the Acceptable Use Policy, we may take immediate corrective action without prior warning, including deletion of content or Service suspension. Repeated violations of the Acceptable Use Policy will result in termination of the Agreement with no refund.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                2. Money Back Guarantee 
              </Accordion.Header>
              <Accordion.Body>


                <ul>
                  <li> All subscribers of shared hosting, business hosting, reseller hosting, and VPS plans carry a 30-day unconditional money-back guarantee. If you are unsatisfied with its performance or quality, you are entitled to a full refund of the contract amount within the first 30 days of the date of the account set-up.</li>
                  <li>If you no longer need a Service you have purchased or are unsatisfied with its performance or quality, you can cancel it at any time. We recommend that cancellation requests are posted through your Customer Portal. </li>
                  <li> Services that are tailor-made to you, are not covered by LevoHost's Money Back Policy. These include Domain name registration or transfer, SSL certificates, Dedicated Server, dedicated IP addresses, Paid support services and any third-party product (WHMCS, cPanel, Softaculous, etc.)  </li>
                  <li> If the account holder cancels the service after the period specified, there will be no refund given.  </li>
                  <li> Each customer can only use the Guarantee once and for one account only. </li>
                  <li>LevoHost reserves the right to change, modify or withdraw the Guarantee at any time and without notice to you. </li>
             
                </ul>           
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                3. Domain Registration Agreement 
              </Accordion.Header>
              <Accordion.Body>
                <p>
                This Domain Registration Agreement ("Registration Agreement") is between LevoHost ("we," "us," or "LevoHost"), as the sponsoring registrar, or acting as reseller for the sponsoring registrar identified in the WHOIS record which may be retrieved here, and you, the person or entity registering a domain or domains through LevoHost. By using LevoHost's domain registration services (the "Services"), you agree to be bound by this Registration Agreement.
                </p>

                <ul>
                  <li> As part of the registration process, you are required to provide certain information to LevoHost. We will use this information following the TOS and our Privacy Policy. The information required to process your domain name registration is set out on your Order.           </li>
                  <li> You acknowledge and agree that the domain name system and the practice of registering and administering domain names are continuously evolving and that we may change or modify these terms as necessary.  </li>
                  <li> 
You may apply for a domain name registration by placing an Order for the purchase of Services from LevoHost. We cannot guarantee that the domain name applied for in your Order will be registered in your given name or is capable of being registered by you. You should not rely on the fact that you have placed an Order to register a domain until we send you a confirmation mail that your order has been accepted by us and your domain has been registered.  </li>
                  <li> If you register a domain name through or transfer a domain to LevoHost, you will be able to manage the domain through a domain management facility in your customer portal. </li>
                  <li> You are solely responsible for providing us with accurate and valid contact information. You are responsible for any modifications to your domain name contact details made through the LevoHost's Customer portal. </li>         

                </ul>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="4">
              <Accordion.Header>
                4. Guaranteed Uptime 
              </Accordion.Header>
              <Accordion.Body>

                <ul>
                  <li>
                  LevoHost offers a 99.9% uptime guarantee on an annual base. If we fall below the guaranteed network uptime, we will compensate you as follows: 99.9% - 99.00% uptime: 1 month free hosting, An additional month of free hosting for every 1% of uptime lost below 99.00%.
                  </li>
                  <li>
                  LevoHost's assessment of downtime begins when Customer opens a support ticket to report the difficulty.
                  </li>
                  <li> LevoHost cannot be held responsible for any service interruption due to the client's error, including but not limited to spamming, Problems with Your ISP's network, Browser or DNS caching issues or any other cyber attack. </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="5">
              <Accordion.Header>
                5. Promotional Codes
              </Accordion.Header>
              <Accordion.Body>

                <ul>
                  <li>
                  The promotional price is for the first term only and renews at the regular rate.
                  </li>
                  <li> Promo codes/referrers are for new customers only
                  </li>
                  <li>By using a promotional ("promo") code you waive the option to indicate who referred you to LevoHost. </li>
                  <li> You may not change/submit a promo code or referrer after you've finished signing up. </li>
                  <li>  Any Customer caught signing up with multiple accounts to take advantage of our promo code offers will have their accounts suspended, merged, and billed for the full amount that would be due had the Client not created multiple accounts to take advantage of promotional offers more than once. </li>
              
                </ul>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="6">
              <Accordion.Header>
                6. LIMITATION OF LIABILITY
              </Accordion.Header>
              <Accordion.Body>
                <p>
                Customer expressly agrees that the use of LevoHost Web Hosting's Server is at the Customer's sole risk. Neither LevoHost Web Hosting, officers, directors, employees, agents, affiliates, third party information providers, warrant that LevoHost Web Hosting's Server service will not be interrupted or error-free; nor do they make any warranty as to the results that may be obtained from the use of the Server service or as to the accuracy, reliability or content of any information service, or merchandise contained in or provided through the LevoHost web Hosting Server service, unless otherwise expressly stated in this agreement.
                </p>

              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>
                7. Affiliate Terms &amp; Conditions
              </Accordion.Header>
              <Accordion.Body>
                <p>
                The Affiliate Program (the Program) allows you (the Affiliate, your) to promote LevoHost's services and receive commissions as set out in these Terms and Conditions (T&amp;C).
                </p>
                <h4>
                  <u>
                  we will update our affiliate program's T&amp;C soon.
                  </u>
                </h4>
   
           
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="8">
              <Accordion.Header>
                8. Age limitation
              </Accordion.Header>
              <Accordion.Body>
                <p>
                By LevoHost Terms of Service, we do not sell products, provide services or collect information from any individual under the age of 18. If you are under 18, we strongly encourage you to use our website only with the involvement of a parent or guardian.
                </p>
       
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>
                9. Modification of Terms of Service
              </Accordion.Header>
              <Accordion.Body>
                <p>
                LevoHost reserve the right to modify these Terms of Service (TOS). If we decide to modify this Terms Of Service (TOS) in any material way (except minor changes, that will not impact you or your services), we will notify you here, by email, or using a notice on www.LevoHost.com
                </p>

              </Accordion.Body>
            </Accordion.Item>



          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default FaqSecTos;
