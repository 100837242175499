import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import Footer from "../Components/Footer/Footer";
import ListDescSection from "../Components/SharedComponent/ListDescSection/ListDescSection";
import FaqSecPrivacy from "../Components/Faq/FaqSec/FaqSecPrivacy";

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Privacy Policy"
        desc="At LevoHost we care about your privacy and believe in transparency"
      />
      <ListDescSection />
      <FaqSecPrivacy />
      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
