import React, { createContext } from "react";
import { v4 as uuid } from "uuid";

export const MoreFeatureSliderContext = createContext();
const MoreFeatureSliderContextProvider = (props) => {
  const sliderItems = [



    {
      id: uuid(),
      heading: "Business Hosting",
      subHeading: "Optimized for small to high traffic websites",
      Price: {
        BDT: "799",
        USD: "9.4"
      },
      plan: "mo",
      listItem: [
        "SSD Disk space: 25 GB ",
        "Monthly Bandwidth: Unmetered ",
        "LiteSpeed with LSCache",
        "Cloudlinux OS ",
        "Free .COM domain "
      ],
      url: "/business-hosting"
    },

    {
      id: uuid(),
      heading: "Shared Web Hosting",
      subHeading: "Configured With Super Fast LiteSpeed Web Server",
      Price: {
        BDT: "140",
        USD: "1.64"
      },
      plan: "mo",
      listItem: [
        "SSD Disk space: 2 GB ",
        "Monthly Bandwidth: 200 GB ",
        "LiteSpeed with LSCache",
        "Cloudlinux OS ",
        " Imunify360 Firewall "
      ],
      url: "/shared-hosting"
    },

    {
      id: uuid(),
      heading: "Cheap Shared Hosting",
      subHeading: "Standard Quality with the Cheapest Price",
      Price: {
        BDT: "750",
        USD: "8.82"
      },
      plan: "yr",
      listItem: [
        "SSD Disk space: 750 MB",
        "Monthly Bandwidth: 30 GB",
        "Cloudlinux OS",
        "Free SSL Certificate: YES"
      ],
      url: "/cheap-hosting"
    },
    {
      id: uuid(),
      heading: "SSL Certificate",
      subHeading: "Protect your users’ data online with SSL security",
      Price: {
        BDT: "765",
        USD: "8.99"
      },
      plan: "yr",
      listItem: [
        "1 DOMAIN SECURED",
        "up to 256-bit encryption",
        "Comes with $10,000 Warranty",
        "Fast SSL Issuance"
      ],
      url: "/ssl-certificate"
    },
    {
      id: uuid(),
      heading: "Dedicated Server",
      subHeading: "Top-tier performance at a cheap price",
      Price: {
        BDT: "5,890",
        USD: "69.29"
      },
      plan: "mo",
      listItem: [
        " CPU: 4c/8t - 3.5GHz/3.9GHz",
        "Intel Xeon E3-1230v6",
        " RAM: 32GB DDR4 ECC 2133MHz ",
        " SSD: 2x HDD SATA 2TB"

      ],
      url: "/dedicated-servers"
    },
    {
      id: uuid(),
      heading: "Domain Registration",
      subHeading: "Huge Selection. Low Costs. New Extensions",
      Price: {
        BDT: "99",
        USD: "1.16"
      },
      plan: "yr",
      listItem: [
        "Free Email Account",
        "Domain Theft Protection",
        "Easy to use Control Panel",
        "Domain Forwarding"
      ],
      url: "/domain-registration"
    },
    {
      id: uuid(),
      heading: ".BD Domain Registration",
      subHeading: "Lowest cost .BD domain Registraton",
      Price: {
        BDT: "1800",
        USD: "21.17"
      },
      plan: "yr",
      listItem: [
        "Full Control Panel",
        "Lowest Cost",
        "Domain Activitation By 3 Working Day",
        "Wide variety of ccTLD"
      ],
      url: "/bd-domain"
    },
    {
      id: uuid(),
      heading: "VPS Hosting",
      subHeading: "High performance at unbeatable prices",
      Price: {
        BDT: "340",
        USD: "4"
      },
      plan: "mo",
      listItem: [
        "CPU: 1 vCore",
        "RAM: 2 GB",
        "Disk Space: 20 GB SSD",
        "IP: 01"
      ],
      url: "/vps-hosting"
    },
    {
      id: uuid(),
      heading: "Reseller Hosting",
      subHeading: "Fully White Labeled Reseller Hosting",
      Price: {
        BDT: "1750",
        USD: "20.58"
      },
      plan: "mo",
      listItem: [
        "SSD Disk space: 25 GB",
        "Monthly Bandwidth: 250 GB",
        "cPanel Account Allowed: 25",
        "Private Name Servers"
      ],
      url: "/reseller-hosting"
    }
  ];

  return (
    <MoreFeatureSliderContext.Provider value={{ sliderItems }}>
      {props.children}
    </MoreFeatureSliderContext.Provider>
  );
};

export default MoreFeatureSliderContextProvider;
