
const HostingAwesomeFeatures = (props) => {
    //PROPS
    const { hostingFeatures, titlePartOne, titlePartTwo, description } = props;
    return (
        <div className="hosting-awesome-features-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="title">{titlePartOne} <br /><span className="colored-text">{titlePartTwo}</span></h2>
                        <p className="details">{description}</p>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            {hostingFeatures.map(feature =>
                                <div className="col-6 col-md-4">
                                    <div className="feature-card text-center">
                                        <div className={`feature-icon-box ${feature.id % 2 === 0 ? "feature-icon-bg-yellow" : "feature-icon-bg-red"}`}>
                                            <figure>
                                                <img src={feature.icon} alt="icon" />
                                            </figure>
                                        </div>
                                        <p className="feature-text">{feature.title}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HostingAwesomeFeatures;