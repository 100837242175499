import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import ExtraFeatures from "../Components/SharedComponent/ExtraFeatures/ExtraFeatures";
import SupportBlock from "../Components/SupportBlock/SupportBlock";
import Footer from "../Components/Footer/Footer";
import WhatSsl from "../Components/WhatSSL/WhatSSL";
import SslReasons from "../Components/SSLReasons/SSLReasons";
import SslFacility from "../Components/SslFacility/SslFacility";
import SslSetup from "../Components/SSLSetup/SSLSetup";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
import SSLHostingPlanSlider from "../Components/SSLHostingPlanSlider/SSLHostingPlanSlider";
import FaqSSLHosting from "../Components/Faq/FaqSSLHosting/FaqSSLHosting";

const SslCertificate = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="SSL Certificate"
        desc="Protect your users’ data online with SSL security"
      />
      <SSLHostingPlanSlider />
      <SslFacility />
      {/* <WhatSsl /> */}
      <SslReasons />

      <SslSetup />
      <FaqSSLHosting />
      <MoreFeatureSlider />
      <SupportBlock />
      <Footer />
    </Fragment>
  );
};

export default SslCertificate;
