import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AffiliateContext } from "../../Contexts/AffiliateContext";

const AffiliateCommission = () => {
  const { Commissions } = useContext(AffiliateContext);
  return (
    <div className="affiliate-commission">
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div className="div-responsive">
              <Row className="head gx-0">
                <Col xs={{ span: 4, offset: 4 }}>
                  <div className="head-col bg-cus5">
                    <h3>
                      Monthly Plan Payout %{" "}
                     
                    </h3>
                  </div>
                </Col>
                <Col xs={{ span: 4 }}>
                  <div className="head-col bg-purple">
                    <h3>
                      Yearly Plan Payout %{" "}
                   
                    </h3>
                  </div>
                </Col>
              </Row>

              <Row className="t-body-wrap">
                <Col xs={12}>
                  {Commissions.map((Commission) => {
                    return (
                      <Row className="t-body gx-0">
                        <Col xs={4}>
                          <div className="body-col body-col-bg-1">
                            <h3>{Commission.name}</h3>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="body-col text-center body-col-bg-2">
                            <p>{Commission.monthlyC}%</p>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="body-col text-center body-col-bg-3">
                            <p>
                              <p>{Commission.yearlyC}%</p>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AffiliateCommission;
