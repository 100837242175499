import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import banner from '../../assets/images/banner.jpg';
import { currencies } from "../../constants/currencies";

const HeroHome = () => {
  const { currency } = useSelector(state => state.currency);
  const offer = {
    regular_price: {
      BDT: "TK 175",
      USD: "$2.05"
    },
    discounted_price: {
      BDT: "TK 140",
      USD: "$1.64"
    }
  }
  return (
    <div className="hero-home" style={{ backgroundImage: `url(${banner})` }}>
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <div className="d-flex flex-column align-items-center justify-content-center text-center center_hero">
              <div className="content">
                <h3>Get your website, email and business online today</h3>
                <p>
                  Up to <strong> 40% OFF </strong> On Hosting, SSL, Domains and more!
                </p>
              </div>
              <div className="hero-offer">
                <div className="offer-list d-flex justify-content-between align-items-center flex-wrap">
                  <p><strong>Litespeed </strong> web server</p>
                  <p>Pure SSD <strong> - (file,os,databases)</strong></p>
                  <p><strong> Cloudlinux </strong> OS </p>
                  <p><strong>SSL Certificate</strong> - Life Time Free</p>
                </div>
                <div className="offer-footer d-flex flex-wrap align-items-center">
                  <div className="offer">
                    <h4>
                      <del class="small-font">{currency === currencies.BDT ? offer.regular_price.BDT : offer.regular_price.USD}/mo</del>
                      <span> {currency === currencies.BDT ? offer.discounted_price.BDT : offer.discounted_price.USD}</span>
                      <small>/mo </small>
                    </h4>
                  </div>
                  <div className="offer-btn">
                    <a href="/shared-hosting">
                      <Button variant="primary">GET STARTED NOW</Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroHome;
