import React, { useState } from 'react';
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import { currencies } from '../../constants/currencies';
import SectionHeadV2 from "../SectionHeadV2/SectionHeadV2";

const DomainPrice = () => {
    const { currency } = useSelector(state => state.currency);
    const [DomainPrice, setDomainPrice] = useState([
        {
            id: uuidv4(),
            TLD: '.com',
            BDT: {
                Register: 'TK 1199',
                Renewal: 'TK 1199',
                Transfer: 'TK 1199'
            },
            USD: {
                Register: '$10.42',
                Renewal: '$10.42',
                Transfer: '$10.42'
            }
        },
        {
            id: uuidv4(),
            TLD: '.biz',
            BDT: {
                Register: 'TK 1899',
                Renewal: 'TK 1899',
                Transfer: 'TK 1899'
            },
            USD: {
                Register: '$16.51',
                Renewal: '$16.51',
                Transfer: '$16.51'
            }
        },
        {
            id: uuidv4(),
            TLD: '.blog',
            BDT: {
                Register: 'TK 3,658',
                Renewal: 'TK 4,424',
                Transfer: 'TK 3,658'
            },
            USD: {
                Register: '$31.80',
                Renewal: '$38.46',
                Transfer: '$31.80'
            }
        },
        {
            id: uuidv4(),
            TLD: '.ca',
            BDT: {
                Register: 'TK 1,905',
                Renewal: 'TK 2,267',
                Transfer: 'TK 1,905'
            },
            USD: {
                Register: '$16.56',
                Renewal: '$19.71',
                Transfer: '$16.56'
            }
        },
        {
            id: uuidv4(),
            TLD: '.club',
            BDT: {
                Register: 'TK 2,299',
                Renewal: 'TK 2,670',
                Transfer: 'TK 2,299'
            },
            USD: {
                Register: '$19.99',
                Renewal: '$23.21',
                Transfer: '$19.99'
            }
        },
        {
            id: uuidv4(),
            TLD: '.cn',
            BDT: {
                Register: 'TK 1,098',
                Renewal: 'TK 1,376',
                Transfer: 'TK 1,098'
            },
            USD: {
                Register: '$11.96',
                Renewal: '$11.96',
                Transfer: '$11.96'
            }
        },
        {
            id: uuidv4(),
            TLD: '.co.uk',
            BDT: {
                Register: 'TK 1,004',
                Renewal: 'TK 1,004',
                Transfer: 'TK 0'
            },
            USD: {
                Register: '$8.73',
                Renewal: '$8.73',
                Transfer: '$0'
            }
        },
        {
            id: uuidv4(),
            TLD: '.de',
            BDT: {
                Register: 'TK 1,022',
                Renewal: 'TK 1,168',
                Transfer: 'TK 1,022'
            },
            USD: {
                Register: '$8.88',
                Renewal: '$10.15',
                Transfer: '$8.88'
            }
        },
        {
            id: uuidv4(),
            TLD: '.eu',
            BDT: {
                Register: 'TK 978',
                Renewal: 'TK 1,111',
                Transfer: 'TK 978'
            },
            USD: {
                Register: '$8.50',
                Renewal: '$9.66',
                Transfer: '$8.50'
            }
        },
        {
            id: uuidv4(),
            TLD: '.info',
            BDT: {
                Register: 'TK 2151',
                Renewal: 'TK 2151',
                Transfer: 'TK 2151'
            },
            USD: {
                Register: '$18.70',
                Renewal: '$18.70',
                Transfer: '$18.70'
            }
        },
        {
            id: uuidv4(),
            TLD: '.me',
            BDT: {
                Register: 'TK 999',
                Renewal: 'TK 4499',
                Transfer: 'TK 4499'
            },
            USD: {
                Register: '$8.68',
                Renewal: '$39.12',
                Transfer: '$39.12'
            }
        },
        {
            id: uuidv4(),
            TLD: '.mobi',
            BDT: {
                Register: 'TK 3,652',
                Renewal: 'TK 4,240',
                Transfer: 'TK 3,652'
            },
            USD: {
                Register: '$31.75',
                Renewal: '$36.86',
                Transfer: '$31.75'
            }
        },
        {
            id: uuidv4(),
            TLD: '.net',
            BDT: {
                Register: 'TK 1599',
                Renewal: 'TK 1599',
                Transfer: 'TK 1599'
            },
            USD: {
                Register: '$13.90',
                Renewal: '$13.90',
                Transfer: '$13.90'
            }
        },
        {
            id: uuidv4(),
            TLD: '.nl',
            BDT: {
                Register: 'TK 1,386',
                Renewal: 'TK 1,652',
                Transfer: 'TK 1,386'
            },
            USD: {
                Register: '$12.05',
                Renewal: '$14.36',
                Transfer: '$12.05'
            }
        },
        {
            id: uuidv4(),
            TLD: '.online',
            BDT: {
                Register: 'TK 4,674',
                Renewal: 'TK 3,756',
                Transfer: 'TK 4,674'
            },
            USD: {
                Register: '$40.64',
                Renewal: '$32.66',
                Transfer: '$40.64'
            }
        },
        {
            id: uuidv4(),
            TLD: '.org',
            BDT: {
                Register: 'TK 1,498',
                Renewal: 'TK 1,498',
                Transfer: 'TK 1,498'
            },
            USD: {
                Register: '$13.02',
                Renewal: '$13.02',
                Transfer: '$13.02'
            }
        },
        {
            id: uuidv4(),
            TLD: '.ru',
            BDT: {
                Register: 'TK 626',
                Renewal: 'TK 626',
                Transfer: 'TK 0'
            },
            USD: {
                Register: '$5.44',
                Renewal: '$5.44',
                Transfer: '$0'
            }
        },
        {
            id: uuidv4(),
            TLD: '.site',
            BDT: {
                Register: 'TK 4,382',
                Renewal: 'TK 3,156',
                Transfer: 'TK 4,382'
            },
            USD: {
                Register: '$38.10',
                Renewal: '$27.44',
                Transfer: '$38.10'
            }
        },
        {
            id: uuidv4(),
            TLD: '.top',
            BDT: {
                Register: 'TK 1,462',
                Renewal: 'TK 1,670',
                Transfer: 'TK 1,462'
            },
            USD: {
                Register: '$12.71',
                Renewal: '$14.52',
                Transfer: '12.71'
            }
        },
        {
            id: uuidv4(),
            TLD: '.uk',
            BDT: {
                Register: 'TK 1,039',
                Renewal: 'TK 1,039',
                Transfer: 'TK 0'
            },
            USD: {
                Register: '$9.03',
                Renewal: '$9.03',
                Transfer: '$0'
            }
        },
        {
            id: uuidv4(),
            TLD: '.us',
            BDT: {
                Register: 'TK 972',
                Renewal: 'TK 1111',
                Transfer: 'TK 972'
            },
            USD: {
                Register: '$8.45',
                Renewal: '$9.66',
                Transfer: '$8.45'
            }
        },
        {
            id: uuidv4(),
            TLD: '.vip',
            BDT: {
                Register: 'TK 2,191',
                Renewal: 'TK 2,503',
                Transfer: 'TK 2,191'
            },
            USD: {
                Register: '$19.05',
                Renewal: '$21.76',
                Transfer: '$19.05'
            }
        }
        ,
        {
            id: uuidv4(),
            TLD: '.xyz ',
            BDT: {
                Register: 'TK 350',
                Renewal: 'TK 1,499',
                Transfer: 'TK 1,499'
            },
            USD: {
                Register: '$3.04',
                Renewal: '$13.03',
                Transfer: '$13.03'
            }
        },
        {
            id: uuidv4(),
            TLD: '.io',
            BDT: {
                Register: 'TK 6,984',
                Renewal: 'TK 8,348',
                Transfer: 'TK 7,304'
            },
            USD: {
                Register: '$60.73',
                Renewal: '$72.59',
                Transfer: '$63.51'
            }
        },
        {
            id: uuidv4(),
            TLD: '.icu',
            BDT: {
                Register: 'TK 1,022',
                Renewal: 'TK 1,168',
                Transfer: 'TK 1,022'
            },
            USD: {
                Register: '$8.88',
                Renewal: '$10.15',
                Transfer: '$8.88'
            }
        }
    ]);
    return (
        <div className="domain-price-bd">
            <Container>
                <Row>
                    <Col md={{ span: 10, offset: 1 }} className="text-center mb-4">
                        <SectionHeadV2
                            heading="Our Domain"
                            headingRed="Pricing"
                            desc=""
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table hover responsive size="sm">
                            <thead>
                                <tr>
                                    <th>TLD</th>
                                    <th className="text-center">Register</th>
                                    <th className="text-center">Renewal</th>
                                    <th className="text-center">Transfer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    DomainPrice.map(domainPrices => {
                                        return (
                                            <tr key={domainPrices.id}>
                                                <td>{domainPrices.TLD}</td>
                                                {currency === currencies.BDT ?
                                                    <>
                                                        <td className="text-center">{domainPrices.BDT.Register}</td>
                                                        <td className="text-center">{domainPrices.BDT.Renewal}</td>
                                                        <td className="text-center">{domainPrices.BDT.Transfer}</td>
                                                    </>
                                                    : <>
                                                        <td className="text-center">{domainPrices.USD.Register}</td>
                                                        <td className="text-center">{domainPrices.USD.Renewal}</td>
                                                        <td className="text-center">{domainPrices.USD.Transfer}</td>
                                                    </>
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>

                    </Col>
                </Row>
                <p> * Discount offers is for only first-year registration  </p>
                <p> *For more TLD names <a href="https://my.levohost.com/cart.php?a=add&domain=register"> click here</a></p>
            </Container>

        </div>
    );
};

export default DomainPrice;
