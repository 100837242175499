import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currencies } from "../../constants/currencies";
import { HostingContext } from "../../Contexts/HostingContext";
import { HostingPriceContext } from "../../Contexts/HostingPriceContext";

function HostingPlanBox() {
  const { currency } = useSelector(state => state.currency);
  const { SharedHostingPriceChart, BusinessHostingPriceChart } = useContext(HostingPriceContext);
  const { VPSPlanFeatures, DedicatedServerHostingPlan } = useContext(HostingContext);
  return (
    <div className="hosting-plan-box">
      <Container>
        <Row className="row-cols-md-2 gx-0 hosting-plan-center">
          <Col md="12" lg="6">
            <div className="hosting-plan-card card-big bg-purple website-bg">
              <h3 className="heading"> SHARED HOSTING</h3>
              <br />
              <br />

              <h4 className="price">
                Starting at <big> {
                  currency === currencies.BDT ?
                    "TK " + SharedHostingPriceChart.starter.discounted.BDT.perMonth
                    : "$ " + SharedHostingPriceChart.starter.discounted.USD.perMonth
                }</big>
                <span>/Mo</span>
              </h4>
              <br />
              {/* <p> <strong className="yellow_color"> 72% Off </strong> (Was $10.99) </p>   */}
              <a href="/shared-hosting">
                <Button variant="primary" className="lvh-btn shared_hosting_btn">
                  Get Started
                </Button>
              </a>
            </div>
          </Col>
          <Col md="12" lg="6">
            <div className="hosting-plan-card card-big bg-yellow domain-bg">
              <h3 className="heading heading-half mb-3">
                REGISTAR YOUR DOMAIN TODAY
              </h3>
              <br />
              <a href="/domain-registration">   <Button variant="primary" className="lvh-btn shared_hosting_reg_btn">
                REGISTAR now
              </Button> </a>

            </div>
          </Col>
          <Col md="12" lg="4">
            {" "}
            <div className="hosting-plan-card card-small bg-red-dark">
              <h3 className="heading headingx1"> BUSINESS HOSTING</h3>
              <br />
              <h4 className="price">
                Starting at <big> {
                  currency === currencies.BDT ?
                    "TK " + BusinessHostingPriceChart.starter.discounted.BDT.perMonth
                    : "$ " + BusinessHostingPriceChart.starter.discounted.USD.perMonth
                }</big>
                <span>/Mo</span>
              </h4>
              {/* <p> <strong className="yellow_color"> 72% Off </strong> (Was $10.99) </p> */}
              <br />
              <a href="/business-hosting">
                <Button variant="outline-light" className="lvh-btn">
                  {" "}
                  view plans{" "}
                </Button>
              </a>
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="hosting-plan-card card-small bg-green">
              <h3 className="heading headingx1">VPS HOSTING</h3>
              <br />
              <h4 className="price">
                Starting at <big> {currency === currencies.BDT ? "TK " + VPSPlanFeatures.vps_1.price_regular.BDT : "$ " + VPSPlanFeatures.vps_1.price_regular.USD}</big>
                <span>/Mo</span>
              </h4>
              {/* <p> <strong className="yellow_color"> 70% Off </strong> (Was $10.99) </p> */}
              <br />
              <a href="/vps-hosting">
                <Button variant="outline-light" className="lvh-btn">
                  {" "}
                  view plans{" "}
                </Button>
              </a>
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="hosting-plan-card card-small bg-cus1">
              <h3 className="heading headingx1">DEDICATED SERVERS</h3>
              <br />
              <h4 className="price">
                Starting at <big> {currency === currencies.BDT ? "TK " + DedicatedServerHostingPlan[0].price.BDT : "$ " + DedicatedServerHostingPlan[0].price.USD}</big>
                <span>/Mo</span>
              </h4>
              {/* <p> <strong className="yellow_color"> 70% Off </strong> (Was $10.99) </p> */}
              <br />
              <a href="/dedicated-servers">
                <Button variant="outline-light" className="lvh-btn">
                  {" "}
                  view plans{" "}
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HostingPlanBox;
