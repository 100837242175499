import React, { useContext } from "react";

import { Image } from "react-bootstrap";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReviewContext } from "../../Contexts/ReviewContext";

const ReviewItem = () => {
  const { reviews } = useContext(ReviewContext);
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {reviews.map((review) => {
        return (
          <div className="review-item" key={review.id}>
            <div className="review-quote-icon">
              <span className="material-icons">format_quote</span>
            </div>
            <div className="d-flex align-items-center">
              <div className="avatar avatar-circle">
                <Image src={review.avater} />
              </div>
              <div className="review-star">
                <span className="material-icons">star</span>
                <span className="material-icons">star</span>
                <span className="material-icons">star</span>
                <span className="material-icons">star</span>
                <span className="material-icons">star</span>
              </div>
            </div>
            <div className="review-desc mt-3">
              <p>{review.message}</p>
              <span>{review.name}</span>
              <small>{review.degination}</small>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default ReviewItem;
