import React from "react";
import { Accordion, Container } from "react-bootstrap";

const FaqSecPrivacy = () => {
  return (
    <div className="faq-section mb-5">
      <Container>
        <div className="faq-inner">
          <Accordion className="accordion-faq" defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                1. Information that we collect from our users
              </Accordion.Header>
              <Accordion.Body>

                <h4>
                  <u>
                  When you access and browse the Site (including when you submit personal information to our website) we will collect the following from you: 
                  </u>
                </h4>
                <ul>
                  <li> Account Signup Information: When you sign up to LevoHost, we store the following information - </li>
                   <p> a) Personal Information: full name,email address,phone number </p>
                   <p> b) Billing Information: Company Name,address.  </p>
                   <p> c) Account Security: Password,security questions.  </p>
                  <li> Payment Information: We will store the following information when purchasing products or services: Bank details, Credit card information, PayPal or relevant service, Billing address. </li>
                  <li> Communications, Chats or Messaging: When you communicate with LevoHost, we collect information about your communication and any information you choose to provide or disclose.  </li>
                  <li> Automated Information: When you visit our website, we receive and store information, which may in certain circumstances constitute personal information and which includes your Operating system, Browser type and version, IP address, Operational logs, referral URL, date/time and/or clickstream data. </li>
                  <li> Any telephone conversations with our staff, which we may monitor or record. </li>
                  <li> Non-financial identification information, such as username and password combinations. </li>
         
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                2. How we utilize information 
              </Accordion.Header>
              <Accordion.Body>
                <b>
                LevoHost does not trade or sell your personal information or data to a third party or any other organization, except as provided specifically in this policy.
                </b>
                <br/>
                <br/>

                <h4>
                  <p>
                   We collect &amp; store various information relating to your purchase, use and/or interactions with our Services. We use the information that you provided in the following ways: 
                  </p>
                </h4>
                <ul>
                    <li> To process orders that you have submitted to LevoHost. </li>
                    <li> To help us identify you and any accounts you hold with LevoHost. </li>
                    <li> To enhance and optimize the operation and performance of our Services to you and for the Website. </li>
                    <li> To enhance data security and to prevent fraud and also and to make sure what you have told us is correct. </li>
                    <li> Personalize and improve your usability of our websites. </li>
                    <li> To carry out marketing and statistical analysis. </li>
                    <li> To comply with applicable laws and regulations. </li>
                    <li> To notify you about changes to our website or services. </li>
                    <li> To carry out obligations arising under agreements entered into between you and LevoHost. </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="3">
              <Accordion.Header>
                3. Cookies &amp; Log Files
              </Accordion.Header>
              <Accordion.Body>
                <p>
                The LevoHost website may use "cookies" to help you personalize your online experience. Cookies help us to provide you with a better experience when you browse our website and also allows us to improve our website.
                </p>
                <p>
                Additionally, we collect certain information automatically and store it in the log files. This information may include browser information (such as browser type and language), IP addresses, internet service provider (ISP), operating system, date/time stamp and/or clickstream data. 
                </p>

              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                4. Your Rights 
              </Accordion.Header>
              <Accordion.Body>
                <p>
                 Depending on your jurisdiction of residence, you may have the data protection right to access, update, correct, or to request the deletion of your personal information with LevoHost. To exercise any of your rights, please send an email to privacy@LevoHost.com
                </p>
                <h4>
                  <b>
                  Please note that we may require you to verify your identity before responding to any requests to execute your rights. 
                  </b>
                </h4>

              </Accordion.Body>
            </Accordion.Item>



            <Accordion.Item eventKey="5">
              <Accordion.Header>
                5. Age restriction
              </Accordion.Header>
              <Accordion.Body>
                <p>
                By LevoHost Terms of Service, we do not sell products, provide services or collect information from any individual under the age of 18. If you are under 18, we strongly encourage you to use our website only with the involvement of a parent or guardian. 
                </p>

              </Accordion.Body>
            </Accordion.Item>



            <Accordion.Item eventKey="6">
              <Accordion.Header>
                6. Changes to our Privacy Policy
              </Accordion.Header>
              <Accordion.Body>
                <p>
                LevoHost reserve the right to modify this Privacy Policy at any time. If we decide to modify this privacy notice in any material way (except minor changes, that will not impact you or your services), we will notify you here, by email, or using a notice on www.LevoHost.com
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>
                7. Further questions and contacts
              </Accordion.Header>
              <Accordion.Body>
                <p>
                If you have any questions about this privacy notice, concerns or complaints about our Privacy Policy, our practices or our Services, you may contact us in the following ways:
                </p>
  
                <ul>

                  <li> By Email: feedback@LevoHost.com</li>
                  <li> By Phone: +880 173333 4333 </li>
                  <li> Or write to us at: 62/6, West Tejturi Bazar, Farmgate, Dhaka 1215, Bangladesh </li> 
                </ul>
              </Accordion.Body>
            </Accordion.Item>


          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default FaqSecPrivacy;
