import React, { useState } from "react";

import { Container, Navbar, Nav, NavDropdown, Image } from "react-bootstrap";

// Resource load
import logo from "../../assets/images/logo.png";
import TopHeader from "./TopHeader";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="header-wrap">
      <TopHeader />
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/" className="me-auto">
            <Image src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Item>
                <NavLink
                  className="nav-link"
                  to="/"
                  activeClassName="nav-active"
                >
                  HOME
                </NavLink>
              </Nav.Item>
              <NavDropdown title="HOSTING" renderMenuOnMount={true}>
                <NavLink
                  className="dropdown-item"
                  to="/shared-hosting"
                  activeClassName="nav-active"
                >
                  Shared Web Hosting
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/business-hosting"
                  activeClassName="nav-active"
                >
                  Business Hosting
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/reseller-hosting"
                  activeClassName="nav-active"
                >
                  Reseller Hosting
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/vps-hosting"
                  activeClassName="nav-active"
                >
                  VPS Hosting
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/dedicated-servers"
                  activeClassName="nav-active"
                >
                  Dedicated Server
                </NavLink>
              </NavDropdown>
              {/* <Nav.Item>
                <NavLink
                  className="nav-link"
                  to="/"
                  activeClassName="nav-active"
                >
                  SERVERS
                </NavLink>
              </Nav.Item> */}
              <NavDropdown title="DOMAIN" renderMenuOnMount={true}>
                <NavLink
                  className="dropdown-item"
                  to="/domain-registration"
                  activeClassName="nav-active"
                >
                  Domain Registration
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/bd-domain"
                  activeClassName="nav-active"
                >
                  .BD domain registration
                </NavLink>
                <NavDropdown.Item href="https://my.levohost.com/cart.php?a=add&domain=transfer">Domain Transfer</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="MORE SERVICES" renderMenuOnMount={true}>
                <NavLink
                  className="dropdown-item"
                  to="/ssl-certificate"
                  activeClassName="nav-active"
                >
                  SSL Certificate
                </NavLink>


                <NavDropdown.Item href="#">
                Google Workspace
                </NavDropdown.Item>


                <NavDropdown.Item href="#">
                  Business Email
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="COMPANY &amp; FACILITIES "
                renderMenuOnMount={true}
              >
                <NavLink
                  className="dropdown-item"
                  to="/affiliate"
                  activeClassName="nav-active"
                >
                  Affiliate Program
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/about-us"
                  activeClassName="nav-active"
                >
                  About LevoHost
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/contact"
                  activeClassName="nav-active"
                >
                  Contact Us
                </NavLink>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
