import React from "react";

import MoreFeatureSliderItem from "./MoreFeatureSliderItem";

const MoreFeatureSlider = (props) => {
  return (
    <div className="more-feature-slider">
      <header className="text-center">
        <h3>Are you looking for something else?</h3>
      </header>

      <div className="full-page-slider">
        <MoreFeatureSliderItem />
      </div>
    </div>
  );
};

export default MoreFeatureSlider;
