import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import SupportBlock from "../Components/SupportBlock/SupportBlock";
import Footer from "../Components/Footer/Footer";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
import WebHostingSpecs from "../Components/WebHostingSpecs/WebHostingSpecs";
import HostingAwesomeFeatures from "../Components/CommonHostingSections/HostingAwesomeFeatures";
import OneClickAppInstall from "../Components/CommonHostingSections/OneClickAppInstall";
import WhyChooseLevoHost from "../Components/CommonHostingSections/WhyChooseLevoHost";
// IMAGE
import security from "../assets/images/hosting-features/safe_secure.png";
import installation from "../assets/images/hosting-features/click_installation.png";
import transfer from "../assets/images/hosting-features/website_transfer.png";
import backup from "../assets/images/hosting-features/daily_backup.png";
import click from "../assets/images/hosting-features/1_click.png";
import clock from "../assets/images/hosting-features/clock.png";
import CheapHostingPlansTop from "../Components/CheapHostingPlans/CheapHostingPlansTop";
import FaqCheapHosting from "../Components/Faq/FaqCheapHosting/FaqCheapHosting";

const CheapHosting = (props) => {
    // HOSTING FEATURES
    const hostingFeatures = [
        {
            id: 1,
            icon: security,
            title: "Multi Layer Security"
        },
        {
            id: 2,
            icon: installation,
            title: "cPanel Control Panel"
        },
        {
            id: 3,
            icon: transfer,
            title: "Free Website Transfer"
        },
        {
            id: 4,
            icon: backup,
            title: "Weekly Backup"
        },
        {
            id: 5,
            icon: click,
            title: "1 Click App Installer"
        },
        {
            id: 6,
            icon: clock,
            title: "99.99% Uptime"
        }
    ]
    // INFO LIST FOR WEB HOSTING SPECS
    const infoList = {
        one: [
            { id: 1, name: "30 Day Money Back Guarantee" },
            { id: 2, name: " Free Website Transfer " },
            { id: 3, name: "24/7 Technical Support" },
            { id: 4, name: "99.9% Server Uptime Guarantee" },
            { id: 5, name: "FREE Let's Encrypt SSL Certs" },
            { id: 6, name: "CloudFlare CDN" },
        ],
        two: [
            { id: 1, name: "Cloudlinux OS" },
            { id: 2, name: "SSH Access (Jailed)" },
            { id: 3, name: "Backups: Twice a Week" },
            { id: 4, name: "Unlimited Auto Responders" },
            { id: 5, name: "Mariadb 10.x / phpMyAdmin" },
            { id: 6, name: "Cron Jobs " },
        ],
        three: [
            { id: 1, name: "cPanel Control Panel" },
            { id: 2, name: "Softaculous Script Installer" },
            { id: 3, name: "Node.js Version Selector" },
            { id: 4, name: "ionCube PHP Loader" },
            { id: 5, name: "Awstats & Webalizer" },
            { id: 6, name: "Spam Protection" },
        ]
    }

    return (
        <Fragment>
            <Header />
            <HeaderSmall
                heading="Cheap Shared Hosting"
                desc="Standard Quality with the Cheapest Price"
            />
            <CheapHostingPlansTop />
            <WebHostingSpecs
                infoList={infoList}
                titlePartOne="Advanced"
                titlePartTwo="Features"
                description=""
            />
            <HostingAwesomeFeatures
                titlePartOne="Awesome Features Of"
                titlePartTwo="Cheap Hosting"
                description="We always think about our client's budget &amp; requirements. For that, we configured our cheap hosting plan with good features as the prices are. In our Cheap Shared hosting package, we've added More features like- Free SSL Certificate, Free Website Transfer, weekly Backup, 99.99% server uptime commitment, and many more features."
                hostingFeatures={hostingFeatures}
            />
            <WhyChooseLevoHost />
            <OneClickAppInstall />
            <FaqCheapHosting />
            <MoreFeatureSlider />
            <SupportBlock />
            <Footer />
        </Fragment>
    );
};

export default CheapHosting;
