import React, { useEffect } from "react";
import TawkTo from 'tawkto-react';
import { BrowserRouter as Router } from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";
import "./assets/scss/app.scss";
import AppRouter from "./Router/AppRouter";
import DataContextProvider from "./Contexts/DataContext";
import HostingContextProvider from "./Contexts/HostingContext";
import MoreFeatureSliderContextProvider from "./Contexts/MoreFeatureSliderContext";
import ReviewContextProvider from "./Contexts/ReviewContext";
import AffiliateContextProvider from "./Contexts/AffiliateContext";
import FaqContextProvider from "./Contexts/FaqContext";
import HostingPriceContextProvider from "./Contexts/HostingPriceContext";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // TWAKTO LIVE CHAT
  const propertyID = "5e0b205d27773e0d832b4ace";
  const twakID = "1e0pqbqo0";

  useEffect(() => {

    const tawk = new TawkTo(propertyID, twakID)

    tawk.onStatusChange((status) => {
      // console.log(status)
    })

  }, [])
  return (
    <Scrollbar>
      <div className="app-wrapper">
        <Router>
          <DataContextProvider>
            <FaqContextProvider>
              <HostingContextProvider>
                <HostingPriceContextProvider>
                  <MoreFeatureSliderContextProvider>
                    <ReviewContextProvider>
                      <AffiliateContextProvider>
                        <AppRouter />
                      </AffiliateContextProvider>
                    </ReviewContextProvider>
                  </MoreFeatureSliderContextProvider>
                </HostingPriceContextProvider>
              </HostingContextProvider>
            </FaqContextProvider>
          </DataContextProvider>
        </Router>
      </div>
    </Scrollbar>
  );
}

export default App;
