import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading";

const SslSetup = () => {
    return (
        <div className="ssl-setup">
            <Container>
                <SectionHeading
                    heading="SSL certification in Four Easy Steps"
                    desc=" After you purchase an SSL certificate from us, it needs to be activated. After activation, you’ll receive installation instructions. From that point, you can manage your certificate in the LevoHost Customer portal."
                />
                <Row className="mt-5 g-3">
                    <Col md={6} lg={4} xl={3}>
                        <div className="setup-card">
                            <header className="setup-card-head">
                                <h2>1</h2>
                            </header>
                            <div className="setup-card-body">
                                <h3>buy it</h3>
                                <p>Begin by purchasing the right SSL certificate for your website or organization. Choose a package listed above..</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={4} xl={3}>
                        <div className="setup-card">
                            <header className="setup-card-head">
                                <h2>2</h2>
                            </header>
                            <div className="setup-card-body">
                                <h3>Activate It</h3>
                                <p>You will be able to activate your SSL certificate from the customer portal immediately after purchase CSR code assignment and domain name indication will occur during the activation process.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={4} xl={3}>
                        <div className="setup-card">
                            <header className="setup-card-head">
                                <h2>3</h2>
                            </header>
                            <div className="setup-card-body">
                                <h3>Install It</h3>
                                <p>You will receive instructions on how to install your purchased SSL certificate once it has been validated and issued.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={4} xl={3}>
                        <div className="setup-card">
                            <header className="setup-card-head">
                                <h2>4</h2>
                            </header>
                            <div className="setup-card-body">
                                <h3>Manage It</h3>
                                <p>You may manage your SSL certificates (including renewal and re-issuance) in your Customer portal..</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SslSetup;
