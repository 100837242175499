/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Copyright from "./Copyright";

import logo from "../../assets/images/logo.png";
import dmca from "../../assets/images/dmca-badge-w150-5x1-06.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer-section">
      <Container>
        <Row>
          <Col md={8} sm={12}>
            <Row>
              <Col md={3} xs={6}>
                <div className="footer-item">
                  <h3>Product / Services</h3>
                  <ul>
                    <li>
                      <Link to="/shared-hosting">Shared Hosting</Link>
                    </li>
                    <li>
                      <Link to="/business-hosting">Business Hosting</Link>
                    </li>
                    <li>
                      <Link to="/reseller-hosting">Reseller Hosting</Link>
                    </li>
                    <li>
                      <Link to="/vps-hosting">VPS Hosting</Link>
                    </li>
                    <li>
                      <Link to="/dedicated-servers">Dedicated Server </Link>
                    </li>
                    <li>
                      <Link to="/ssl-certificate">SSL Certificate</Link>
                    </li>
                    <li>
                      <Link to="/">Domain Registration</Link>
                    </li>
                    <li>
                      <Link to="/">.BD Domain Registration</Link>
                    </li>
                    <li>
                      <Link to="/cheap-hosting">Cheap Shared Hosting</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} xs={6}>
                <div className="footer-item">
                  <h3>Member Area</h3>
                  <ul>
                    <li>
                      <a href="https://my.levohost.com/login"> Member Login </a>
                    </li>
                    <li>
                      <a href="https://my.levohost.com/announcements"> Announcement </a>
                    </li>
                    <li>
                      <a href="https://my.levohost.com/knowledgebase"> Knowledge Base </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} xs={6}>
                <div className="footer-item">
                  <h3>Help</h3>
                  <ul>
                    <li>
                      <a href="https://my.levohost.com/submitticket.php"> Submit Support Ticket </a>

                    </li>
                    <li>
                      <Link to="/">Live Chat</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <a href="https://my.levohost.com/serverstatus.php"> Network Status </a>
                    </li>
                    <li>
                      <a href="https://my.levohost.com/submitticket.php"> Report Abuse </a>

                    </li>
                    <li>
                      <Link to="/">Sitemap</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} xs={6}>
                <div className="footer-item">
                  <h3>Company</h3>
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/affiliate">Affiliate Program</Link>
                    </li>
                    <li>
                      <Link to="/tos">Terms Of Services </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <div className="footer-info">
              <span className="mb-3 d-block logo">
                <Image src={logo} />
              </span>
              <p>
                <span className="material-icons">home</span>
                62/6, West Tejturi Bazar, Dhaka
              </p>

              <p>
                <span className="material-icons">email</span>
                support@levohost.com
              </p>
              <p>
                <span className="material-icons">call</span>
                +880 173333-4333
              </p>
              <ul className="social-icons">
                <li>
                  <Link
                    to={{ pathname: "https://www.facebook.com/levohost" }}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: "https://twitter.com/levohost" }}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "https://www.linkedin.com/company/levohost"
                    }}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                </li>
              </ul>

              <a href="//www.dmca.com/Protection/Status.aspx?ID=bf178af4-eb73-41ce-afad-fbac2902aff5" target="_blank" title="DMCA.com Protection Status" class="dmca-badge">
                <img src={dmca} alt="DMCA.com Protection Status" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Copyright />
    </div>
  );
};

export default Footer;
