/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading";
import { SiTrustpilot } from "react-icons/si";

import shape_1 from "../../assets/images/review-img-3.png";
import shape_2 from "../../assets/images/review-img-4.png";
import shape_3 from "../../assets/images/review-img-5.png";
import shape_4 from "../../assets/images/review-img-6.png";

import ReviewItem from "./ReviewItem";

const Reviews = () => {
  return (
    <div className="reviews-section">
      <figure className="review-img-3">
        <Image src={shape_1} />
      </figure>
      <figure className="review-img-4">
        <Image src={shape_2} />
      </figure>
      <figure className="review-img-5">
        <Image src={shape_3} />
      </figure>
      <figure className="review-img-6">
        <Image src={shape_4} />
      </figure>
      <Container>
        {/*Section Header*/}
        <SectionHeading
          heading="What Our Customers Have To Say?"
          desc="95% of our customers would recommend LevoHost to a friend. Are you ready to become the next LevoHost customer to love their web host?"
        />
        {/*Review List*/}
        <Row>
          <Col md={{ offset: 1, span: 10 }}>
            <div className="reviews-list">
              <ReviewItem />
            </div>
          </Col>
        </Row>
        <div className="review-btn-box">
          <a className="review-btn" target="_blank" href="https://www.trustpilot.com/evaluate/levohost.com?utm_medium=trustbox&utm_source=TrustBoxReviewCollector">
            <button className="btn btn-outline-success">
              <span>Review us on </span>
              <SiTrustpilot className="icon"/>
              <span>trustpilot</span>
            </button>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Reviews;
