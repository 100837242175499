import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import ExtraFeatures from "../Components/SharedComponent/ExtraFeatures/ExtraFeatures";
import SupportBlock from "../Components/SupportBlock/SupportBlock";
import Footer from "../Components/Footer/Footer";
import HostingFeaturesNoHead from "../Components/SharedComponent/HostingFeatures/HostingFeaturesNoHead";
import ReadyToStart from "../Components/SharedComponent/ReadyToStart/ReadyToStart";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
import SharedHostingPlans from "../Components/SharedHostingPlans/SharedHostingPlans";
import SharedHostingPlansTop from "../Components/SharedHostingPlans/SharedHostingPlansTop";
import FaqSharedHosting from "../Components/Faq/FaqSharedHosting/FaqSharedHosting";
import WebHostingSpecs from "../Components/WebHostingSpecs/WebHostingSpecs";

const SharedHosting = (props) => {
  // INFO LIST FOR WEB HOSTING SPECS
  const infoList = {
    one: [
      { id: 1, name: "30 Day Money Back Guarantee" },
      { id: 2, name: " Free Website Transfer " },
      { id: 3, name: "24/7 Technical Support" },
      { id: 4, name: "99.9% Server Uptime Guarantee" },
      { id: 5, name: "FREE Let's Encrypt SSL Certs" },
      { id: 6, name: "CloudFlare CDN" },
    ],
    two: [
      { id: 1, name: "LitesSpeed Web Server" },
      { id: 2, name: "Cloudlinux OS" },
      { id: 3, name: "SSH Access (Jailed)" },
      { id: 4, name: "Backups: Twice a Week" },
      { id: 5, name: "Imunify360 Firewall" },
      { id: 6, name: "Cron Jobs " },
    ],
    three: [
      { id: 1, name: "cPanel Control Panel" },
      { id: 2, name: "Softaculous Script Installer" },
      { id: 3, name: "Node.js Version Selector" },
      { id: 4, name: "ionCube PHP Loader" },
      { id: 5, name: "Awstats & Webalizer" },
      { id: 6, name: "Spam Protection" },
    ]
  }

  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Shared Web Hosting"
        desc="Configured With Super Fast LiteSpeed Web Server"
      />
      <SharedHostingPlansTop />
      <WebHostingSpecs
        infoList={infoList}
        titlePartOne="Advanced"
        titlePartTwo="Features"
        description=""
      />
      <SharedHostingPlans />
      <ExtraFeatures
        icon_1="monetization_on"
        icon_2="monetization_on"
        icon_3="monetization_on"
        heading_1="cPanel Control Panel"
        heading_2="Litespeed Web Server"
        heading_3="24/7/365 Live Support"
        desc_1="With our cPanel’s responsive interface, you can manage your web hosting from anywhere, whether you are using a laptop, desktop, tablet, or smartphone."
        desc_2="LiteSpeed web server is the #1 commercial web server and provides better performance than any other web hosting server, like Apache or Nginx "
        desc_3="We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. ."
      />
      <HostingFeaturesNoHead />
      <ReadyToStart />
      <FaqSharedHosting />
      <MoreFeatureSlider />
      <SupportBlock />
      <Footer />
    </Fragment>
  );
};

export default SharedHosting;
