import { SELECTED_CURRENCY } from "../types";

const initState = {
    currency: "BDT"
}

const currencyReducer = (state = initState, action) => {
    switch (action.type) {
        case SELECTED_CURRENCY:
            return {
                ...state,
                currency: action.payload
            }
        default:
            return state;
    }
}

export default currencyReducer;