import { Container, Image } from "react-bootstrap";

import hostingFet1 from "../../../assets/images/feature_img01 (1).svg";
import hostingFet2 from "../../../assets/images/dedicated.jpg";
import hostingFet3 from "../../../assets/images/feature_img02 (1).svg";
import hostingFet4 from "../../../assets/images/feature_img03 (1).svg";
import hostingFet5 from "../../../assets/images/feature_img04.svg";
import SectionHeaderTwo from "../SectionHeaderTwo/SectionHeaderTwo";

const HostingFeaturesDedicated = () => {
  return (
    <div className="hosting-features  shared_feature_section dd_server_feature_section text-center">
      <Container>
        {/*Section Headign*/}
        <SectionHeaderTwo
          heading="Features"
          desc="Our Dedicated Server Features"
        />
        <span className="blank-space"></span>
        {/*Content*/}

        <div>
          <div class="row feature_row01 circle_shape">
            <div class="col-xl-3 hide_column"></div>
            <div class="col-xl-2 col-lg-12 col-md-4">
              <div class="feature_img_box mx-auto mx-md-0 mx-lg-auto mx-xl-0">
                <figure>
                  <Image src={hostingFet1} alt="" />
                </figure>
              </div>
            </div>
            {/* <div class="col hide_column d-noe d-lg-block"></div> */}
            <div class="col-xl-6 col-lg-10 offset-lg-1 offset-xl-0 text-lg-center text-xl-start col-md-8 feature_content_box text-center text-md-start ps-lg-5">
              <h3>Anti-DDoS protection </h3>
              <p>
                To guarantee maximum security for your infrastructures, an anti-DDoS system is included with all LevoHost dedicated servers.
              </p>
            </div>
          </div>
          <div class="row feature_row02">
            <div class="col-xl-5 col-lg-5 d-none d-xl-block">
              <div class="features_absoulte_img">
                <figure>
                  <Image src={hostingFet2} alt="" />
                </figure>
              </div>
            </div>
            <div class="col-xl-2 col-lg-12 col-md-4">
              <div class="feature_img_box mx-auto mx-md-0 mx-lg-auto mx-xl-0">
                <figure>
                  <Image src={hostingFet3} alt="" />
                </figure>
              </div>
            </div>
            <div class="col-xl-5 col-lg-10 offset-lg-1 offset-xl-0 text-lg-center text-xl-start col-md-8 feature_content_box padding_left_40 text-center text-md-start">
              <h3>Distributions</h3>
              <p>
                You can install a number of operating systems automatically via the LevoHost Customer Portal. This includes various operating systems (Windows Server, Debian, Ubuntu, OpenSUSE, or CentOS), Plesk, and cPanel web management interfaces, and also Microsoft SQL Server for databases are also available.
              </p>
            </div>
          </div>
          <div class="row feature_row03">
            <div class="col-xl-5"></div>
            <div class="col-xl-2 col-lg-12 col-md-4">
              <div class="feature_img_box mx-auto mx-md-0 mx-lg-auto mx-xl-0 ms-xl-4">
                <figure>
                  <Image src={hostingFet4} alt="" />
                </figure>
              </div>
            </div>
            <div class="col-xl-5 col-lg-10 offset-lg-1 offset-xl-0 text-lg-center text-xl-start col-md-8 feature_content_box padding_left_40 text-center text-md-start ps-lg-3">
              <h3>99.90% Uptime Guarantee</h3>
              <p> We understand the uptime importance. We’re so confident in our tech, that our 99.90% Uptime Guarantee will put your $$ back in your pocket if your site/server goes down.

              </p>

            </div>
          </div>
          <div class="row feature_row01 feature_row04">
            <div class="col-xl-3 hide_column"></div>
            <div class="col-xl-2 col-lg-12 col-md-4">
              <div class="feature_img_box mx-auto mx-md-0 mx-lg-auto mx-xl-0">
                <figure>
                  <Image src={hostingFet5} alt="" />
                </figure>
              </div>
            </div>
            {/* <div class="col hide_column d-noe d-lg-block"></div> */}
            <div class="col-xl-6 col-lg-10 offset-lg-1 offset-xl-0 text-lg-center text-xl-start col-md-8 feature_content_box text-center text-md-start">
              <h3>Disk customisation</h3>
              <p>
                When you order your dedicated server with us, you can customize/modify its storage capacity with a wide range of disks.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HostingFeaturesDedicated;
