import React, { useContext, useState } from "react";
import { Col, Container, Row, ButtonGroup, Button } from "react-bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading";
import BusinessHostingPlansRow from "./BusinessHostingPlansRow";
import { HostingContext } from "../../Contexts/HostingContext";
import { useSelector } from "react-redux";
import { currencies } from "../../constants/currencies";

// PLAN SCHEMA
const schema = {
  perMonth: 'perMonth',
  perYear: 'perYear',
  perThreeYears: 'perThreeYears'
}

const BusinessHostingPlans = (props) => {
  const { currency } = useSelector(state => state.currency);
  const { BusinessPackageHead } = useContext(HostingContext);
  // HOSTING PLAN
  const [plan, setPlan] = useState(schema.perMonth);

  const handleClick = (e) => {
    setPlan(e.target.value);
  };
  return (
    <div className="hosting-plans shared-hosting-plan bg-white" id="viewAllSpecs">
      <Container>
        <SectionHeading
          heading="Compare All Web Hosting Plans"
          desc="We sorted our web hosting packages are more user-friendly and well configured. Just an easy-as-pie way to get a great website up and running"
        />
        <Row>
          <Col md={12} className="text-center my-4 hosting-dur-btn">
            <ButtonGroup onClick={handleClick}>
              <Button value={schema.perMonth} variant={`outline-secondary ${plan === schema.perMonth ? 'active' : ''}`}>
                Monthly
              </Button>
              <Button value={schema.perYear} variant={`outline-secondary ${plan === schema.perYear ? 'active' : ''}`}>
                1 Year
              </Button>
              <Button value={schema.perThreeYears} variant={`outline-secondary ${plan === schema.perThreeYears ? 'active' : ''}`}>
                2 Year
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <div className="div-responsive">
          <Row>
            <Col md={12}>
              <div className="hosting-plans-head mt-5">
                <Row className="gx-0">
                  <Col xs={2}>
                    <div className="plan-head-card text-start">
                      <h5> ** All renewal prices are same as regular price.</h5>
                    </div>
                  </Col>

                  {BusinessPackageHead.map((packages) => {
                    return (
                      <Col key={packages.id}>
                        <div className="plan-head-card">
                          <p>{packages.name}</p>
                          {currency === currencies.BDT ?
                            <>
                              <h3>
                                {plan === schema.perMonth ?
                                  packages.orgPrice.BDT.perMonth
                                  : plan === schema.perYear ?
                                    packages.orgPrice.BDT.perYear
                                    : plan === schema.perThreeYears &&
                                    packages.orgPrice.BDT.perThreeYears
                                }
                              </h3>
                              <span>
                                {plan === schema.perMonth ?
                                  packages.disPrice.BDT.perMonth
                                  : plan === schema.perYear ?
                                    packages.disPrice.BDT.perYear
                                    : plan === schema.perThreeYears &&
                                    packages.disPrice.BDT.perThreeYears
                                }
                              </span>
                            </>
                            :
                            <>
                              <h3>
                                {plan === schema.perMonth ?
                                  packages.orgPrice.USD.perMonth
                                  : plan === schema.perYear ?
                                    packages.orgPrice.USD.perYear
                                    : plan === schema.perThreeYears &&
                                    packages.orgPrice.USD.perThreeYears
                                }
                              </h3>
                              <span>
                                {plan === schema.perMonth ?
                                  packages.disPrice.USD.perMonth
                                  : plan === schema.perYear ?
                                    packages.disPrice.USD.perYear
                                    : plan === schema.perThreeYears &&
                                    packages.disPrice.USD.perThreeYears
                                }
                              </span>
                            </>
                          }
                          <a href={packages.btnURL}>
                            <Button variant="primary" className="btn-host-order">
                              {packages.btnName}
                            </Button>
                          </a>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <BusinessHostingPlansRow />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default BusinessHostingPlans;
