import React from 'react';
import { Container, Row, Col, Button, Image } from "react-bootstrap";

import callMan from '../../assets/images/x1.jpg'

const SupportBlock = () => {
    return (
        <div className="support-block">
            <Container>
                <Row>
                    <Col md={7}>
                        <h3>Not sure where to Start?</h3>
                        <p>Talk to one of our web hosting experts who will review your needs and suggest a tailored hosting solution that will match your specific business reality and needs.</p>

                        <div className="support-item">
                            <a href="javascript:void(Tawk_API.toggle())">
                                <Button variant="info" className="btn-support">
                                    <span className="material-icons">question_answer</span>
                                    <small> Live Chat</small>
                                </Button>
                            </a>
                            <a href="https://my.levohost.com/submitticket.php">
                                <Button variant="warning" className="btn-support">
                                    <span className="material-icons">confirmation_number</span>
                                    <small>Support Tickets</small>
                                </Button>
                            </a>
                            <a href="/">
                                <Button variant="danger" className="btn-support">
                                    <span className="material-icons">email</span>
                                    <small>Send Email</small>
                                </Button>
                            </a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="support-man">
                            <Image src={callMan} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SupportBlock;
