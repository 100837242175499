import React from 'react';
import icon1 from "../../assets/images/why-choose/fast_server.svg";
import icon2 from "../../assets/images/why-choose/money-back-gaurantee.svg";
import icon3 from "../../assets/images/why-choose/pure_ssd.svg";
import icon4 from "../../assets/images/why-choose/support-icon.svg";

const WhyChooseLevoHost = () => {
    // CARD DATA
    const cardData = [
        {
            id: 1,
            icon: icon1,
            title: "Up To 20X Faster Servers",
            features: [
                {
                    id: 1,
                    text: "For the best performance & faster loading times, we use Solid-State Drive (SSD), super fast HTTP/2, popular security tools and also a great combination of hardware and software to make them more faster. With 20X Faster Servers, websites load quickly, providing a smooth user experience. This results in enhanced Google SEO rankings, increased visibility, and higher conversions. "
                }
            ]
        },
        {
            id: 2,
            icon: icon3,
            title: "Pure SSD (file,os,databases)",
            features: [
                {
                    id: 1,
                    text: "Solid State Drive ( SSD ) gives you super-fast read/write speed also can manage more disk requests then Standard HDD."
                },
                {
                    id: 2,
                    text: "We use SSD for everything (Files, OS & Databases) so you can get the biggest speed boost to your website."
                },
            ]
        },
        {
            id: 3,
            icon: icon4,
            title: "24/7/365 Technical Support",
            features: [
                {
                    id: 1,
                    text: "We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services."
                }
            ]
        },
        {
            id: 4,
            icon: icon2,
            title: "Money Back Guarantee",
            features: [
                {
                    id: 1,
                    text: "We offer a hassle-free 30 DAYS money-back guarantee on Shared, Cheap & Business web hosting packages."
                },
                {
                    id: 2,
                    text: "If you are unsatisfied for any reason, simply send us a refund request ticket, and we'll back your money within 7 working days."
                }
            ]
        }
    ];
    return (
        <div className="why-choose-levohost-area">
            <div className="container">
                <div className="why-choose-area-header">
                    <h2 className="title">Why Choose <span className="colored-text">Levohost</span></h2>
                    <p className="details">LevoHost offers the Best quality web hosting services at the lowest possible prices.</p>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 features-card-area pt-4 g-3">
                    {cardData.map(item =>
                        <div className="col" key={item.id}>
                            <div className="card h-100">
                                <div className="icon-box">
                                    <img className="img-fluid" src={item.icon} alt="icon" />
                                </div>
                                <h4 className="card-title">{item.title}</h4>
                                {item.features.map(feature =>
                                    <p className="feature-text">{feature.text}</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WhyChooseLevoHost;