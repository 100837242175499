import React, { Fragment, useContext } from "react";
import { Accordion, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { HostingContext } from "../../Contexts/HostingContext";
import { MdDone } from "react-icons/md";
import { useSelector } from "react-redux";
import { currencies } from "../../constants/currencies";

const BusinessHostingPlansRow = () => {
  const { currency } = useSelector(state => state.currency);
  const { BusinessHostingPackage } = useContext(HostingContext);
  return (
    <Fragment>
      <Accordion defaultActiveKey={['0']} alwaysOpen className="hosting-plans-toggle">
        {BusinessHostingPackage.map((data, i) => {
          return (
            <Accordion.Item eventKey={i + ""}>
              <Accordion.Header>{data.name}</Accordion.Header>

              <Accordion.Body>
                {data.packageItems.map((packageItem) => {
                  return (
                    <Row className="gx-0" key={packageItem.id}>
                      <Col xs={2}>
                        <div className="plan-heading">
                          <h3>{packageItem.diskSpace}</h3>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                {packageItem.tooltip}
                              </Tooltip>
                            }
                          >
                            <span className="material-icons d-inline-block">
                              help
                            </span>
                          </OverlayTrigger>
                        </div>
                      </Col>
                      <Col>
                        <div className="plan-col">
                          {data.section_uid === "pricing_plan" && currency === currencies.BDT ?
                            <p>{packageItem.BDT.col_1}</p>
                            : data.section_uid === "pricing_plan" && currency === currencies.USD ?
                              <p>{packageItem.USD.col_1}</p>
                              : packageItem.col_1.trim().toLowerCase() === 'yes' ?
                                <div className="done-icon"><MdDone /></div>
                                :
                                <p>{packageItem.col_1}</p>
                          }
                        </div>
                      </Col>
                      <Col>
                        <div className="plan-col">
                          {data.section_uid === "pricing_plan" && currency === currencies.BDT ?
                            <p>{packageItem.BDT.col_2}</p>
                            : data.section_uid === "pricing_plan" && currency === currencies.USD ?
                              <p>{packageItem.USD.col_2}</p>
                              : packageItem.col_2.trim().toLowerCase() === 'yes' ?
                                <div className="done-icon"><MdDone /></div>
                                :
                                <p>{packageItem.col_2}</p>
                          }
                        </div>
                      </Col>
                      <Col>
                        <div className="plan-col">
                          {data.section_uid === "pricing_plan" && currency === currencies.BDT ?
                            <p>{packageItem.BDT.col_3}</p>
                            : data.section_uid === "pricing_plan" && currency === currencies.USD ?
                              <p>{packageItem.USD.col_3}</p>
                              : packageItem.col_3.trim().toLowerCase() === 'yes' ?
                                <div className="done-icon"><MdDone /></div>
                                :
                                <p>{packageItem.col_3}</p>
                          }
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Fragment>
  );
};

export default BusinessHostingPlansRow;
