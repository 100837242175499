import React, { createContext } from "react";
import { v4 as uuid } from "uuid";
import avater from "../assets/images/sharif-vai.png";
import avater2 from "../assets/images/lara_client.png";
import avater3 from "../assets/images/absiddik.png";
import avater4 from "../assets/images/jabir.jpg";
import avater5 from "../assets/images/nasim.jpg";
export const ReviewContext = createContext();
const ReviewContextProvider = (props) => {
  const reviews = [
    {
      id: uuid(),
      name: "M.R. Islam Sharif",
      avater: avater,
      message:
        "LevoHost has been Host and Developed our website. It was a pleasure working with Team LevoHost through development of my Shipping company's website.",
      degination: "Director, Shibashipping"
    },
    {
      id: uuid(),
      name: "Sonia Lara",
      avater: avater2,
      message:
        " LevoHost consistently met and overcome my expectations in their professionalism and the quality of their deliverable.",
      degination: " Founder, Larahouse "
    },
    {
      id: uuid(),
      name: "AB Siddik",
      avater: avater3,
      message:
        "One of the best hosting services for the instant and friendly support team. Who doesn't want to take extra headache for managing their hosting differently I'll recommend for them"
    },
    {
      id: uuid(),
      name: "Jame Abir Khan",
      avater: avater4,
      message:
        "LevoHost has great support and is extremely concerned about their customers, Thanks again for your great support.",
      degination: "CEO - JamesCoders"
    },
    {
      id: uuid(),
      name: "Nasim Ahmed",
      avater: avater5,
      message:
        "The servers are super fast and the service they offer is top notch! Highly recommended!.",
 
    }
  ];

  return (
    <ReviewContext.Provider value={{ reviews }}>
      {props.children}
    </ReviewContext.Provider>
  );
};

export default ReviewContextProvider;
