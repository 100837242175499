import React from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Form, FloatingLabel, Button } from "react-bootstrap";
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const sendMessageHandler = (data, e) => {
        emailjs.sendForm("service_wvbgp03", "template_q9s5l4s", e.target, '3Q8m4x0uwb84C87md')
            .then((result) => {
                if (result.text === "OK") {
                    toast.success('Thank you for your message')
                    e.target.reset();
                }
            }, (error) => {
                toast.error(error.text);
            });
    };
    return (
        <div className="contact-form">
            <ToastContainer />
            <Container>
                <Row>
                    <Col md={6}>
                        <h3>For any <span>Query</span></h3>
                        <p>Whether you have a query about our product or services, offers, features, about our activities or anything else, our dedicated team members are always ready to answer all of your queries. Feel free to submit your queries, one of our team members will get back to you as soon as possible.</p>
                    </Col>
                    <Col md={6}>
                        <Form onSubmit={handleSubmit(sendMessageHandler)}>
                            <FloatingLabel
                                controlId="name"
                                label="Your Name"
                                className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    {...register("name", { required: true })}
                                />
                                {errors.name && <span className="text-danger">Enter you name!</span>}
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="name"
                                label="Your Email"
                                className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    {...register("email", { required: true })}
                                />
                                {errors.email && <span className="text-danger">Enter you email!</span>}
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingTextarea2" label="Your Message">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ height: '140px' }}
                                    name="message"
                                    {...register("message", { required: true })}
                                />
                                {errors.message && <span className="text-danger">Enter you message!</span>}
                            </FloatingLabel>
                            <Button variant="primary" type="submit" className="lvh-btn">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactForm;
