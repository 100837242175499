import React, { Fragment, useState, useEffect } from "react";
import { Accordion, Col, Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currencies } from "../../constants/currencies";

// PLAN SCHEMA
const schema = {
  perMonth: 'perMonth',
  perYear: 'perYear',
  perThreeYears: 'perThreeYears'
}

const ResellerHostingPlansRow = (props) => {
  const { currency } = useSelector(state => state.currency);
  const { plan } = props;
  // PRICE CHART 
  const priceChart = {
    basic_features: {
      RH_1: {
        BDT: {
          perMonth: "TK 1750/mo",
          perYear: " TK 10500/6 mo",
          perThreeYears: "TK 21000/yr"
        },
        USD: {
          perMonth: "$20.58/mo",
          perYear: " $123.52/6 mo",
          perThreeYears: "$247.05/yr"
        }
      },
      RH_2: {
        BDT: {
          perMonth: "TK 2950/mo",
          perYear: " TK 17700/6 mo",
          perThreeYears: "TK 35400/yr"
        },
        USD: {
          perMonth: "$34.7/mo",
          perYear: " $208.23/6 mo",
          perThreeYears: "$416.47/yr"
        }
      },
      RH_3: {
        BDT: {
          perMonth: "TK 5650/mo",
          perYear: " TK 33900/6 mo",
          perThreeYears: "TK 67800/yr"
        },
        USD: {
          perMonth: "$66.47/mo",
          perYear: " $398.82/6 mo",
          perThreeYears: "$797.64/yr"
        }
      },
      RH_4: {
        BDT: {
          perMonth: "TK 8650/mo",
          perYear: "TK 51900/6 mo",
          perThreeYears: "TK 103800/yr"
        },
        USD: {
          perMonth: "$101.76/mo",
          perYear: " $610.58/6 mo",
          perThreeYears: "$1221.17/yr"
        }
      }
    },
    server_features: {
      RH_1: {
        BDT: {
          perMonth: "TK 1750/mo",
          perYear: " TK 10500/6 mo",
          perThreeYears: "TK 21000/yr"
        },
        USD: {
          perMonth: "$20.58/mo",
          perYear: " $123.52/6 mo",
          perThreeYears: "$247.05/yr"
        }
      },
      RH_2: {
        BDT: {
          perMonth: "TK 2950/mo",
          perYear: " TK 17700/6 mo",
          perThreeYears: "TK 35400/yr"
        },
        USD: {
          perMonth: "$34.7/mo",
          perYear: " $208.23/6 mo",
          perThreeYears: "$416.47/yr"
        }
      },
      RH_3: {
        BDT: {
          perMonth: "TK 5650/mo",
          perYear: " TK 33900/6 mo",
          perThreeYears: "TK 67800/yr"
        },
        USD: {
          perMonth: "$66.47/mo",
          perYear: " $398.82/6 mo",
          perThreeYears: "$797.64/yr"
        }
      },
      RH_4: {
        BDT: {
          perMonth: "TK 8650/mo",
          perYear: "TK 51900/6 mo",
          perThreeYears: "TK 103800/yr"
        },
        USD: {
          perMonth: "$101.76/mo",
          perYear: " $610.58/6 mo",
          perThreeYears: "$1221.17/yr"
        }
      }
    },
    advanced_features: {
      RH_1: {
        BDT: {
          perMonth: "TK 1750/mo",
          perYear: " TK 10500/6 mo",
          perThreeYears: "TK 21000/yr"
        },
        USD: {
          perMonth: "$20.58/mo",
          perYear: " $123.52/6 mo",
          perThreeYears: "$247.05/yr"
        }
      },
      RH_2: {
        BDT: {
          perMonth: "TK 2950/mo",
          perYear: " TK 17700/6 mo",
          perThreeYears: "TK 35400/yr"
        },
        USD: {
          perMonth: "$34.7/mo",
          perYear: " $208.23/6 mo",
          perThreeYears: "$416.47/yr"
        }
      },
      RH_3: {
        BDT: {
          perMonth: "TK 5650/mo",
          perYear: " TK 33900/6 mo",
          perThreeYears: "TK 67800/yr"
        },
        USD: {
          perMonth: "$66.47/mo",
          perYear: " $398.82/6 mo",
          perThreeYears: "$797.64/yr"
        }
      },
      RH_4: {
        BDT: {
          perMonth: "TK 8650/mo",
          perYear: "TK 51900/6 mo",
          perThreeYears: "TK 103800/yr"
        },
        USD: {
          perMonth: "$101.76/mo",
          perYear: " $610.58/6 mo",
          perThreeYears: "$1221.17/yr"
        }
      }
    },
    database_management: {
      RH_1: {
        BDT: {
          perMonth: "TK 1750/mo",
          perYear: " TK 10500/6 mo",
          perThreeYears: "TK 21000/yr"
        },
        USD: {
          perMonth: "$20.58/mo",
          perYear: " $123.52/6 mo",
          perThreeYears: "$247.05/yr"
        }
      },
      RH_2: {
        BDT: {
          perMonth: "TK 2950/mo",
          perYear: " TK 17700/6 mo",
          perThreeYears: "TK 35400/yr"
        },
        USD: {
          perMonth: "$34.7/mo",
          perYear: " $208.23/6 mo",
          perThreeYears: "$416.47/yr"
        }
      },
      RH_3: {
        BDT: {
          perMonth: "TK 5650/mo",
          perYear: " TK 33900/6 mo",
          perThreeYears: "TK 67800/yr"
        },
        USD: {
          perMonth: "$66.47/mo",
          perYear: " $398.82/6 mo",
          perThreeYears: "$797.64/yr"
        }
      },
      RH_4: {
        BDT: {
          perMonth: "TK 8650/mo",
          perYear: "TK 51900/6 mo",
          perThreeYears: "TK 103800/yr"
        },
        USD: {
          perMonth: "$101.76/mo",
          perYear: " $610.58/6 mo",
          perThreeYears: "$1221.17/yr"
        }
      }
    }
  }
  // INITIAL PRICE STATE 
  const initialState = {
    basic_features: {
      BDT: {
        RH_1: priceChart.basic_features.RH_1.BDT.perYear,
        RH_2: priceChart.basic_features.RH_2.BDT.perYear,
        RH_3: priceChart.basic_features.RH_3.BDT.perYear,
        RH_4: priceChart.basic_features.RH_4.BDT.perYear
      },
      USD: {
        RH_1: priceChart.basic_features.RH_1.USD.perYear,
        RH_2: priceChart.basic_features.RH_2.USD.perYear,
        RH_3: priceChart.basic_features.RH_3.USD.perYear,
        RH_4: priceChart.basic_features.RH_4.USD.perYear
      }
    },
    server_features: {
      BDT: {
        RH_1: priceChart.server_features.RH_1.BDT.perYear,
        RH_2: priceChart.server_features.RH_2.BDT.perYear,
        RH_3: priceChart.server_features.RH_3.BDT.perYear,
        RH_4: priceChart.server_features.RH_4.BDT.perYear
      },
      USD: {
        RH_1: priceChart.server_features.RH_1.USD.perYear,
        RH_2: priceChart.server_features.RH_2.USD.perYear,
        RH_3: priceChart.server_features.RH_3.USD.perYear,
        RH_4: priceChart.server_features.RH_4.USD.perYear
      }
    },
    advanced_features: {
      BDT: {
        RH_1: priceChart.advanced_features.RH_1.BDT.perYear,
        RH_2: priceChart.advanced_features.RH_2.BDT.perYear,
        RH_3: priceChart.advanced_features.RH_3.BDT.perYear,
        RH_4: priceChart.advanced_features.RH_4.BDT.perYear
      },
      USD: {
        RH_1: priceChart.advanced_features.RH_1.USD.perYear,
        RH_2: priceChart.advanced_features.RH_2.USD.perYear,
        RH_3: priceChart.advanced_features.RH_3.USD.perYear,
        RH_4: priceChart.advanced_features.RH_4.USD.perYear
      }
    },
    database_management: {
      BDT: {
        RH_1: priceChart.database_management.RH_1.BDT.perYear,
        RH_2: priceChart.database_management.RH_2.BDT.perYear,
        RH_3: priceChart.database_management.RH_3.BDT.perYear,
        RH_4: priceChart.database_management.RH_4.BDT.perYear
      },
      USD: {
        RH_1: priceChart.database_management.RH_1.USD.perYear,
        RH_2: priceChart.database_management.RH_2.USD.perYear,
        RH_3: priceChart.database_management.RH_3.USD.perYear,
        RH_4: priceChart.database_management.RH_4.USD.perYear

      }
    }
  }
  const [price, setPrice] = useState(initialState);
  useEffect(() => {
    // PLAN PRICE DETECTOR
    if (plan === schema.perMonth) {
      setPrice({
        basic_features: {
          BDT: {
            RH_1: priceChart.basic_features.RH_1.BDT.perMonth,
            RH_2: priceChart.basic_features.RH_2.BDT.perMonth,
            RH_3: priceChart.basic_features.RH_3.BDT.perMonth,
            RH_4: priceChart.basic_features.RH_4.BDT.perMonth
          },
          USD: {
            RH_1: priceChart.basic_features.RH_1.USD.perMonth,
            RH_2: priceChart.basic_features.RH_2.USD.perMonth,
            RH_3: priceChart.basic_features.RH_3.USD.perMonth,
            RH_4: priceChart.basic_features.RH_4.USD.perMonth
          }
        },
        server_features: {
          BDT: {
            RH_1: priceChart.server_features.RH_1.BDT.perMonth,
            RH_2: priceChart.server_features.RH_2.BDT.perMonth,
            RH_3: priceChart.server_features.RH_3.BDT.perMonth,
            RH_4: priceChart.server_features.RH_4.BDT.perMonth
          },
          USD: {
            RH_1: priceChart.server_features.RH_1.USD.perMonth,
            RH_2: priceChart.server_features.RH_2.USD.perMonth,
            RH_3: priceChart.server_features.RH_3.USD.perMonth,
            RH_4: priceChart.server_features.RH_4.USD.perMonth
          }
        },
        advanced_features: {
          BDT: {
            RH_1: priceChart.advanced_features.RH_1.BDT.perMonth,
            RH_2: priceChart.advanced_features.RH_2.BDT.perMonth,
            RH_3: priceChart.advanced_features.RH_3.BDT.perMonth,
            RH_4: priceChart.advanced_features.RH_4.BDT.perMonth
          },
          USD: {
            RH_1: priceChart.advanced_features.RH_1.USD.perMonth,
            RH_2: priceChart.advanced_features.RH_2.USD.perMonth,
            RH_3: priceChart.advanced_features.RH_3.USD.perMonth,
            RH_4: priceChart.advanced_features.RH_4.USD.perMonth
          }
        },
        database_management: {
          BDT: {
            RH_1: priceChart.database_management.RH_1.BDT.perMonth,
            RH_2: priceChart.database_management.RH_2.BDT.perMonth,
            RH_3: priceChart.database_management.RH_3.BDT.perMonth,
            RH_4: priceChart.database_management.RH_4.BDT.perMonth
          },
          USD: {
            RH_1: priceChart.database_management.RH_1.USD.perMonth,
            RH_2: priceChart.database_management.RH_2.USD.perMonth,
            RH_3: priceChart.database_management.RH_3.USD.perMonth,
            RH_4: priceChart.database_management.RH_4.USD.perMonth

          }
        }
      });
    } else if (plan === schema.perYear) {
      setPrice(initialState);
    } else if (plan === schema.perThreeYears) {
      setPrice({
        basic_features: {
          BDT: {
            RH_1: priceChart.basic_features.RH_1.BDT.perThreeYears,
            RH_2: priceChart.basic_features.RH_2.BDT.perThreeYears,
            RH_3: priceChart.basic_features.RH_3.BDT.perThreeYears,
            RH_4: priceChart.basic_features.RH_4.BDT.perThreeYears
          },
          USD: {
            RH_1: priceChart.basic_features.RH_1.USD.perThreeYears,
            RH_2: priceChart.basic_features.RH_2.USD.perThreeYears,
            RH_3: priceChart.basic_features.RH_3.USD.perThreeYears,
            RH_4: priceChart.basic_features.RH_4.USD.perThreeYears
          }
        },
        server_features: {
          BDT: {
            RH_1: priceChart.server_features.RH_1.BDT.perThreeYears,
            RH_2: priceChart.server_features.RH_2.BDT.perThreeYears,
            RH_3: priceChart.server_features.RH_3.BDT.perThreeYears,
            RH_4: priceChart.server_features.RH_4.BDT.perThreeYears
          },
          USD: {
            RH_1: priceChart.server_features.RH_1.USD.perThreeYears,
            RH_2: priceChart.server_features.RH_2.USD.perThreeYears,
            RH_3: priceChart.server_features.RH_3.USD.perThreeYears,
            RH_4: priceChart.server_features.RH_4.USD.perThreeYears
          }
        },
        advanced_features: {
          BDT: {
            RH_1: priceChart.advanced_features.RH_1.BDT.perThreeYears,
            RH_2: priceChart.advanced_features.RH_2.BDT.perThreeYears,
            RH_3: priceChart.advanced_features.RH_3.BDT.perThreeYears,
            RH_4: priceChart.advanced_features.RH_4.BDT.perThreeYears
          },
          USD: {
            RH_1: priceChart.advanced_features.RH_1.USD.perThreeYears,
            RH_2: priceChart.advanced_features.RH_2.USD.perThreeYears,
            RH_3: priceChart.advanced_features.RH_3.USD.perThreeYears,
            RH_4: priceChart.advanced_features.RH_4.USD.perThreeYears
          }
        },
        database_management: {
          BDT: {
            RH_1: priceChart.database_management.RH_1.BDT.perThreeYears,
            RH_2: priceChart.database_management.RH_2.BDT.perThreeYears,
            RH_3: priceChart.database_management.RH_3.BDT.perThreeYears,
            RH_4: priceChart.database_management.RH_4.BDT.perThreeYears
          },
          USD: {
            RH_1: priceChart.database_management.RH_1.USD.perThreeYears,
            RH_2: priceChart.database_management.RH_2.USD.perThreeYears,
            RH_3: priceChart.database_management.RH_3.USD.perThreeYears,
            RH_4: priceChart.database_management.RH_4.USD.perThreeYears

          }
        }
      });
    }
  }, [plan])

  return (
    <Fragment>
      <Accordion defaultActiveKey={['0']} alwaysOpen className="hosting-plans-toggle">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Basic Features</Accordion.Header>
          <Accordion.Body>
            <Row className="gx-0 hosting-plans-title-head">
              <Col xs={3}></Col>
              <Col className="hosting-plan-title">RWH-01</Col>
              <Col className="hosting-plan-title">RWH-02</Col>
              <Col className="hosting-plan-title">RWH-03</Col>
              <Col className="hosting-plan-title">RWH-04</Col>
            </Row>
            <Row className="gx-0">
              <Col xs={3}></Col>
              {currency === currencies.BDT ?
                <>
                  <Col className="hosting-plan-price">{price.basic_features.BDT.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.basic_features.BDT.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.basic_features.BDT.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.basic_features.BDT.RH_4}</Col>
                </>
                : <>
                  <Col className="hosting-plan-price">{price.basic_features.USD.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.basic_features.USD.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.basic_features.USD.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.basic_features.USD.RH_4}</Col>
                </>
              }
            </Row>

            <div className="table-body">
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>SSD Disk Space</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>25 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>50 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>100 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>150 GB</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Monthly Bandwidth</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>250 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>500 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1000 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1500 GB</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>cPanel Account Allowed</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>25</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>50</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>100</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>150</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Entry Processes</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>20</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>20</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>20</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>20</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>White Lebeled Reseller Hosting</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p> YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Private Name Servers</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p> YES</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>LiteSpeed with LSCache</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Cloudlinux OS </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
            </div>

            <Row className="gx-0">
              <Col xs={3} className="hosting-plan-btn"></Col>
              <Col className="hosting-plan-btn">

                <a href="https://my.levohost.com/store/reseller-hosting/rwh-01">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-02">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-03">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-04">
                  <Button>Order Now</Button>
                </a>

              </Col>

            </Row>

          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Server Features</Accordion.Header>
          <Accordion.Body>
            <Row className="gx-0 hosting-plans-title-head">
              <Col xs={3}></Col>
              <Col className="hosting-plan-title">RWH-01</Col>
              <Col className="hosting-plan-title">RWH-02</Col>
              <Col className="hosting-plan-title">RWH-03</Col>
              <Col className="hosting-plan-title">RWH-04</Col>
            </Row>
            <Row className="gx-0">
              <Col xs={3}></Col>
              {currency === currencies.BDT ?
                <>
                  <Col className="hosting-plan-price">{price.server_features.BDT.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.server_features.BDT.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.server_features.BDT.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.server_features.BDT.RH_4}</Col>
                </>
                : <>
                  <Col className="hosting-plan-price">{price.server_features.USD.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.server_features.USD.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.server_features.USD.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.server_features.USD.RH_4}</Col>
                </>
              }
            </Row>
            <div className="table-body">
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Cores Per cPanel User</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1 Core</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1 Core</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1 Core</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1 Core</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Physical Memory Per cPanel User</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p> 1 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1 GB</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>1 GB</p>
                  </div>
                </Col>
              </Row>


              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Webserver</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>Apache/Litespeed</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>Apache/Litespeed</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>Apache/Litespeed</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>Apache/Litespeed</p>
                  </div>
                </Col>
              </Row>


              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>PHP 5.2-5.6, 7.0-7.4 </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>

              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Node.JS 6.16, 8.17, 9.11, 10.19, 11.15, 12.9</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>

              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>MariaDB 10.X.X </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>

              </Row>              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Ruby 1.8-2.6 </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>

              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Python 2.7, 3.3-3.8</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Perl </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Javascript </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>xCache </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>

              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>SSH Access (Jailed)</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>



            </div>
            <Row className="gx-0">
              <Col xs={3} className="hosting-plan-btn"></Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-01">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-02">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-03">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-04">
                  <Button>Order Now</Button>
                </a>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="2">
          <Accordion.Header>cPanel Features</Accordion.Header>
          <Accordion.Body>
            <Row className="gx-0 hosting-plans-title-head">
              <Col xs={3}></Col>
              <Col className="hosting-plan-title">RWH-01</Col>
              <Col className="hosting-plan-title">RWH-02</Col>
              <Col className="hosting-plan-title">RWH-03</Col>
              <Col className="hosting-plan-title">RWH-04</Col>
            </Row>
            <Row className="gx-0">
              <Col xs={3}></Col>
              {currency === currencies.BDT ?
                <>
                  <Col className="hosting-plan-price">{price.advanced_features.BDT.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.advanced_features.BDT.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.advanced_features.BDT.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.advanced_features.BDT.RH_4}</Col>
                </>
                : <>
                  <Col className="hosting-plan-price">{price.advanced_features.USD.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.advanced_features.USD.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.advanced_features.USD.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.advanced_features.USD.RH_4}</Col>
                </>
              }
            </Row>
            <div className="table-body">
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Virus Scanner</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Apache Handlers Manager </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Hotlink Protection </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>


              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>IP Blocker</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>


              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Index Manager</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>


              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Leech Protect</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>



              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Cron Jobs </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>


              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Simple DNS Zone Editor </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>

              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Advanced DNS Zone Editor </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>SSL/TLS Manager</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>

            </div>
            <Row className="gx-0">
              <Col xs={3} className="hosting-plan-btn"></Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-01">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-02">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-03">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-04">
                  <Button>Order Now</Button>
                </a>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Support Features</Accordion.Header>
          <Accordion.Body>
            <Row className="gx-0 hosting-plans-title-head">
              <Col xs={3}></Col>
              <Col className="hosting-plan-title">RWH-01</Col>
              <Col className="hosting-plan-title">RWH-02</Col>
              <Col className="hosting-plan-title">RWH-03</Col>
              <Col className="hosting-plan-title">RWH-04</Col>
            </Row>
            <Row className="gx-0">
              <Col xs={3}></Col>
              {currency === currencies.BDT ?
                <>
                  <Col className="hosting-plan-price">{price.database_management.BDT.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.database_management.BDT.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.database_management.BDT.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.database_management.BDT.RH_4}</Col>
                </>
                : <>
                  <Col className="hosting-plan-price">{price.database_management.USD.RH_1}</Col>
                  <Col className="hosting-plan-price">{price.database_management.USD.RH_2}</Col>
                  <Col className="hosting-plan-price">{price.database_management.USD.RH_3}</Col>
                  <Col className="hosting-plan-price">{price.database_management.USD.RH_4}</Col>
                </>
              }
            </Row>
            <div className="table-body">
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p> Live Chat Support:  </p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>24/7 Ticket Support</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col xs={3}>
                  <div className="plan-heading">
                    <p>Phone Support</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
                <Col>
                  <div className="plan-col">
                    <p>YES</p>
                  </div>
                </Col>
              </Row>



            </div>
            <Row className="gx-0">
              <Col xs={3} className="hosting-plan-btn"></Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-01">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-02">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-03">
                  <Button>Order Now</Button>
                </a>
              </Col>
              <Col className="hosting-plan-btn">
                <a href="https://my.levohost.com/store/reseller-hosting/rwh-04">
                  <Button>Order Now</Button>
                </a>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default ResellerHostingPlansRow;
