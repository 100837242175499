import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import logo from "../../../assets/images/cpanel-logo.png";
import logo2 from "../../../assets/images/litespeed.svg";
import logo3 from "../../../assets/images/247x.png";

const ExtraFeatures = (props) => {
  return (
    <div className="extra-features">
      <Container>
        <Row>
          <Col md={4}>
            <div className="extra-features-card">
              <span className="icon">
                <Image src={logo} />
              </span>
              <h3>{props.heading_1}</h3>
              <p>{props.desc_1}</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="extra-features-card">
              <span className="icon">
                <Image src={logo2} />
              </span>
              <h3>{props.heading_2}</h3>
              <p>{props.desc_2}</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="extra-features-card">
              <span className="icon">
                <Image src={logo3} />
              </span>
              <h3>{props.heading_3}</h3>
              <p>{props.desc_3}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExtraFeatures;
