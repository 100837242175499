import { useSelector } from 'react-redux';
import { currencies } from '../../constants/currencies';

const TopLevelDomain = () => {
    const { currency } = useSelector(state => state.currency);
    // DOMAIN DATA
    const domainData = [
        {
            id: 1,
            domain_name: ".com",
            price: {
                BDT: "TK 1199/yr",
                USD: "$10.42/yr"
            },
            off: ""
        },
        {
            id: 2,
            domain_name: ".club",
            price: {
                BDT: "TK 2299/yr",
                USD: "$19.99/yr"
            },
            off: ""
        },
        {
            id: 3,
            domain_name: ".biz",
            price: {
                BDT: "TK 1899/yr",
                USD: "$16.51/yr"
            },
            off: ""
        },
        {
            id: 4,
            domain_name: ".xyz",
            price: {
                BDT: "TK 350/yr",
                USD: "$2.99/yr"
            },
            off: ""
        },
    ];
    // SECTION DATA
    const sectionData = {
        title: "Top Level Domain",
        description: "Register a new domain or transfer your existing ones now! We've catagorize our domain pricing as low as possible with lots of premium features. From below you will find Top Level domain (TLD) name & prices."
    }
    return (
        <div className="top-level-domain-section pb-5 pb-md-4">
            <div className="container">
                <div className="text-center p-5">
                    <h2 className="domain-title fs-1 mb-4">{sectionData.title}</h2>
                    <p className="domain-text lead">{sectionData.description}</p>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-2">
                    {domainData.map(data =>
                        <div className="col" key={data.id}>
                            <div className="card h-100 shadow domain-card">
                                <div className="domain-card-header">
                                    <div className="header-left">
                                        <h2 className="fs-1">{data.domain_name}</h2>
                                    </div>
                                    <div className="header-right">
                                        <p> SALE <br /> {data.off} </p>
                                    </div>
                                </div>
                                <div className="domain-card-body">
                                    <h5 className="price fs-3">{currency === currencies.BDT ? data.price.BDT : data.price.USD}</h5>
                                    <a href="https://my.levohost.com/cart.php?a=add&domain=register">

                                        <button className="btn domain-btn">Register</button>
                                    </a>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopLevelDomain;