import React from 'react';
import {Button} from 'react-bootstrap'

const GetStarted = () => {
    return (
        <div className="get-started text-center">
            <h3>Let's Get Stared!</h3>
            <p>Take your online business to the next level with levohost's Cloud VPS hosting. High performance, Pure SSD setups, server management, and more, all on a high-performance network. </p>
            <Button variant="outline-light" className="btn-border-1 mt-4">Get started now</Button>
        </div>
    );
};

export default GetStarted;
