import React, { createContext } from "react";
import { v4 as uuid } from "uuid";

export const HostingContext = createContext();
const HostingContextProvider = (props) => {
  // Shared Hosting Content
  const SharedPackageHead = [
    {
      id: uuid(),
      name: "Starter",
      orgPrice: {
        BDT: {
          perMonth: "TK 140/mo",
          perYear: "TK 1049/y",
          perThreeYears: "TK 1799/2y"
        },
        USD: {
          perMonth: "$1.64/mo",
          perYear: "$12.34/y",
          perThreeYears: "$21.16/2y"
        }
      },
      disPrice: {
        BDT: {
          perMonth: "Regular: TK 175/mo",
          perYear: "Regular: TK 1499/y",
          perThreeYears: "Regular: TK 2998/2y"
        },
        USD: {
          perMonth: "Regular: $2.05/mo",
          perYear: "Regular: $17.63/y",
          perThreeYears: "Regular: $35.27/2y"
        }
      },
      btnName: "Order Now",
      btnURL: "https://my.levohost.com/store/shared-web-hosting/starter"
    },
    {
      id: uuid(),
      name: "Standard",
      orgPrice: {
        BDT: {
          perMonth: "TK 239/mo",
          perYear: "TK 2099/y",
          perThreeYears: "TK 3599/2y"
        },
        USD: {
          perMonth: "$2.81/mo",
          perYear: "$24.69/y",
          perThreeYears: "$42.34/2y"
        }
      },
      disPrice: {
        BDT: {
          perMonth: "Regular: TK 299/mo",
          perYear: "Regular: TK 2999/y",
          perThreeYears: "Regular: TK 5998/2y"
        },
        USD: {
          perMonth: "Regular: $3.51/mo",
          perYear: "Regular: $35.28/y",
          perThreeYears: "Regular: $70.56/2y"
        }
      },
      btnName: "Order Now",
      btnURL: "https://my.levohost.com/store/shared-web-hosting/standard"
    },
    {
      id: uuid(),
      name: "Advanced",
      orgPrice: {
        BDT: {
          perMonth: "TK 319/mo",
          perYear: "TK 2799/y",
          perThreeYears: "TK 4799/2y"
        },
        USD: {
          perMonth: "$3.75/mo",
          perYear: "$32.92/y",
          perThreeYears: "$56.45/2y"
        }
      },
      disPrice: {
        BDT: {
          perMonth: "Regular: TK 399/mo",
          perYear: "Regular: TK 3999/y",
          perThreeYears: "Regular: TK 7998/2y"
        },
        USD: {
          perMonth: "Regular: $4.69/mo",
          perYear: "Regular: $47.04/y",
          perThreeYears: "Regular: $94.09/2y"
        }
      },
      btnName: "Order Now",
      btnURL: "https://my.levohost.com/store/shared-web-hosting/advanced"
    }
  ];
  const sharedHostingPackage = [
    {
      id: uuid(),
      section_uid: "core_features", // This section_uid should not changed
      name: "Core Features",
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Disk Space",
          tooltip: "",
          col_1: "2 GB SSD",
          col_2: "5 GB SSD",
          col_3: "10 GB SSD"
        },
        {
          id: uuid(),
          diskSpace: "Bandwidth",
          tooltip: "",
          col_1: "100 GB",
          col_2: "250 GB",
          col_3: "500 GB"
        },
        {
          id: uuid(),
          diskSpace: "Entry Process",
          tooltip: "",
          col_1: "20",
          col_2: "30",
          col_3: "40"
        },

        {
          id: uuid(),
          diskSpace: "Addon Domain",
          tooltip: "",
          col_1: "02",
          col_2: "05",
          col_3: "10"
        },

        {
          id: uuid(),
          diskSpace: "Subdomain",
          tooltip: "",
          col_1: "10",
          col_2: "25",
          col_3: "50"
        },
        {
          id: uuid(),
          diskSpace: "FTP Accounts",
          tooltip: "",
          col_1: "25",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },


        {
          id: uuid(),
          diskSpace: "Parked Domains ",
          tooltip: "",
          col_1: " Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "cPanel Control Panel",
          tooltip: "",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Uptime",
          tooltip: "",
          col_1: "99.99%",
          col_2: "99.99%",
          col_3: "99.99%"
        },
        {
          id: uuid(),
          diskSpace: "Backup",
          tooltip: "",
          col_1: "Twice a Week",
          col_2: "Twice a Week",
          col_3: "Twice a Week"
        },
        {
          id: uuid(),
          diskSpace: "Free SSL Certificate",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "30 days money back",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "24/7 Technical Support",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },
    {
      id: uuid(),
      name: "Server Features",
      section_uid: "server_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Cores Per cPanel User",
          tooltip: ".",
          col_1: "1 Core",
          col_2: "1 Core",
          col_3: "1 Core"
        },
        {
          id: uuid(),
          diskSpace: "Physical Memorey Per cPanel User",
          tooltip: ".",
          col_1: "1 GB",
          col_2: "1.5 GB",
          col_3: "2 GB"
        },
        {
          id: uuid(),
          diskSpace: "Webserver",
          tooltip: ".",
          col_1: "Apache/Litespeed",
          col_2: "Apache/Litespeed",
          col_3: "Apache/Litespeed"
        },

        {
          id: uuid(),
          diskSpace: "PHP 5.2-5.6, 7.0-7.4 ",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Node.JS 6.16, 8.17, 9.11, 10.19, 11.15, 12.9",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "MariaDB 10.X.X ",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Ruby 1.8-2.6 ",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Python 2.7, 3.3-3.8 ",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Perl ",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Javascript ",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "xCache  ",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "SSH Access (Jailed)",
          tooltip: ".",
          col_1: " YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },
    {
      id: uuid(),
      name: "Database Features",
      section_uid: "database_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "MySQL Databases",
          tooltip: "",
          col_1: "20",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "PHP MyAdmin",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "PHP PgAdmin",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "PostgreSQL Databases",
          tooltip: ".",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        }
      ]
    },
    {
      id: uuid(),
      name: "Email Features",
      section_uid: "email_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Email Accounts",
          tooltip: "",
          col_1: "25",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "Email Forwarders ",
          tooltip: "",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "Webmail",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "SMTP, POP3, IMAP",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "SpamAssassin",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "BoxTrapper ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Mailing Lists ",
          tooltip: "",
          col_1: "30",
          col_2: "Unlimited",
          col_3: "Unlimited"
        }
      ]
    },
    {
      id: uuid(),
      name: "Control Panel Features",
      section_uid: "control_panel_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Virus Scanner",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Apache Handlers Manager ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Hotlink Protection ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "IP Blocker",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Index Manager ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Leech Protect",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Cron Jobs ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Simple DNS Zone Editor ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Advanced DNS Zone Editor ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "SSL/TLS Manager ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },

    {
      id: uuid(),
      name: "Support Features",
      section_uid: "support_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "24/7/365 Technical Support ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Live Chat Support",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "AnyDesk Support (Technical)",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Phone Support",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },

    {
      id: uuid(),
      name: "Pricing Plan",
      section_uid: "pricing_plan", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "1 Month ",
          tooltip: "",
          BDT: {
            col_1: "TK 175",
            col_2: "TK 299",
            col_3: "TK 399"
          },
          USD: {
            col_1: "$2.05",
            col_2: "$3.51",
            col_3: "$4.69"
          }
        },
        {
          id: uuid(),
          diskSpace: "1 Year",
          tooltip: "",
          BDT: {
            col_1: "TK 1499",
            col_2: "TK 2999",
            col_3: "TK 3999"
          },
          USD: {
            col_1: "$17.63",
            col_2: "$35.28",
            col_3: "$47.04"
          }
        },
        {
          id: uuid(),
          diskSpace: "2 Year",
          tooltip: "",
          BDT: {
            col_1: "TK 2998",
            col_2: "TK 5998",
            col_3: "TK 7998"
          },
          USD: {
            col_1: "$35.27",
            col_2: "$70.56",
            col_3: "$94.09"
          }
        }
      ]
    }
  ];

  // Business Hosting Content
  const BusinessPackageHead = [
    {
      id: uuid(),
      name: "Silver",
      orgPrice: {
        BDT: {
          perMonth: "TK 799/mo",
          perYear: "TK 7350/y",
          perThreeYears: "TK 12600/2y"
        },
        USD: {
          perMonth: "$9.4/mo",
          perYear: "$86.47/y",
          perThreeYears: "$148.23/2y"
        }
      },
      disPrice: {
        BDT: {
          perMonth: "Regular: TK 999/mo",
          perYear: "Regular: TK 10500/y",
          perThreeYears: "Regular: TK 21000/2y"
        },
        USD: {
          perMonth: "Regular: $11.75/mo",
          perYear: "Regular: $123.52/y",
          perThreeYears: "Regular: $247.05/2y"
        }
      },
      btnName: "Order Now",
      btnURL: "https://my.levohost.com/store/business-hosting/silver"
    },
    {
      id: uuid(),
      name: "Gold",
      orgPrice: {
        BDT: {
          perMonth: "TK 1240/mo",
          perYear: "TK 12250/y",
          perThreeYears: "TK 21000/2y"
        },
        USD: {
          perMonth: "$14.58/mo",
          perYear: "$144.11/y",
          perThreeYears: "$247.05/2y"
        }
      },
      disPrice: {
        BDT: {
          perMonth: "Regular: TK 1550/mo",
          perYear: "Regular: TK 17500/y",
          perThreeYears: "Regular: TK 35000/2y"
        },
        USD: {
          perMonth: "Regular: $18.23/mo",
          perYear: "Regular: $205.88/y",
          perThreeYears: "Regular: $411.76/2y"
        }
      },
      btnName: "Order Now",
      btnURL: "https://my.levohost.com/store/business-hosting/gold"
    },
    {
      id: uuid(),
      name: "Diamond",
      orgPrice: {
        BDT: {
          perMonth: "TK 1720/mo",
          perYear: "TK 17150/y",
          perThreeYears: "TK 29400/2y"
        },
        USD: {
          perMonth: "$20.23/mo",
          perYear: "$288.23/y",
          perThreeYears: "$576.47/2y"
        }
      },
      disPrice: {
        BDT: {
          perMonth: "Regular: TK 2150/mo",
          perYear: "Regular: TK 24500/y",
          perThreeYears: "Regular: TK 49000/2y"
        },
        USD: {
          perMonth: "Regular: $25.29/mo",
          perYear: "Regular: $288.23/y",
          perThreeYears: "Regular: $576.47/2y"
        }
      },
      btnName: "Order Now",
      btnURL: "https://my.levohost.com/store/business-hosting/diamond"
    }
  ];
  const BusinessHostingPackage = [
    {
      id: uuid(),
      name: "Plan Features",
      section_uid: "plan_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Disk Space",
          tooltip: "",
          col_1: "25 GB SSD",
          col_2: "50 GB SSD",
          col_3: "100 GB SSD"
        },
        {
          id: uuid(),
          diskSpace: "Bandwidth",
          tooltip: "",
          col_1: "Unmetered",
          col_2: "Unmetered",
          col_3: "Unmetered"
        },
        {
          id: uuid(),
          diskSpace: "Entry Process",
          tooltip: "",
          col_1: "25",
          col_2: "30",
          col_3: "40"
        },
        {
          id: uuid(),
          diskSpace: "Addon Domain",
          tooltip: "",
          col_1: "05",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "Subdomain",
          tooltip: "",
          col_1: "25",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "FTP Accounts",
          tooltip: "",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "cPanel Control Pane",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },

        {
          id: uuid(),
          diskSpace: "Uptime",
          tooltip: "",
          col_1: "99.99%",
          col_2: "99.99%",
          col_3: "99.99%"
        },
        {
          id: uuid(),
          diskSpace: "Backup",
          tooltip: "",
          col_1: "Twice a Week",
          col_2: "Twice a Week",
          col_3: "Twice a Week"
        },
        {
          id: uuid(),
          diskSpace: "Free SSL Certificate",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Free .COM domain Registration/Transfer",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "30 days money back",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "24/7 Technical Support",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },
    {
      id: uuid(),
      name: "Server Features",
      section_uid: "server_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Cores Per cPanel User",
          tooltip: ".",
          col_1: "1.5 core",
          col_2: "2 core",
          col_3: "2 core"
        },
        {
          id: uuid(),
          diskSpace: "Physical Memorey Per cPanel User",
          tooltip: ".",
          col_1: "1 GB",
          col_2: "2 GB",
          col_3: "3 GB"
        },
        {
          id: uuid(),
          diskSpace: "Webserver",
          tooltip: "",
          col_1: "Apache/Litespeed",
          col_2: "Apache/Litespeed",
          col_3: "Apache/Litespeed"
        },
        {
          id: uuid(),
          diskSpace: "PHP 5.2-5.6, 7.0-7.4 ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Node.JS 6.16, 8.17, 9.11, 10.19, 11.15, 12.9",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "MariaDB 10.X.X ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Ruby 1.8-2.6 ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Python 2.7, 3.3-3.8 ",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Perl",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Javascript",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "xCache",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "SSH Access",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },
    {
      id: uuid(),
      name: "Database Features",
      section_uid: "database_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "MySQL Databases",
          tooltip: ".",
          col_1: "25",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "PHP MyAdmin",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "PHP PgAdmin",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "PostgreSQL Databases",
          tooltip: ".",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        }
      ]
    },
    {
      id: uuid(),
      name: "Email Features",
      section_uid: "email_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Email Accounts",
          tooltip: ".",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "Email Forwarders ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Webmail",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "SMTP, POP3, IMAP",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "SpamAssassin",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "BoxTrapper ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Mailing Lists  ",
          tooltip: ".",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        }
      ]
    },

    {
      id: uuid(),
      name: "Control Panel Features",
      section_uid: "control_panel_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Virus Scanner",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Apache Handlers Manager ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Hotlink Protection ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "IP Blocker",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Index Manager",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Leech Protect",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Cron Jobs ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Simple DNS Zone Editor ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Advanced DNS Zone Editor ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "SSL/TLS Manager  ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },

    {
      id: uuid(),
      name: "Support Features",
      section_uid: "support_features", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "24/7/365 Technical Support ",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Live Chat Support",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "Phone Support",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "AnyDesk Support (Technical)",
          tooltip: ".",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    },
    {
      id: uuid(),
      name: "Pricing Plan",
      section_uid: "pricing_plan", // This section_uid should not changed
      packageItems: [
        {
          id: uuid(),
          diskSpace: "1 Month ",
          tooltip: ".",
          BDT: {
            col_1: "TK 999",
            col_2: "TK 1550",
            col_3: "TK 2150"
          },
          USD: {
            col_1: "$11.75",
            col_2: "$18.23",
            col_3: "$25.29"
          }
        },
        {
          id: uuid(),
          diskSpace: "1 Year",
          tooltip: ".",
          BDT: {
            col_1: "TK 10500",
            col_2: "TK 17500",
            col_3: "TK 24500"
          },
          USD: {
            col_1: "$123.52",
            col_2: "$205.88",
            col_3: "$288.23"
          }
        },
        {
          id: uuid(),
          diskSpace: "2 Year",
          tooltip: ".",
          BDT: {
            col_1: "TK 21000",
            col_2: "TK 35000",
            col_3: "TK 49000"
          },
          USD: {
            col_1: "$247.05",
            col_2: "$411.76",
            col_3: "$576.47"
          }
        }
      ]
    }
  ];

  // Reseller Hosting Content
  const ResellerPackageHead = [
    {
      id: uuid(),
      name: "RWH-1",
      orgPrice: "TK 2000/mo",
      disPrice: "Regular: TK 3000/mo",
      btnName: "Order Now",
      btnURL: ""
    },
    {
      id: uuid(),
      name: "RWH-2",
      orgPrice: "TK 2000/mo",
      disPrice: "Regular: TK 3000/mo",
      btnName: "Order Now",
      btnURL: ""
    },
    {
      id: uuid(),
      name: "RWH-3",
      orgPrice: "TK 2000/mo",
      disPrice: "Regular: TK 3000/mo",
      btnName: "Order Now",
      btnURL: ""
    }
  ];

  const ResellerHostingPackage = [
    {
      id: uuid(),
      name: "Basic Features",
      packageItems: [
        {
          id: uuid(),
          diskSpace: "Disk Space",
          tooltip: "",
          col_1: "50 GB SSD",
          col_2: "100 GB SSD",
          col_3: "150 GB SSD"
        },
        {
          id: uuid(),
          diskSpace: "Bandwidth",
          tooltip: "",
          col_1: "500 GB ",
          col_2: "1000 GB",
          col_3: "1500 GB"
        },
        {
          id: uuid(),
          diskSpace: "Entry Process",
          tooltip: "",
          col_1: "20",
          col_2: "20",
          col_3: "20"
        },
        {
          id: uuid(),
          diskSpace: "Addon Domain",
          tooltip: "",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "Subdomain",
          tooltip: "",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "FTP Accounts",
          tooltip: "",
          col_1: "Unlimited",
          col_2: "Unlimited",
          col_3: "Unlimited"
        },
        {
          id: uuid(),
          diskSpace: "cPanel Accounts",
          tooltip: "",
          col_1: "25",
          col_2: "50",
          col_3: "80"
        },
        {
          id: uuid(),
          diskSpace: "File (Inode) limit",
          tooltip: "",
          col_1: "X",
          col_2: "X",
          col_3: "X"
        },
        {
          id: uuid(),
          diskSpace: "Uptime",
          tooltip: "",
          col_1: "99.99%",
          col_2: "99.99%",
          col_3: "99.99%"
        },
        {
          id: uuid(),
          diskSpace: "Backup",
          tooltip: "",
          col_1: "Daily/Weekly/Monthly",
          col_2: "Daily/Weekly/Monthly",
          col_3: "Daily/Weekly/Monthly"
        },
        {
          id: uuid(),
          diskSpace: "Free SSL Certificate",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "30 days money back",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        },
        {
          id: uuid(),
          diskSpace: "24/7 Technical Support",
          tooltip: "",
          col_1: "YES",
          col_2: "YES",
          col_3: "YES"
        }
      ]
    }
  ];

  // VPS Content
  const VPSHostingPackage = [
    {
      id: uuid(),
      heading: "VPS-",
      packageName: "Linux Managed 1",
      price: "TK 500",
      subHeading: "",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "done",
          name: "Memory: 512 MB ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Processor: 1 Core ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "SSD Disk Space: 20 GB",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Transfer: 1 TB*",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Static IP address: YES ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "24/7 Technical Support",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS-",
      packageName: "Linux Managed 2",
      price: "TK 600",
      subHeading: "",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "done",
          name: " Memory: 1 GB ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Processor: 1 Core ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "SSD Disk Space: 40 GB",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Transfer: 2 TB* ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Static IP address: YES ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "24/7 Technical Support",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS-",
      packageName: "Linux Managed 3",
      price: "TK 700",
      subHeading: "",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "done",
          name: "Memory: 2 GB ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Processor: 1 Core ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "SSD Disk Space: 60 GB",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Transfer: 3 TB* ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Static IP address: YES ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "24/7 Technical Support",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS-",
      packageName: "Linux Managed 4",
      price: "TK 500",
      subHeading: "",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "done",
          name: "Memory: 4 GB ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Processor: 2 Core ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "SSD Disk Space: 80 GB",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Transfer: 4 TB* ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Static IP address: YES ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: " 24/7 Technical Support",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS-",
      packageName: "Linux Managed 5",
      price: "TK 500",
      subHeading: "",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "done",
          name: "Memory: 8 GB ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: " Processor: 2 Core ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "SSD Disk Space: 160 GB",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Transfer: 5 TB* ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Static IP address: YES ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "24/7 Technical Support",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS-",
      packageName: "Linux Managed 6",
      price: "TK 500",
      subHeading: "",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "done",
          name: "Memory: 16 GB ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Processor: 4 Core ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "SSD Disk Space: 320 GB",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Transfer: 6 TB* ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Static IP address: YES ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "24/7 Technical Support",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS-",
      packageName: "Linux Managed 7",
      price: "TK 500",
      subHeading: "",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "done",
          name: "Memory: 32 GB ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Processor: 8 Core ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "SSD Disk Space: 640 GB",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Transfer: 7 TB* ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: "Static IP address: YES ",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "done",
          name: " 24/7 Technical Support",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    }
  ];
  // VPS plan feature
  const VPSPlanFeatures = {
    vps_1: {
      cpu: "1 vCore",
      ram: "2 GB",
      hard_disk: "20 GB SSD",
      bandwidth: "100 Mbps - 1 TB",
      ip_ram: "01",
      price_regular: {
        BDT: "340",
        USD: "3.99"
      },
      price_discounted: "",
      order_link: "https://my.levohost.com/store/vps-hosting-linux/vps-1"
    },
    vps_2: {
      cpu: "1 vCore",
      ram: "2 GB",
      hard_disk: "20 GB SSD NVMe",
      bandwidth: "250 Mbps - 1 TB",
      ip_ram: "01",
      price_regular: {
        BDT: "585",
        USD: "6.88"
      },
      price_discounted: "",
      order_link: "https://my.levohost.com/store/vps-hosting-linux/vps-2"
    },
    vps_3: {
      cpu: "2 vCore",
      ram: "4  GB",
      hard_disk: "80 GB SSD NVMe",
      bandwidth: "500 Mbps - 2 TB",
      ip_ram: "01",
      price_regular: {
        BDT: "1155",
        USD: "13.5"
      },
      price_discounted: "",
      order_link: "https://my.levohost.com/store/vps-hosting-linux/vps-3"
    },
    vps_4: {
      cpu: "4 vCore",
      ram: "8 GB",
      hard_disk: "160 GB SSD NVMe",
      bandwidth: "1 Gbps - 3 TB",
      ip_ram: "01",
      price_regular: {
        BDT: "2245",
        USD: "26.4"
      },
      price_discounted: "",
      order_link: "https://my.levohost.com/store/vps-hosting-linux/vps-4"
    },
    vps_5: {
      cpu: "8 vCore",
      ram: "From 8 GB - 32 GB",
      hard_disk: "From 160 GB - 640 GB SSD NVMe",
      bandwidth: "2 Gbps - 4 TB",
      ip_ram: "01",
      price_regular: {
        BDT: "3320",
        USD: "38.99"
      },
      price_discounted: "",
      order_link: "https://my.levohost.com/store/vps-hosting-linux/vps-5"
    }
  }
  // Dedicated Content
  const DedicatedHostingPackage = [
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Dedicated</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    }
  ];

  // DEDICATED SERVER HOSTING PLAN
  const DedicatedServerHostingPlan = [
    {
      id: uuid(),
      processor: "Intel Xeon E3-1230v6",
      price: {
        BDT: "5,890",
        USD: "69.29"
      },
      getStartedLink: "https://my.levohost.com/store/dedicated-servers/intel-xeon-e3-1230v6",
      cpu: "4c/8t - 3.5GHz/3.9GHz",
      ram: "32GB DDR4 ECC 2133MHz",
      storage: "2x HDD SATA 2TB",
      bandWithAndTraffic: "500Mbit/s - 10TB",
      ipAddress: "1"
    },
    {
      id: uuid(),
      processor: "Intel Xeon-D 2141I",
      price: {
        BDT: "8,040",
        USD: "94.58"
      },
      getStartedLink: "https://my.levohost.com/store/dedicated-servers/intel-xeon-d-2141i",
      cpu: "8c/16t - 2.2GHz/3GHz",
      ram: "32GB DDR4 ECC 2133MHz",
      storage: "2x SSD NVMe 512GB",
      bandWithAndTraffic: "500Mbit/s - Unmetered",
      ipAddress: "1"
    },
    {
      id: uuid(),
      processor: "AMD Ryzen 7 3800X",
      price: {
        BDT: "11,406",
        USD: "134.18"
      },
      getStartedLink: "https://my.levohost.com/store/dedicated-servers/amd-ryzen-7-3800x",
      cpu: "8c/16t - 3.9GHz/4.5GHz",
      ram: "64GB DDR4 ECC 2666MHz",
      storage: "2x SSD NVMe 960GB",
      bandWithAndTraffic: "500Mbit/s - Unmetered",
      ipAddress: "1"
    },
    {
      id: uuid(),
      processor: "AMD Epyc 7371",
      price: {
        BDT: "20,569",
        USD: "241.98"
      },
      getStartedLink: "https://my.levohost.com/store/dedicated-servers/amd-epyc-7371",
      cpu: "16c/32t - 3.1GHz/3.8GHz",
      ram: "128GB DDR4 ECC 2400MHz",
      storage: "2x SSD NVMe 960GB",
      bandWithAndTraffic: "500Mbit/s - Unmetered",
      ipAddress: "1"
    },
    {
      id: uuid(),
      processor: "AMD Epyc 7313",
      price: {
        BDT: "21,130",
        USD: "248.58"
      },
      getStartedLink: "https://my.levohost.com/store/dedicated-servers/amd-epyc-7313",
      cpu: "16c/32t - 3GHz/3.7GHz",
      ram: "64GB DDR4 ECC 3200MHz",
      storage: "2x SSD NVMe 960GB",
      bandWithAndTraffic: "1Gbit/s - Unmetered",
      ipAddress: "1"
    },
    {
      id: uuid(),
      processor: "AMD Epyc 7413",
      price: {
        BDT: "29,078",
        USD: "342.09"
      },
      getStartedLink: "https://my.levohost.com/store/dedicated-servers/amd-epyc-7413",
      cpu: "24c/48t - 2.65GHz/3.6GHz",
      ram: "128GB DDR4 ECC 3200MHz",
      storage: "2x SSD NVMe 960GB",
      bandWithAndTraffic: "1Gbit/s - Unmetered",
      ipAddress: "1"
    }
  ]
  // SSL Content
  const SSLHostingPackage = [
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>SfdfvdfvdSL</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    },
    {
      id: uuid(),
      heading: "VPS",
      packageName: "SSD Starter",
      price: "12.99",
      subHeading:
        "<strong>Small but powerful VPS</strong> with the same SSD based storage as our more advanced server plans.",
      btnName: "Order Now",
      btnURL: "https://www.facebook.com/levohost",
      lists: [
        {
          ids: uuid(),
          icon: "memory",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "dns",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "save",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "arrow_forward",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        },
        {
          ids: uuid(),
          icon: "verified_user",
          name: "2 vCPU Cores",
          adText: "Fast Intel Xeon based virtual cores"
        }
      ]
    }
  ];
  return (
    <HostingContext.Provider
      value={{
        SharedPackageHead,
        BusinessPackageHead,
        ResellerPackageHead,
        sharedHostingPackage,
        BusinessHostingPackage,
        ResellerHostingPackage,
        VPSPlanFeatures,
        VPSHostingPackage,
        DedicatedHostingPackage,
        DedicatedServerHostingPlan,
        SSLHostingPackage
      }}
    >
      {props.children}
    </HostingContext.Provider>
  );
};

export default HostingContextProvider;
