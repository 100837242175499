import React from 'react';
import {Col, Container, Image, Row, Button} from "react-bootstrap";
import hostingFet_litespeed from "../../../assets/images/litespeed.jpg";
import hostingFet2 from "../../../assets/images/ssd.jpg";
import hostingFet_backup from "../../../assets/images/uptime2.jpg";
import hostingFet_uptime from "../../../assets/images/backup.jpg";
import hostingFet_support from "../../../assets/images/support2.jpg";
import SectionHeaderTwo from "../SectionHeaderTwo/SectionHeaderTwo";

const HostingFeaturesNoHead = () => {
    return (
        <div className="hosting-features-style-2">
            <Container>

                {/*Section Headign*/}
                <SectionHeaderTwo
                    heading="Hosting features"
                    desc=" Our Shared Web hosting features"

                />
                <span className="blank-space"></span>
                {/*Content*/}
                
                <Row className="justify-content-between align-items-center">
                    <Col md={{span:5, order:2}}>
                        <div className="hosting-features-img">
                            <Image src={hostingFet_litespeed} />
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="hosting-feature">
                            <h3 className="font-42 text-blue">LiteSpeed Web Server</h3>
                            <p>LiteSpeed web server is the #1 commercial web server and  provides better performance than any other web hosting server, like Apache or Nginx. All of our Web Hosting servers are configured with Litespeed web server for better Speed. Enjoy faster page loads up to 50 times faster than Apache.</p>
                            <Button variant="link" className="p-0 text-decoration-underline text-yellow hover-letter">Learn more</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between align-items-center">
                    <Col md={{span:5}}>
                        <div className="hosting-features-img">
                            <Image src={hostingFet2} />
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="hosting-feature">
                            <h3 className="font-42 text-blue">Pure SSD Disk Space</h3>
                            <p>Solid State Drive ( SSD ) gives you super-fast read/write speed also can manage more disk requests then Standard HDD.

 We use SSD for everything (Files, OS &amp; Databases) so you can get the biggest speed boost to your website.</p>
                            <Button variant="link" className="p-0 text-decoration-underline text-yellow hover-letter">Learn more</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between align-items-center">
                    <Col md={{span:5, order:2}}>
                        <div className="hosting-features-img">
                            <Image src={hostingFet_uptime} />
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="hosting-feature">
                            <h3 className="font-42 text-blue"> Hassle-Free Website Migration </h3>
                            <p> If you have hosted your website with another hosting company and want to migrate it to LevoHost, you can easily do it in a hassle-free manner. All you need to do is choose a hosting plan and then submit the migration request form. LevoHost Support Team will then schedule the migration and complete it for you free of cost.</p>
                            <Button variant="link" className="p-0 text-decoration-underline text-yellow hover-letter">Learn more</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between align-items-center">
                    <Col md={{span:5}}>
                        <div className="hosting-features-img">
                            <Image src={hostingFet_backup} />
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="hosting-feature">
                            <h3 className="font-42 text-blue"> 99.99% Uptime Guarantee</h3>
                            <p>We understand the uptime importance. We’re so confident in our tech, that our 99.99% Uptime Guarantee will put your $$ back in your pocket if your site goes down.</p>
                            <Button variant="link" className="p-0 text-decoration-underline text-yellow hover-letter">Learn more</Button>
                        </div>
                    </Col>
                </Row>


                <Row className="justify-content-between align-items-center">
                    <Col md={{span:5, order:2}}>
                        <div className="hosting-features-img">
                            <Image src={hostingFet_support} />
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="hosting-feature">
                            <h3 className="font-42 text-blue">24/7/365 Live Customer Support</h3>
                            <p>We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services. Whether you need advanced PHP Hosting support or have a question about how to get your blog set up, the Levohost support team is always here.</p>
                            <Button variant="link" className="p-0 text-decoration-underline text-yellow hover-letter">Learn more</Button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default HostingFeaturesNoHead;
