import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Col, Container, Row, ButtonGroup, Button } from "react-bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading";
import { HostingPriceContext } from "../../Contexts/HostingPriceContext";
import { currencies } from "../../constants/currencies";

// PLAN SCHEMA
const schema = {
  perMonth: 'perMonth',
  perYear: 'perYear',
  perTwoYears: 'perTwoYears'
}

const SharedHostingPlans = () => {
  const { currency } = useSelector(state => state.currency);
  // PRICE CHART 
  const { SharedHostingPriceChart: PRICE_CHART } = useContext(HostingPriceContext);

  // HOSTING PLAN
  const [plan, setPlan] = useState(schema.perYear);
  const [price, setPrice] = useState({
    starter: {
      BDT: {
        regular: PRICE_CHART.starter.regular.BDT.perYear,
        discounted: PRICE_CHART.starter.discounted.BDT.perYear,
        discount: PRICE_CHART.starter.discountRate.BDT.perYear
      },
      USD: {
        regular: PRICE_CHART.starter.regular.USD.perYear,
        discounted: PRICE_CHART.starter.discounted.USD.perYear,
        discount: PRICE_CHART.starter.discountRate.USD.perYear
      }
    },
    standard: {
      BDT: {
        regular: PRICE_CHART.standard.regular.BDT.perYear,
        discounted: PRICE_CHART.standard.discounted.BDT.perYear,
        discount: PRICE_CHART.standard.discountRate.BDT.perYear
      },
      USD: {
        regular: PRICE_CHART.standard.regular.USD.perYear,
        discounted: PRICE_CHART.standard.discounted.USD.perYear,
        discount: PRICE_CHART.standard.discountRate.USD.perYear
      }
    },
    advanced: {
      BDT: {
        regular: PRICE_CHART.advanced.regular.BDT.perYear,
        discounted: PRICE_CHART.advanced.discounted.BDT.perYear,
        discount: PRICE_CHART.advanced.discountRate.BDT.perYear
      },
      USD: {
        regular: PRICE_CHART.advanced.regular.USD.perYear,
        discounted: PRICE_CHART.advanced.discounted.USD.perYear,
        discount: PRICE_CHART.advanced.discountRate.USD.perYear
      }
    }
  })
  useEffect(() => {
    // PLAN PRICE DETECTOR
    if (plan === schema.perMonth) {
      setPrice({
        starter: {
          BDT: {
            regular: PRICE_CHART.starter.regular.BDT.perMonth,
            discounted: PRICE_CHART.starter.discounted.BDT.perMonth,
            discount: PRICE_CHART.starter.discountRate.BDT.perMonth
          },
          USD: {
            regular: PRICE_CHART.starter.regular.USD.perMonth,
            discounted: PRICE_CHART.starter.discounted.USD.perMonth,
            discount: PRICE_CHART.starter.discountRate.USD.perMonth
          }
        },
        standard: {
          BDT: {
            regular: PRICE_CHART.standard.regular.BDT.perMonth,
            discounted: PRICE_CHART.standard.discounted.BDT.perMonth,
            discount: PRICE_CHART.standard.discountRate.BDT.perMonth
          },
          USD: {
            regular: PRICE_CHART.standard.regular.USD.perMonth,
            discounted: PRICE_CHART.standard.discounted.USD.perMonth,
            discount: PRICE_CHART.standard.discountRate.USD.perMonth
          }
        },
        advanced: {
          BDT: {
            regular: PRICE_CHART.advanced.regular.BDT.perMonth,
            discounted: PRICE_CHART.advanced.discounted.BDT.perMonth,
            discount: PRICE_CHART.advanced.discountRate.BDT.perMonth
          },
          USD: {
            regular: PRICE_CHART.advanced.regular.USD.perMonth,
            discounted: PRICE_CHART.advanced.discounted.USD.perMonth,
            discount: PRICE_CHART.advanced.discountRate.USD.perMonth
          }
        }
      });
    } else if (plan === schema.perYear) {
      setPrice({
        starter: {
          BDT: {
            regular: PRICE_CHART.starter.regular.BDT.perYear,
            discounted: PRICE_CHART.starter.discounted.BDT.perYear,
            discount: PRICE_CHART.starter.discountRate.BDT.perYear
          },
          USD: {
            regular: PRICE_CHART.starter.regular.USD.perYear,
            discounted: PRICE_CHART.starter.discounted.USD.perYear,
            discount: PRICE_CHART.starter.discountRate.USD.perYear
          }
        },
        standard: {
          BDT: {
            regular: PRICE_CHART.standard.regular.BDT.perYear,
            discounted: PRICE_CHART.standard.discounted.BDT.perYear,
            discount: PRICE_CHART.standard.discountRate.BDT.perYear
          },
          USD: {
            regular: PRICE_CHART.standard.regular.USD.perYear,
            discounted: PRICE_CHART.standard.discounted.USD.perYear,
            discount: PRICE_CHART.standard.discountRate.USD.perYear
          }
        },
        advanced: {
          BDT: {
            regular: PRICE_CHART.advanced.regular.BDT.perYear,
            discounted: PRICE_CHART.advanced.discounted.BDT.perYear,
            discount: PRICE_CHART.advanced.discountRate.BDT.perYear
          },
          USD: {
            regular: PRICE_CHART.advanced.regular.USD.perYear,
            discounted: PRICE_CHART.advanced.discounted.USD.perYear,
            discount: PRICE_CHART.advanced.discountRate.USD.perYear
          }
        }
      });
    } else if (plan === schema.perTwoYears) {
      setPrice({
        starter: {
          BDT: {
            regular: PRICE_CHART.starter.regular.BDT.perTwoYears,
            discounted: PRICE_CHART.starter.discounted.BDT.perTwoYears,
            discount: PRICE_CHART.starter.discountRate.BDT.perTwoYears
          },
          USD: {
            regular: PRICE_CHART.starter.regular.USD.perTwoYears,
            discounted: PRICE_CHART.starter.discounted.USD.perTwoYears,
            discount: PRICE_CHART.starter.discountRate.USD.perTwoYears
          }
        },
        standard: {
          BDT: {
            regular: PRICE_CHART.standard.regular.BDT.perTwoYears,
            discounted: PRICE_CHART.standard.discounted.BDT.perTwoYears,
            discount: PRICE_CHART.standard.discountRate.BDT.perTwoYears
          },
          USD: {
            regular: PRICE_CHART.standard.regular.USD.perTwoYears,
            discounted: PRICE_CHART.standard.discounted.USD.perTwoYears,
            discount: PRICE_CHART.standard.discountRate.USD.perTwoYears
          }
        },
        advanced: {
          BDT: {
            regular: PRICE_CHART.advanced.regular.BDT.perTwoYears,
            discounted: PRICE_CHART.advanced.discounted.BDT.perTwoYears,
            discount: PRICE_CHART.advanced.discountRate.BDT.perTwoYears
          },
          USD: {
            regular: PRICE_CHART.advanced.regular.USD.perTwoYears,
            discounted: PRICE_CHART.advanced.discounted.USD.perTwoYears,
            discount: PRICE_CHART.advanced.discountRate.USD.perTwoYears
          }
        }
      });
    }
  }, [plan])

  const handleClick = (e) => {
    setPlan(e.target.value);
  };

  return (
    <div className="hosting-plans" id="hostingPlans">
      <Container>
        <SectionHeading
          heading="Our Hosting Plans"
          desc="We sorted our web hosting packages are more user-friendly and well configured. Just an easy-as-pie way to get a great website up and running"
        />
        <Row>
          <Col md={12} className="text-center my-4 hosting-dur-btn">
            <ButtonGroup onClick={handleClick}>
              <Button value={schema.perMonth} variant={`outline-secondary ${plan === schema.perMonth ? 'active' : ''}`}>
                Monthly
              </Button>
              <Button value={schema.perYear} variant={`outline-secondary ${plan === schema.perYear ? 'active' : ''}`}>
                1 Year
              </Button>
              <Button value={schema.perTwoYears} variant={`outline-secondary ${plan === schema.perTwoYears ? 'active' : ''}`}>
                2 Year
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <div className="hosting-plan-slider shared-hosting-plan-slider hosting-plan-slider-top text-center">
          <Container>
            <Row className="g-3">
              <Col md={6} lg={4} className="outer_hosting_plan_card">
                <div className="hosting_plan_card bg_img01">
                  <div className="heading_hosting_plan">
                    <h3>Starter</h3>
                    <p>
                      <strong className="green_clr">STARTER </strong> shared hosting
                      package is an easy-as-pie way to get a great website up
                      and running.
                    </p>
                  </div>
                  <ul className="hosting_plan_list">
                    <li>SSD Disk space: 2 GB</li>
                    <li>Monthly Bandwidth: 100 GB</li>
                    <li>Cores Per cPanel User: 1 core</li>
                    <li>Physical Memory Per cPanel User: 1 GB</li>
                    <li>Entry Processes : 20</li>
                    <li>Addon Domain Allowed: 02</li>
                    <li>LiteSpeed with LSCache</li>
                    <li>Cloudlinux OS</li>
                    <li>Imunify360 Firewall</li>
                  </ul>
                </div>
                <div className="hosting_plan_price_box bg_f5f5f5">
                  <div className="price_box">
                    <span>{currency === currencies.BDT ? "TK" : "$"}</span>
                    {currency === currencies.BDT ? price.starter.BDT.discounted : price.starter.USD.discounted}
                    <small>
                      {plan === schema.perMonth ?
                        "/per month"
                        : plan === schema.perYear ?
                          "/per year"
                          : plan === schema.perTwoYears &&
                          "/per 2 year"
                      }
                    </small>
                  </div>
                  <small className="off-price">
                    <span>Regular: <del>{currency === currencies.BDT ? "TK" : "$"} {currency === currencies.BDT ? price.starter.BDT.regular : price.starter.USD.regular}/{plan === schema.perMonth ?
                      "per month"
                      : plan === schema.perYear ?
                        "per year"
                        : plan === schema.perTwoYears &&
                        "per 2 year"
                    }</del></span><span className="dis_bg"> {currency === currencies.BDT ? price.starter.BDT.discount : price.starter.USD.discount}% OFF </span>
                  </small>
                  <a href="https://my.levohost.com/store/shared-web-hosting/starter" className="hosting_plan_card_btn">Order Now</a>
                </div>
              </Col>
              <Col md={6} lg={4} className="outer_hosting_plan_card">
                <div className="hosting_plan_card bg_img02">
                  <div className="recommended">Recommended</div>
                  <div className="heading_hosting_plan">
                    <h3>Standard</h3>
                    <p>
                      <strong className="green_clr">STANDARD </strong> hosting
                      package is ideal for small business owners or who want
                      to host multiple websites.
                    </p>
                  </div>
                  <ul className="hosting_plan_list">
                    <li>SSD Disk space: 5 GB</li>
                    <li>Monthly Bandwidth: 250 GB</li>
                    <li>Cores Per cPanel User: 1 core</li>
                    <li>Physical Memory Per cPanel User: 1.5 GB</li>
                    <li>Entry Processes : 30</li>
                    <li>Addon Domain Allowed: 05</li>
                    <li>LiteSpeed with LSCache</li>
                    <li>Cloudlinux OS</li>
                    <li>Imunify360 Firewall</li>
                  </ul>
                </div>
                <div className="hosting_plan_price_box bg_f5f5f5">
                  <div className="price_box">
                    <span>{currency === currencies.BDT ? "TK" : "$"}</span>
                    {currency === currencies.BDT ? price.standard.BDT.discounted : price.standard.USD.discounted}
                    <small>
                      {plan === schema.perMonth ?
                        "/per month"
                        : plan === schema.perYear ?
                          "/per year"
                          : plan === schema.perTwoYears &&
                          "/per 2 year"
                      }
                    </small>
                  </div>
                  <small className="off-price">
                    <span>Regular: <del>{currency === currencies.BDT ? "TK" : "$"} {currency === currencies.BDT ? price.standard.BDT.regular : price.standard.USD.regular}/{plan === schema.perMonth ?
                      "per month"
                      : plan === schema.perYear ?
                        "per year"
                        : plan === schema.perTwoYears &&
                        "per 2 year"
                    }</del></span><span className="dis_bg"> {currency === currencies.BDT ? price.standard.BDT.discount : price.standard.USD.discount}% OFF </span>
                  </small>
                  <a href="https://my.levohost.com/store/shared-web-hosting/standard" className="hosting_plan_card_btn">Order Now</a>
                </div>
              </Col>
              <Col md={6} lg={4} className="outer_hosting_plan_card">
                <div className="hosting_plan_card bg_img03">
                  <div className="heading_hosting_plan">
                    <h3>Advanced</h3>
                    <p>
                      <strong className="green_clr">ADVANCED </strong> hosting
                      package is great for moderate traffic and who is looking
                      more power and enhanced performance.
                    </p>
                  </div>
                  <ul className="hosting_plan_list">
                    <li>SSD Disk space: 10 GB</li>
                    <li>Monthly Bandwidth: 500 GB</li>
                    <li>Cores Per cPanel User: 1 core</li>
                    <li>Physical Memory Per cPanel User: 2 GB</li>
                    <li>Entry Processes : 40</li>
                    <li>Addon Domain Allowed: 10</li>
                    <li>LiteSpeed with LSCache</li>
                    <li>Cloudlinux OS</li>
                    <li>Imunify360 Firewall</li>
                  </ul>
                </div>
                <div className="hosting_plan_price_box bg_f5f5f5">
                  <div className="price_box">
                    <span>{currency === currencies.BDT ? "TK" : "$"}</span>
                    {currency === currencies.BDT ? price.advanced.BDT.discounted : price.advanced.USD.discounted}
                    <small>
                      {plan === schema.perMonth ?
                        "/per month"
                        : plan === schema.perYear ?
                          "/per year"
                          : plan === schema.perTwoYears &&
                          "/per 2 year"
                      }
                    </small>
                  </div>
                  <small className="off-price">
                    <span>Regular: <del>{currency === currencies.BDT ? "TK" : "$"} {currency === currencies.BDT ? price.advanced.BDT.regular : price.advanced.USD.regular}/{plan === schema.perMonth ?
                      "per month"
                      : plan === schema.perYear ?
                        "per year"
                        : plan === schema.perTwoYears &&
                        "per 2 year"
                    }</del></span><span className="dis_bg"> {currency === currencies.BDT ? price.advanced.BDT.discount : price.advanced.USD.discount}% OFF </span>
                  </small>
                  <a href="https://my.levohost.com/store/shared-web-hosting/advanced" className="hosting_plan_card_btn">Order Now</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default SharedHostingPlans;
