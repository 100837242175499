import React from 'react';
import {Container, Row,Col} from "react-bootstrap";
import DomainSearchForm from "../DomainSearchForm/DomainSearchForm";

const SearchDomain = (props) => {
    return (
        <div className="search-domain-section">
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="search-domain-box">
                            <h3>Find Your Perfect <span>  Domain Name</span></h3>
                            <p> Huge Selection. Low Costs. New Extensions. Register your perfect domain name today. </p>
                            <div className="domain-search-sm">
                                <DomainSearchForm/>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="search-text">
                            <h3 className="mb-4">{props.heading}</h3>
                            <p>{props.desc}</p>
                          
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SearchDomain;
