import React from 'react';
import {Container, Row, Col, Image, Accordion} from "react-bootstrap";
import imgLevel from '../../assets/images/18.svg';
import AccordionItem from "../AccordionItem/AccordionItem";
import SectionHeadingBig from "../SharedComponent/SectionHeadingBig/SectionHeadingBig";

const VpsLevelControl = () => {
    return (
        <div className="vps-level-hosting">
             <Container>
                 <SectionHeadingBig heading="Most uses for VPS-level Control" />
                 <Row className="mt-5">
                     <Col md={6}>
                         <div className="image">
                             <Image src={imgLevel}  />
                         </div>
                     </Col>
                     <Col md={6}>
                         <div className="content">
                             <Accordion className="accordion-left-arrow" defaultActiveKey="0">
                                 <AccordionItem
                                     faqNo="0"
                                     faqQuestion="Web Hosting"
                                     faqAnswer="The most common way of using a VPS is by turning it into a hosting server. There are lots of advantages to using a Virtual private server for website hosting. Although you can technically use shared hosting to run most low-traffic volume websites, VPS is way better for business purpose use. The dedicated resources and isolated environment gain the security and reliability of all the websites hosted on the same account."
                                 />
                                 <AccordionItem
                                     faqNo="1"
                                     faqQuestion="Private Backups"
                                     faqAnswer=" If you have lots of data or files that need to be backed up, then buying a VPS would be a great decision for you. "
                                 />
                                 <AccordionItem
                                     faqNo="2"
                                     faqQuestion="Hosting a Server"
                                     faqAnswer="Hosting servers through Virtual Private Server is another increasingly common use"
                                 />
                                 <AccordionItem
                                     faqNo="3"
                                     faqQuestion="Testing New Environments"
                                     faqAnswer="You can use Virtual Private Server for learning/ practice/ testing purposes (as they are cheap) to learn Linux or Windows, or any other stuff!s."
                                 />
                                 <AccordionItem
                                     faqNo="4"
                                     faqQuestion="Hosting A Gaming Server"
                                     faqAnswer="Virtual Private Server is one of the best ways to host a private server for gaming sessions.
                                     "
                                 />
                             </Accordion>
                         </div>
                     </Col>
                 </Row>
             </Container>
        </div>
    );
};

export default VpsLevelControl;
