import React, { useContext } from "react";
import { Accordion, Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import AccordionItem from "../../AccordionItem/AccordionItem";
import { FaqContext } from "../../../Contexts/FaqContext";
import SectionHeading from "../../SectionHeading/SectionHeading";

const FaqCheapHosting = () => {
    const { faqCheapHosting } = useContext(FaqContext);
    return (
        <div className="faq-wrap">
            <Container>
                <SectionHeading
                    heading="Frequently Asked Questions (FAQ)"
                    desc="Below, you will find the most common questions that we get asked, we answered most of them here. Alternatively, connect with us through live chat, phone call or by support tickets."
                />
            </Container>
            <Container>
                <div className="faq-inner">
                    <Accordion className="accordion-faq" defaultActiveKey="0">
                        {faqCheapHosting.map((faq) => {
                            return (
                                <AccordionItem
                                    key={faq.id}
                                    faqNo={faq.id}
                                    faqQuestion={faq.question}
                                    faqAnswer={ReactHtmlParser(faq.answer)}
                                />
                            );
                        })}
                    </Accordion>
                </div>
            </Container>
            <p className="faq-hint">

            </p>
        </div>
    );
};

export default FaqCheapHosting;
