/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import Slider from "react-rangeslider";
// To include the default styles
import "react-rangeslider/lib/index.css";
import vps_icon1 from "../../../assets/images/vps_icon1.png";
import vps_icon2 from "../../../assets/images/vps_icon2.png";
import vps_icon3 from "../../../assets/images/vps_icon3.png";
import vps_icon4 from "../../../assets/images/vps_icon4.png";
import vps_icon5 from "../../../assets/images/vps_icon5.png";
import vps_feature_img01 from "../../../assets/images/vps_feature_img01.png";
import vps_feature_img02 from "../../../assets/images/vps_feature_img02.png";
import vps_feature_img03 from "../../../assets/images/vps_feature_img03.png";
import vps_feature_img04 from "../../../assets/images/vps_feature_img04.png";
import vps_feature_img05 from "../../../assets/images/vps_feature_img05.png";
import vps_feature_img06 from "../../../assets/images/vps_feature_img06.png";
import SectionHeading from "../../SectionHeading/SectionHeading";
import { HostingContext } from "../../../Contexts/HostingContext";
import { useSelector } from "react-redux";
import { currencies } from "../../../constants/currencies";

const VpsPlanFeature = () => {
  const { currency } = useSelector(state => state.currency);
  const { VPSPlanFeatures: VPS_feature } = useContext(HostingContext);
  const featuresInitialState = {
    cpu: VPS_feature.vps_1.cpu,
    ram: VPS_feature.vps_1.ram,
    hard_disk: VPS_feature.vps_1.hard_disk,
    bandwidth: VPS_feature.vps_1.bandwidth,
    ip_ram: VPS_feature.vps_1.ip_ram,
    price_regular: {
      BDT: VPS_feature.vps_1.price_regular.BDT,
      USD: VPS_feature.vps_1.price_regular.USD
    },
    price_discounted: VPS_feature.vps_1.price_discounted,
    order_link: VPS_feature.vps_1.order_link
  }
  const [vertical, setVertical] = useState(20);
  const [features, setFeatures] = useState(featuresInitialState);

  const handleChangeVertical = (value) => {
    setVertical(value);
  };
  const handleChangeVerticalComplete = () => {
    if (vertical >= 90 && vertical < 100) {
      setVertical(100);
    } else if (vertical > 80 && vertical < 90) {
      setVertical(80);
    } else if (vertical >= 70 && vertical < 80) {
      setVertical(80);
    } else if (vertical > 60 && vertical < 70) {
      setVertical(60);
    } else if (vertical >= 50 && vertical < 60) {
      setVertical(60);
    } else if (vertical > 40 && vertical < 50) {
      setVertical(40);
    } else if (vertical >= 30 && vertical < 40) {
      setVertical(40);
    } else if (vertical < 30) {
      setVertical(20);
    }
  }

  useEffect(() => {
    switch (vertical) {
      case 20:
        setFeatures(featuresInitialState);
        break;
      case 40:
        setFeatures({
          cpu: VPS_feature.vps_2.cpu,
          ram: VPS_feature.vps_2.ram,
          hard_disk: VPS_feature.vps_2.hard_disk,
          bandwidth: VPS_feature.vps_2.bandwidth,
          ip_ram: VPS_feature.vps_2.ip_ram,
          price_regular: {
            BDT: VPS_feature.vps_2.price_regular.BDT,
            USD: VPS_feature.vps_2.price_regular.USD
          },
          price_discounted: VPS_feature.vps_2.price_discounted,
          order_link: VPS_feature.vps_2.order_link
        });
        break;
      case 60:
        setFeatures({
          cpu: VPS_feature.vps_3.cpu,
          ram: VPS_feature.vps_3.ram,
          hard_disk: VPS_feature.vps_3.hard_disk,
          bandwidth: VPS_feature.vps_3.bandwidth,
          ip_ram: VPS_feature.vps_3.ip_ram,
          price_regular: {
            BDT: VPS_feature.vps_3.price_regular.BDT,
            USD: VPS_feature.vps_3.price_regular.USD
          },
          price_discounted: VPS_feature.vps_3.price_discounted,
          order_link: VPS_feature.vps_3.order_link
        });
        break;
      case 80:
        setFeatures({
          cpu: VPS_feature.vps_4.cpu,
          ram: VPS_feature.vps_4.ram,
          hard_disk: VPS_feature.vps_4.hard_disk,
          bandwidth: VPS_feature.vps_4.bandwidth,
          ip_ram: VPS_feature.vps_4.ip_ram,
          price_regular: {
            BDT: VPS_feature.vps_4.price_regular.BDT,
            USD: VPS_feature.vps_4.price_regular.USD
          },
          price_discounted: VPS_feature.vps_4.price_discounted,
          order_link: VPS_feature.vps_4.order_link
        });
        break;
      case 100:
        setFeatures({
          cpu: VPS_feature.vps_5.cpu,
          ram: VPS_feature.vps_5.ram,
          hard_disk: VPS_feature.vps_5.hard_disk,
          bandwidth: VPS_feature.vps_5.bandwidth,
          ip_ram: VPS_feature.vps_5.ip_ram,
          price_regular: {
            BDT: VPS_feature.vps_5.price_regular.BDT,
            USD: VPS_feature.vps_5.price_regular.USD
          },
          price_discounted: VPS_feature.vps_5.price_discounted,
          order_link: VPS_feature.vps_5.order_link
        });
        break;
      default:
        break;
    }
  }, [vertical])

  const verticalLabels = {
    20: "VPS 1",
    40: "VPS 2",
    60: "VPS 3",
    80: "VPS 4",
    100: "VPS 5"
  };

  return (
    <div className="vps-plans-feature">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="vps-plan-inner">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4 col-4 col-sm-5">
                  <div className="price-slider-section">
                    <Slider
                      value={vertical}
                      orientation="vertical"
                      labels={verticalLabels}
                      onChange={handleChangeVertical}
                      onChangeComplete={handleChangeVerticalComplete}
                    />
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-8 col-8 col-sm-7">
                  <div className="row">
                    <div className="col-md-6 col-md-4">
                      <div className="vps-box">
                        <div className="vps-item-name">
                          <Image src={vps_icon1} />
                          <h3>CPU</h3>
                        </div>
                        <div className="vps-item-value">
                          <h3>{features.cpu}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-4">
                      <div className="vps-box">
                        <div className="vps-item-name">
                          <Image src={vps_icon2} />
                          <h3>RAM</h3>
                        </div>
                        <div className="vps-item-value">
                          <h3>{features.ram}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-4">
                      <div className="vps-box">
                        <div className="vps-item-name">
                          <Image src={vps_icon3} />
                          <h3> Disk Space</h3>
                        </div>
                        <div className="vps-item-value">
                          <h3>{features.hard_disk}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-4">
                      <div className="vps-box">
                        <div className="vps-item-name">
                          <Image src={vps_icon4} />
                          <h3>BandWidth &amp; Traffic</h3>
                        </div>
                        <div className="vps-item-value">
                          <h3>{features.bandwidth}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-4">
                      <div className="vps-box">
                        <div className="vps-item-name">
                          <Image src={vps_icon5} />
                          <h3>IP</h3>
                        </div>
                        <div className="vps-item-value">
                          <h3>{features.ip_ram}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-4">
                      <div className="vps-box vps-box-blue">
                        {/* <p>{features.price_discounted}</p> */}
                        <h3>{currency === currencies.BDT ? "TK " + features.price_regular.BDT : "$ " + features.price_regular.USD}/mo</h3>
                        <a href={features.order_link}>
                          <Button> Order Now </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="hosting_feature_section">
        <div class="container">
          <SectionHeading
            heading="VPS Features"
            desc="Take full control of your servers and manage them as you see fit"
          />
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <div class="vps_hosting_feature_box h-100">
                    <div class="vps_feature_img01">
                      <Image src={vps_feature_img01} />
                    </div>
                    <div class="vps_feature_content">
                      Full <br /> Root Access
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <div class="vps_hosting_feature_box h-100">
                    <div class="vps_feature_img01">
                      <Image src={vps_feature_img02} />
                    </div>
                    <div class="vps_feature_content">
                      Increased <br />  Performance
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <div class="vps_hosting_feature_box h-100">
                    <div class="vps_feature_img01">
                      <Image src={vps_feature_img03} />
                    </div>
                    <div class="vps_feature_content">
                      Simplicity

                      <br /> and Independence


                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <div class="vps_hosting_feature_box h-100">
                    <div class="vps_feature_img01">
                      <Image src={vps_feature_img04} />
                    </div>
                    <div class="vps_feature_content">
                      Easy-to-use <br /> Interface
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <div class="vps_hosting_feature_box h-100">
                    <div class="vps_feature_img01">
                      <Image src={vps_feature_img05} />
                    </div>
                    <div class="vps_feature_content">
                      Backup  <br /> Options
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <div class="vps_hosting_feature_box h-100">
                    <div class="vps_feature_img01">
                      <Image src={vps_feature_img06} />
                    </div>
                    <div class="vps_feature_content">
                      Constant

                      <br /> and Stable Service
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default VpsPlanFeature;
