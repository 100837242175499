import { BsFillLightningFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const WebHostingSpecs = (props) => {
    const { infoList, titlePartOne, titlePartTwo, description } = props;

    return (
        <div className="web-hosting-specs">
            <div className="container inner-web-hosting-specs py-5">
                <div className="web-hosting-specs-header col-md-8 mx-auto text-center py-4">
                    <h1>{titlePartOne} <span>{titlePartTwo}</span> </h1>
                    <p>{description}</p>
                </div>
                <div className="row row-cols-1 row-cols-md-3 g-4 mx-auto text-md-start">
                    <div className="col">
                        <ul>
                            {infoList.one.map(item =>
                                <li className="lead" key={item.id}><BsFillLightningFill className="icon" />{item.name}</li>
                            )}
                        </ul>
                    </div>
                    <div className="col border-left">
                        <ul className="ps-0 ps-lg-5">
                            {infoList.two.map(item =>
                                <li className="lead" key={item.id}><BsFillLightningFill className="icon" />{item.name}</li>
                            )}
                        </ul>
                    </div>
                    <div className="col border-left">
                        <ul className="ps-0 ps-lg-5">
                            {infoList.three.map(item =>
                                <li className="lead" key={item.id}><BsFillLightningFill className="icon" />{item.name}</li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="d-flex justify-content-center pt-5">
                    <a href="#viewAllSpecs" className="btn view-all-specs-btn">
                        View All Specs
                    </a>
                </div>
            </div>
        </div>
    );
};

export default WebHostingSpecs;