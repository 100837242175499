import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import OperatingSupport from "../Components/SharedComponent/OperatingSupport/OperatingSupport";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
import SupportBlock from "../Components/SupportBlock/SupportBlock";
import Footer from "../Components/Footer/Footer";
import VpsLevelControl from "../Components/VpsLevelControl/VpsLevelControl";
import GetStarted from "../Components/SharedComponent/GetStarted/GetStarted";
import FaqVPSHosting from "../Components/Faq/FaqVPSHosting/FaqVPSHosting";
import VpsPlanFeature from "../Components/SharedComponent/VpsPlanFeature/VpsPlanFeature";

const VpsHosting = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="VPS Hosting (Linux)"
        desc="High performance at unbeatable prices"
      />
      <VpsPlanFeature />
      <OperatingSupport />
      <VpsLevelControl />
      <GetStarted />
      <FaqVPSHosting />
      <MoreFeatureSlider />
      <SupportBlock />
      <Footer />
    </Fragment>
  );
};

export default VpsHosting;
