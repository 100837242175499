import React, { createContext } from "react";
import { v4 as uuid } from "uuid";

export const AffiliateContext = createContext();
const AffiliateContextProvider = (props) => {
  const Commissions = [
    {
      id: uuid(),
      name: " Shared Web Hosting",
      monthlyC: "15",
      yearlyC: "20"
    },
    {
      id: uuid(),
      name: " Business Hosting",
      monthlyC: "15",
      yearlyC: "20"
    },
    {
      id: uuid(),
      name: " Cheap Shared Hosting",
      monthlyC: "15",
      yearlyC: "20"
    },
    {
      id: uuid(),
      name: " VPS Hosting",
      monthlyC: "15",
      yearlyC: "20"
    },
    {
      id: uuid(),
      name: " Dedicated Server",
      monthlyC: "15",
      yearlyC: "20"
    },
    {
      id: uuid(),
      name: " Domain Registration",
      monthlyC: "--",
      yearlyC: "--"
    },
    {
      id: uuid(),
      name: " SSL Certificate",
      monthlyC: "--",
      yearlyC: "--"
    }
  ];

  return (
    <AffiliateContext.Provider value={{ Commissions }}>
      {props.children}
    </AffiliateContext.Provider>
  );
};

export default AffiliateContextProvider;
