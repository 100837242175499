import React from 'react';
import {Button, Form} from "react-bootstrap";

const DomainSearchForm = () => {
    return (
        <Form className="domain-search-form" action="https://my.levohost.com/cart.php?a=add&domain=register" method='post'>
            <Form.Group>
                <Form.Control className="search_domain_field" type="text" placeholder="Enter Your Domain Name:"  name="query" />
                <Button variant="primary" className="lvh-btn" type="submit" value="Go">
                    Submit
                </Button>
            </Form.Group>
        </Form>
    );
};

export default DomainSearchForm;
