import React from 'react';
import {Container, Row, Col, Image} from "react-bootstrap";
import SectionHeaderTwo from "../SectionHeaderTwo/SectionHeaderTwo";
import ssd from  '../../../assets/images/ssd2.jpg';
import whm from  '../../../assets/images/panal_img.png';
import cpanel from  '../../../assets/images/cpanel.png';
import backup from  '../../../assets/images/24.svg';
import support from  '../../../assets/images/support2.jpg';





const HostingFeaturesFluid = () => {
    return (
        <div className="hosting-features-fluid">
            <div className="bg-white py-5">
                <Container>

                                   {/*Section Headign*/}
                <SectionHeaderTwo
                    heading="Hosting features"
                    desc=" Our Reseller Web hosting features"

                />
                <span className="blank-space"></span>
                {/*Content*/}
                    <Row>
                        <Col md={{span:4,  order:2}}>
                            <div className="img">
                                <Image src={ssd}/>
                            </div>
                        </Col>
                        <Col md={{span:5, offset:2,}}>
                            <div className="content">
                                <h3> Pure SSD Disk Space</h3>
                                <h5>Pure SSD Drive (File,OS,Databases) </h5>
                                <p>Solid State Drive ( SSD ) gives you super-fast read/write speed, also can manage more disk requests then Standard hard disk drives HDD. ​We use SSD for everything (Files, OS & Databases) so you can get the biggest speed boost to your website. when you buy one of our Web Hosting packages, we include our high-performance SSDs for free.</p>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-gray py-5">
                <Container>
                    <Row>
                        <Col md={{span:4,offset:2}}>
                            <div className="img">
                                <Image src={whm}/>
                            </div>
                        </Col>
                        <Col md={{span:5}}>
                            <div className="content">
                                <h3>WHM Control Panel</h3>
                                <h5> Control your Reseller Hosting with WHM panel.</h5>
                                <p>WHM (Web Host Manager) is a Reseller control panel for the backend of the cPanel account. With WHM, you can create individual accounts, add domains, create hosting packages, manage hosting features, and perform basic maintenance.WHM gives you a lot more control and flexibility when managing a large number of sites.You can view a demo from here.</p>
                                                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-white py-5">
                <Container>
                    <Row>
                        <Col md={{span:4,  order:2}}>
                            <div className="img">
                                <Image src={cpanel}/>
                            </div>
                        </Col>
                        <Col md={{span:5, offset:2,}}>
                            <div className="content">
                                <h3>cPanel Control Panel</h3>
                                <h5>Take Full Control With The #1 Control Panel: Cpanel.</h5>
                                <p>cPanel is the most popular Linux-based web hosting control panel used to conveniently manage your web hosting. With cPanel, you can perform actions from a user-friendly dashboard instead of running complex Linux commands. cPanel can be used in different types of hosting, including Shared, Business, Dedicated, and managed WordPress hosting. With our reseller hosting plan, You will get a certain number of Cpanel account. You can view a demo from here</p>
                                </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-gray py-5">
                <Container>
                    <Row>
                        <Col md={{span:4,offset:2}}>
                            <div className="img">
                                <Image src={backup}/>
                            </div>
                        </Col>
                        <Col md={{span:5}}>
                            <div className="content">
                                <h3> Free Daily &amp; Weekly Backup</h3>
                                <h5>We Create Automatic Backups Of Your Website Daily &amp; Weekly</h5>
                                <p>We backup all of your data daily &amp; weekly basis to protect against hardware failure or accidental erasure. Our Daily &amp; weekly data backups that ensure the Website's safekeeping of files and databases. Backups are kept for up to a month. All backups are kept separately from hosting storage.</p>
                             
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-white py-5">
                <Container>
                    <Row>
                        <Col md={{span:4,  order:2}}>
                            <div className="img">
                                <Image src={support}/>
                            </div>
                        </Col>
                        <Col md={{span:5, offset:2,}}>
                            <div className="content">
                                <h3> 24/7/365 Technical Support</h3>
                                <h5>We Offer Phone, Ticket, Email and Live Chat Support.</h5>
                                <p>We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services. Whether you need advanced PHP Hosting support or have a question about how to get your blog set up, the Levohost support team is always here.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    );
};

export default HostingFeaturesFluid;
