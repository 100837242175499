import React, { createContext } from "react";

export const HostingPriceContext = createContext();
const HostingPriceContextProvider = (props) => {
    // Shared Hosting Price
    const SharedHostingPriceChart = {
        starter: {
            regular: {
                BDT: {
                    perMonth: 175,
                    perYear: 1499,
                    perTwoYears: 2998
                },
                USD: {
                    perMonth: 2.05,
                    perYear: 17.63,
                    perTwoYears: 35.27
                }
            },
            discounted: {
                BDT: {
                    perMonth: 140,
                    perYear: 1049,
                    perTwoYears: 1799
                },
                USD: {
                    perMonth: 1.64,
                    perYear: 12.34,
                    perTwoYears: 21.16
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                },
                USD: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                }
            }
        },
        standard: {
            regular: {
                BDT: {
                    perMonth: 299,
                    perYear: 2999,
                    perTwoYears: 5998
                },
                USD: {
                    perMonth: 3.51,
                    perYear: 35.28,
                    perTwoYears: 70.56
                }
            },
            discounted: {
                BDT: {
                    perMonth: 239,
                    perYear: 2099,
                    perTwoYears: 3599
                },
                USD: {
                    perMonth: 2.81,
                    perYear: 24.69,
                    perTwoYears: 42.34
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                },
                USD: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                }
            }
        },
        advanced: {
            regular: {
                BDT: {
                    perMonth: 399,
                    perYear: 3999,
                    perTwoYears: 7998
                },
                USD: {
                    perMonth: 4.69,
                    perYear: 47.04,
                    perTwoYears: 94.09
                }
            },
            discounted: {
                BDT: {
                    perMonth: 319,
                    perYear: 2799,
                    perTwoYears: 4799
                },
                USD: {
                    perMonth: 3.75,
                    perYear: 32.92,
                    perTwoYears: 56.45
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                },
                USD: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                }
            }
        }
    }

    // Business Hosting Price
    const BusinessHostingPriceChart = {
        starter: {
            regular: {
                BDT: {
                    perMonth: 999,
                    perYear: 10500,
                    perTwoYears: 21000
                },
                USD: {
                    perMonth: 11.75,
                    perYear: 123.52,
                    perTwoYears: 247.05
                }
            },
            discounted: {
                BDT: {
                    perMonth: 799,
                    perYear: 7350,
                    perTwoYears: 12600
                },
                USD: {
                    perMonth: 9.4,
                    perYear: 86.47,
                    perTwoYears: 148.23
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                },
                USD: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                }
            }
        },
        standard: {
            regular: {
                BDT: {
                    perMonth: 1550,
                    perYear: 17500,
                    perTwoYears: 35000
                },
                USD: {
                    perMonth: 18.23,
                    perYear: 205.88,
                    perTwoYears: 411.76
                }
            },
            discounted: {
                BDT: {
                    perMonth: 1240,
                    perYear: 12250,
                    perTwoYears: 21000
                },
                USD: {
                    perMonth: 14.58,
                    perYear: 144.11,
                    perTwoYears: 247.05
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                },
                USD: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                }
            }
        },
        advanced: {
            regular: {
                BDT: {
                    perMonth: 2150,
                    perYear: 24500,
                    perTwoYears: 49000
                },
                USD: {
                    perMonth: 25.29,
                    perYear: 288.23,
                    perTwoYears: 576.47
                }
            },
            discounted: {
                BDT: {
                    perMonth: 1720,
                    perYear: 17150,
                    perTwoYears: 29400
                },
                USD: {
                    perMonth: 20.23,
                    perYear: 201.76,
                    perTwoYears: 345.88
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                },
                USD: {
                    perMonth: 20,
                    perYear: 30,
                    perTwoYears: 40
                }
            }
        }
    }

    // Cheap Hosting Price
    const CheapHostingPriceChart = {
        starter: {
            regular: {
                BDT: {
                    perMonth: 375,
                    perYear: 750,
                    perTwoYears: 1500
                },
                USD: {
                    perMonth: 4.41,
                    perYear: 8.82,
                    perTwoYears: 17.64
                }
            },
            discounted: {
                BDT: {
                    perMonth: 337,
                    perYear: 637,
                    perTwoYears: 1200
                },
                USD: {
                    perMonth: 3.96,
                    perYear: 7.49,
                    perTwoYears: 14.11
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 10,
                    perYear: 15,
                    perTwoYears: 20
                },
                USD: {
                    perMonth: 10,
                    perYear: 15,
                    perTwoYears: 20
                }
            }
        },
        standard: {
            regular: {
                BDT: {
                    perMonth: 499,
                    perYear: 999,
                    perTwoYears: 1995
                },
                USD: {
                    perMonth: 5.87,
                    perYear: 11.75,
                    perTwoYears: 23.47
                }
            },
            discounted: {
                BDT: {
                    perMonth: 449,
                    perYear: 849,
                    perTwoYears: 1596
                },
                USD: {
                    perMonth: 5.28,
                    perYear: 9.98,
                    perTwoYears: 18.77
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 10,
                    perYear: 15,
                    perTwoYears: 20
                },
                USD: {
                    perMonth: 20,
                    perYear: 15,
                    perTwoYears: 20
                }
            }
        },
        advanced: {
            regular: {
                BDT: {
                    perMonth: 625,
                    perYear: 1250,
                    perTwoYears: 2500
                },
                USD: {
                    perMonth: 7.35,
                    perYear: 14.7,
                    perTwoYears: 29.41
                }
            },
            discounted: {
                BDT: {
                    perMonth: 562,
                    perYear: 1062,
                    perTwoYears: 2000
                },
                USD: {
                    perMonth: 6.61,
                    perYear: 12.49,
                    perTwoYears: 23.52
                }
            },
            discountRate: {
                BDT: {
                    perMonth: 10,
                    perYear: 15,
                    perTwoYears: 20
                },
                USD: {
                    perMonth: 10,
                    perYear: 15,
                    perTwoYears: 20
                }
            }
        }
    }

    return (
        <HostingPriceContext.Provider
            value={{
                SharedHostingPriceChart,
                BusinessHostingPriceChart,
                CheapHostingPriceChart
            }}
        >
            {props.children}
        </HostingPriceContext.Provider>
    );
};

export default HostingPriceContextProvider;
