import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing } from "@fortawesome/free-regular-svg-icons";
import { currencyChangeAction } from "../../redux/actions/currencyActions";

const TopHeader = () => {
  const dispatch = useDispatch();
  const { currency } = useSelector(state => state.currency);
  // handle change currency
  const changeCurrency = (currency) => {
    currencyChangeAction(dispatch, currency);
  }

  return (
    <div className="top-header bg-red py-2">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="d-flex align-items-center hide-sm-numner">
              <a className="me-4 call-us d-flex align-items-center" href="/">
                <span className="material-icons">phone_enabled</span>
                <span>+880 173333-4333</span>
              </a>
            </div>
          </Col>
          <Col md={6} className="text-end">
            <div className="d-flex d-sm-inline-flex align-items-center">
              <div className="currency-btn me-2">
                <Button
                  variant="light"
                  onClick={() => changeCurrency("BDT")}
                  className={currency === "BDT" ? "acitve" : ""}
                >
                  BDT
                </Button>
                <Button
                  variant="light"
                  onClick={() => changeCurrency("USD")}
                  className={currency === "USD" ? "acitve" : ""}
                >
                  USD
                </Button>
              </div>
              <a className="mx-2 mx-sm-4" href="https://my.levohost.com/submitticket.php">
                <FontAwesomeIcon icon={faLifeRing} className="me-2" />
                Support Desk
              </a>
              <a className="ms-auto ms-sm-0" href="https://my.levohost.com/login">
                Login
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopHeader;
