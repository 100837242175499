import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import Footer from "../Components/Footer/Footer";
import SearchDomain from "../Components/SearchDomain/SearchDomain";
import BdDomainPrice from "../Components/BdDomainPrice/BdDomainPrice";
import FaqDomianRegisterBD from "../Components/Faq/FaqDomianRegisterBD/FaqDomianRegisterBD";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";

const BdDomain = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading=".BD Domain Registration"
        desc="Full Control Panel | Min 2 years Registration | Lowest Price"
      />
      <SearchDomain
        heading="What is .BD Domain name?"
        desc=".BD domain is the country code top-level domain (ccTLD) for Bangladesh. It is administered by Bangladesh Telecommunications Company Limited (BTCL). Currently, registration is available to anyone under the .COM.BD, .NET.BD, .GOV.BD, .ORG.BD, MIL.BD and .EDU.BD domain extension.  LevoHost is the authorized .BD Domain name registration service provider of BTCL. Any company/person/organization from all over the world can apply for registration of domain with DOT BD and it takes a maximum of three (03) working days to activate the domain upon submitting order. BD domain must be registered for a minimum of 2 years. "
      />
      <BdDomainPrice />
      <FaqDomianRegisterBD />
      <MoreFeatureSlider />
      <Footer />
    </Fragment>
  );
};

export default BdDomain;
