import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import SupportBlock from "../Components/SupportBlock/SupportBlock";
import Footer from "../Components/Footer/Footer";
import HostingFeaturesFluid from "../Components/SharedComponent/HostingFeaturesFluid/HostingFeaturesFluid";
import GetStatedBox from "../Components/SharedComponent/GetStarted/GetStatedBox";
import ResellerHostingPlans from "../Components/ResellerHostingPlans/ResellerHostingPlans";
import FaqResellerHosting from "../Components/Faq/FaqResellerHosting/FaqResellerHosting";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
const ResellerHosting = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Reseller Hosting"
        desc="Enjoy White Labeled Reseller Hosting Powered By cPanel"
      />
      {/* <ResellerHostingPlansTop /> */}
      <ResellerHostingPlans />
      <HostingFeaturesFluid />
      <GetStatedBox />
      <FaqResellerHosting />
      <MoreFeatureSlider />
      <SupportBlock />
      <Footer />
    </Fragment>
  );
};

export default ResellerHosting;
