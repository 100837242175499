import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import SupportBlock from "../Components/SupportBlock/SupportBlock";
import Footer from "../Components/Footer/Footer";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import ExtraFeatures from "../Components/SharedComponent/ExtraFeatures/ExtraFeatures";
import OperatingSupport from "../Components/SharedComponent/OperatingSupport/OperatingSupport";
import ListFeatures from "../Components/SharedComponent/ListFeatures/ListFeatures";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
import DedicatedHostingPlanSlider from "../Components/DedicatedHostingPlanSlider/DedicatedHostingPlanSlider";
import FaqDedicatedHosting from "../Components/Faq/FaqDedicatedHosting/FaqDedicatedHosting";
import HostingFeaturesDedicated from "../Components/SharedComponent/HostingFeatures/HostingFeaturesDedicated";

const DedicatedServers = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Dedicated Server (Self Managed)"
        desc="Top-tier performance at a cheap price"
      />
      <DedicatedHostingPlanSlider />
      <HostingFeaturesDedicated />
      <OperatingSupport />
      <ListFeatures />
      <FaqDedicatedHosting />
      <MoreFeatureSlider />
      <SupportBlock />
      <Footer />
    </Fragment>
  );
};

export default DedicatedServers;
