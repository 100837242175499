import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import Footer from "../Components/Footer/Footer";
import SearchDomain from "../Components/SearchDomain/SearchDomain";
import DomainPrice from "../Components/DomainPrice/DomainPrice";
import DomainAddons from "../Components/DomainAddons/DomainAddons";
import TransferDomainShowcase from "../Components/TransferDomainShowcase/TransferDomainShowcase";
import FaqDomianRegister from "../Components/Faq/FaqDomianRegister/FaqDomianRegister";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
import TopLevelDomain from "../Components/TopLevelDomain/TopLevelDomain";

const DomainRegistration = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Domain Registration"
        desc="Low Prices & Huge Selection | Full Control Panel"
      />
      <SearchDomain
        heading='What is a domain name?'
        desc='A domain name is simply a human-readable form of an IP address or in other words, a domain name is the address of your website that people type in the browser URL bar to visit your website. Every website has a domain name, and it’s completely unique to that site, also Every domain name has a suffix that indicates which top-level domain (TLD) it belongs to. There are only a limited number of top-level domains. For example: .com, .net . org, .info, .biz'


      />
      <TopLevelDomain />
      <DomainPrice />
      <DomainAddons />
      <TransferDomainShowcase />
      <FaqDomianRegister />
      <MoreFeatureSlider />
      <Footer />
    </Fragment>
  );
};

export default DomainRegistration;
