import React from 'react';
import {Container, Row, Col, Image} from "react-bootstrap";
import itemImg1 from '../../../assets/images/wordpress.png';
import itemImg2 from '../../../assets/images/joomla.png';
import itemImg3 from '../../../assets/images/drupal.png';
import itemImg4 from '../../../assets/images/megento.png';
import itemImg5 from '../../../assets/images/cpanel6.png';
import itemImg6 from '../../../assets/images/debian.png';
import itemImg7 from '../../../assets/images/centos2.png';
import itemImg8 from '../../../assets/images/ubuntu.png';
import itemImg9 from '../../../assets/images/suse.png';
import itemImg10 from '../../../assets/images/freebsd.png';

const OperatingSupport = () => {
    return (
        <div className="operating-support">
            <Container>
                <Row>
                    <Col md={{span:8, offset:2}}>
                        <h3>Available Application &amp; Operating Systems</h3>

                        <div className="operating-systems-items">
                            <div className="item">
                                <Image src={itemImg1}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg2}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg3}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg4}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg5}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg6}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg7}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg8}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg9}/>
                            </div>
                            <div className="item">
                                <Image src={itemImg10}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default OperatingSupport;
