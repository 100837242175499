import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import Footer from "../Components/Footer/Footer";
import HowAffiliateWork from "../Components/HowAffiliateWork/HowAffiliateWork";
import AffiliateCommission from "../Components/AffiliateCommission/AffiliateCommission";
import ListSection from "../Components/SharedComponent/ListSection/ListSection";
import AffiliateTc from "../Components/AffiliateTC/AffiliateTC";
import WhatSection from "../Components/SharedComponent/WhatSection/WhatSection";
import FaqAffiliate from "../Components/Faq/FaqAffiliate/FaqAffiliate";

const Affiliate = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Join the Affiliate Team"
        desc="Get commissions each time you refer a new customer to LevoHost!"
      />
      <HowAffiliateWork />
      <AffiliateCommission />
      <ListSection />
      <AffiliateTc />
      <WhatSection
        spcStyle="text-white bg-red"
        btnName="light"
        heading="Ready to become an Affiliate"
        desc="LevoHost offers great affiliate commissions, but most of our affiliates endorse us for a different reason: the Quality & Performance of our service. If you enjoy using LevoHost, share it with your friends and colleagues to earn more money."
        btnText="Join Now"
      />
      <FaqAffiliate />

      <Footer />
    </Fragment>
  );
};

export default Affiliate;
