import image from "../../assets/images/one_click_icons.png";

const OneClickAppInstall = () => {
    return (
        <div className="one-click-app-install-area py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="title">Over 250+ Free 1-Click <br /><span className="colored-text">App Installs</span></h2>
                        <p className="details">Why waste your valuable time installing the web application MANUALLY ?? Install your favorite web applications within minutes by Softaculous auto-installer. Just click on the Softaculous icon from your cPanel account, fill in your installation details and then install the application to your hosting account with Just 1-click.</p>
                    </div>
                    <div className="col-md-6">
                        <img className="img-fluid px-5 pt-3 pb-5" src={image} alt="click" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OneClickAppInstall;