import React, { useContext } from "react";
import { Button, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import vps_icon1 from "../../assets/images/vps_icon1.png";
import vps_icon2 from "../../assets/images/vps_icon2.png";
import vps_icon3 from "../../assets/images/vps_icon3.png";
import vps_icon4 from "../../assets/images/vps_icon4.png";
import vps_icon5 from "../../assets/images/vps_icon5.png";
import { currencies } from "../../constants/currencies";
import { HostingContext } from "../../Contexts/HostingContext";

const DedicatedPlansPrice = () => {
  const { currency } = useSelector(state => state.currency);
  const { DedicatedServerHostingPlan } = useContext(HostingContext);
  return (
    <div className="dedicate-plan-price">
      {DedicatedServerHostingPlan.map(plan =>
        <div className="plans-item" key={plan.id}>
          <div className="plans-item-top">
            <h3>{plan.processor}</h3>
            <h4>{currency === currencies.BDT ? "TK " + plan.price.BDT : "$ " + plan.price.USD}/MO</h4>
            <a href={plan.getStartedLink}>  <Button className="pink-btn">Get Started</Button> </a>
          </div>
          <div className="plans-item-bottom">
            <div className="item-icon item-icon-first">
              <span className="icon">
                <Image src={vps_icon1} />
              </span>
              <div className="margin-x1">
                <span className="sub-title">CPU</span>
                <p>{plan.cpu}</p>
              </div>
            </div>
            <div className="item-icon">
              <span className="icon">
                <Image src={vps_icon2} />
              </span>
              <div className="margin-x2">
                <span className="sub-title">RAM</span>
                <p> {plan.ram} </p>
              </div>
            </div>
            <div className="item-icon">
              <span className="icon">
                <Image src={vps_icon3} />
              </span>
              <div className="margin-x3">
                <span className="sub-title">Storage</span>
                <p>{plan.storage}</p>
              </div>
            </div>
            <div className="item-icon">
              <span className="icon">
                <Image src={vps_icon4} />
              </span>
              <div className="margin-x4">
                <span className="sub-title"> Public Bandwidth &amp; Traffic</span>
                <p>{plan.bandWithAndTraffic} </p>
              </div>
            </div>
            <div className="item-icon">
              <span className="icon">
                <Image src={vps_icon5} />
              </span>
              <div className="margin-x5">
                <span className="sub-title">IP Address: </span>
                <p>{plan.ipAddress}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DedicatedPlansPrice;
