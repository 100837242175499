import React from 'react';
import {Container,Row,Col,Button} from "react-bootstrap";

const SslReasons = () => {
    return (
        <div className="ssl-reasons">
            <Container>
                <Row>
                    <Col className="text-center mb-4">
                        <h3>6 Reasons why SSL is a must have for any website</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:10, offset:1}} className="mt-4">
                        <Row>
                            <Col md={6}>
                                <div className="content">
                                    <span className="number">1</span>
                                    <p><strong> Website Security: </strong>The data sent over the standard HTTP protocol is less secure. For most browsing activities this isn’t much of an issue, but if your site collects any kind of information like credit card information or any other confidential information from your customers, then securing this data is a must. </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="content">
                                    <span className="number">2</span>
                                    <p><strong>Search Engine Ranking: </strong>To give a safer web browsing experience to the user from 2018 onwards, Google has decided to flag the websites which do not have an SSL/TLS Certificate installed on their website. HTTPS provides websites with a great SEO ranking boost. Google rewards the sites with a valid SSL certificate by ranking them higher. </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="content">
                                    <span className="number">3</span>
                                    <p><strong>Trustworthy Brand: </strong> SSL certificates are very essential from a customer trust point of view. The easy to identify signs inform the users that the data they are sending will be secured.</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="content">
                                    <span className="number">4</span>
                                    <p><strong> Satisfy PCI Requirements: </strong> If you accept online payments, you must follow PCI requirements. To accept online payments, your website must be PCI compliant. Having an SSL certificate installed on your website is one of the 12 primary requirements set by the PCI </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="content">
                                    <span className="number">5</span>
                                    <p><strong>Speed Up Your Site With HTTP/2: </strong> You must need an SSL certificate to run HTTP/2, the more powerful and faster successor to HTTP. Using HTTP/2 can make your website's load faster for users </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="content">
                                    <span className="number">6</span>
                                    <p><strong>Enhances User Experience: </strong> When the users visit a site that shows a symbol of a green padlock in the URL bar along with the website’s name written in green, they automatically feel more comfortable. Blend this with the improved website speed, and it is understandable that SSL certification innately creates a much better user experience. </p>
                                </div>
                            </Col>

                            <Col md={12} className="text-center mt-4">
                                <Button variant="danger" className="btn-border-1">Secure your website now</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SslReasons;
