import React, { useContext } from "react";
import { Accordion, Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import AccordionItem from "../../AccordionItem/AccordionItem";
import { FaqContext } from "../../../Contexts/FaqContext";

const FaqDomainName = () => {
  const { faqDomainName } = useContext(FaqContext);
  return (
    <div className="faq-section">
      <Container>
        <div className="faq-inner">
          <Accordion className="accordion-faq" defaultActiveKey="0">
            {faqDomainName.map((faq) => {
              return (
                <AccordionItem
                  key={faq.id}
                  faqNo={faq.id}
                  faqQuestion={faq.question}
                  faqAnswer={ReactHtmlParser(faq.answer)}
                />
              );
            })}
          </Accordion>
        </div>
      </Container>
      <p className="faq-hint">
      
      </p>
    </div>
  );
};

export default FaqDomainName;
