import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, ButtonGroup, Button } from "react-bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading";
import { HostingPriceContext } from "../../Contexts/HostingPriceContext";
import { useSelector } from "react-redux";
import { currencies } from "../../constants/currencies";

// PLAN SCHEMA
const schema = {
    perMonth: 'perMonth',
    perYear: 'perYear',
    perTwoYears: 'perTwoYears'
}

const CheapHostingPlansTop = () => {
    const { currency } = useSelector(state => state.currency);
    // PRICE CHART 
    const { CheapHostingPriceChart: PRICE_CHART } = useContext(HostingPriceContext);

    // HOSTING PLAN
    const [plan, setPlan] = useState(schema.perYear);
    const [price, setPrice] = useState({
        starter: {
            BDT: {
                regular: PRICE_CHART.starter.regular.BDT.perYear,
                discounted: PRICE_CHART.starter.discounted.BDT.perYear,
                discount: PRICE_CHART.starter.discountRate.BDT.perYear
            },
            USD: {
                regular: PRICE_CHART.starter.regular.USD.perYear,
                discounted: PRICE_CHART.starter.discounted.USD.perYear,
                discount: PRICE_CHART.starter.discountRate.USD.perYear
            }
        },
        standard: {
            BDT: {
                regular: PRICE_CHART.standard.regular.BDT.perYear,
                discounted: PRICE_CHART.standard.discounted.BDT.perYear,
                discount: PRICE_CHART.standard.discountRate.BDT.perYear
            },
            USD: {
                regular: PRICE_CHART.standard.regular.USD.perYear,
                discounted: PRICE_CHART.standard.discounted.USD.perYear,
                discount: PRICE_CHART.standard.discountRate.USD.perYear
            }
        },
        advanced: {
            BDT: {
                regular: PRICE_CHART.advanced.regular.BDT.perYear,
                discounted: PRICE_CHART.advanced.discounted.BDT.perYear,
                discount: PRICE_CHART.advanced.discountRate.BDT.perYear
            },
            USD: {
                regular: PRICE_CHART.advanced.regular.USD.perYear,
                discounted: PRICE_CHART.advanced.discounted.USD.perYear,
                discount: PRICE_CHART.advanced.discountRate.USD.perYear
            }
        }
    })
    useEffect(() => {
        // PLAN PRICE DETECTOR
        if (plan === schema.perMonth) {
            setPrice({
                starter: {
                    BDT: {
                        regular: PRICE_CHART.starter.regular.BDT.perMonth,
                        discounted: PRICE_CHART.starter.discounted.BDT.perMonth,
                        discount: PRICE_CHART.starter.discountRate.BDT.perMonth
                    },
                    USD: {
                        regular: PRICE_CHART.starter.regular.USD.perMonth,
                        discounted: PRICE_CHART.starter.discounted.USD.perMonth,
                        discount: PRICE_CHART.starter.discountRate.USD.perMonth
                    }
                },
                standard: {
                    BDT: {
                        regular: PRICE_CHART.standard.regular.BDT.perMonth,
                        discounted: PRICE_CHART.standard.discounted.BDT.perMonth,
                        discount: PRICE_CHART.standard.discountRate.BDT.perMonth
                    },
                    USD: {
                        regular: PRICE_CHART.standard.regular.USD.perMonth,
                        discounted: PRICE_CHART.standard.discounted.USD.perMonth,
                        discount: PRICE_CHART.standard.discountRate.USD.perMonth
                    }
                },
                advanced: {
                    BDT: {
                        regular: PRICE_CHART.advanced.regular.BDT.perMonth,
                        discounted: PRICE_CHART.advanced.discounted.BDT.perMonth,
                        discount: PRICE_CHART.advanced.discountRate.BDT.perMonth
                    },
                    USD: {
                        regular: PRICE_CHART.advanced.regular.USD.perMonth,
                        discounted: PRICE_CHART.advanced.discounted.USD.perMonth,
                        discount: PRICE_CHART.advanced.discountRate.USD.perMonth
                    }
                }
            });
        } else if (plan === schema.perYear) {
            setPrice({
                starter: {
                    BDT: {
                        regular: PRICE_CHART.starter.regular.BDT.perYear,
                        discounted: PRICE_CHART.starter.discounted.BDT.perYear,
                        discount: PRICE_CHART.starter.discountRate.BDT.perYear
                    },
                    USD: {
                        regular: PRICE_CHART.starter.regular.USD.perYear,
                        discounted: PRICE_CHART.starter.discounted.USD.perYear,
                        discount: PRICE_CHART.starter.discountRate.USD.perYear
                    }
                },
                standard: {
                    BDT: {
                        regular: PRICE_CHART.standard.regular.BDT.perYear,
                        discounted: PRICE_CHART.standard.discounted.BDT.perYear,
                        discount: PRICE_CHART.standard.discountRate.BDT.perYear
                    },
                    USD: {
                        regular: PRICE_CHART.standard.regular.USD.perYear,
                        discounted: PRICE_CHART.standard.discounted.USD.perYear,
                        discount: PRICE_CHART.standard.discountRate.USD.perYear
                    }
                },
                advanced: {
                    BDT: {
                        regular: PRICE_CHART.advanced.regular.BDT.perYear,
                        discounted: PRICE_CHART.advanced.discounted.BDT.perYear,
                        discount: PRICE_CHART.advanced.discountRate.BDT.perYear
                    },
                    USD: {
                        regular: PRICE_CHART.advanced.regular.USD.perYear,
                        discounted: PRICE_CHART.advanced.discounted.USD.perYear,
                        discount: PRICE_CHART.advanced.discountRate.USD.perYear
                    }
                }
            });
        } else if (plan === schema.perTwoYears) {
            setPrice({
                starter: {
                    BDT: {
                        regular: PRICE_CHART.starter.regular.BDT.perTwoYears,
                        discounted: PRICE_CHART.starter.discounted.BDT.perTwoYears,
                        discount: PRICE_CHART.starter.discountRate.BDT.perTwoYears
                    },
                    USD: {
                        regular: PRICE_CHART.starter.regular.USD.perTwoYears,
                        discounted: PRICE_CHART.starter.discounted.USD.perTwoYears,
                        discount: PRICE_CHART.starter.discountRate.USD.perTwoYears
                    }
                },
                standard: {
                    BDT: {
                        regular: PRICE_CHART.standard.regular.BDT.perTwoYears,
                        discounted: PRICE_CHART.standard.discounted.BDT.perTwoYears,
                        discount: PRICE_CHART.standard.discountRate.BDT.perTwoYears
                    },
                    USD: {
                        regular: PRICE_CHART.standard.regular.USD.perTwoYears,
                        discounted: PRICE_CHART.standard.discounted.USD.perTwoYears,
                        discount: PRICE_CHART.standard.discountRate.USD.perTwoYears
                    }
                },
                advanced: {
                    BDT: {
                        regular: PRICE_CHART.advanced.regular.BDT.perTwoYears,
                        discounted: PRICE_CHART.advanced.discounted.BDT.perTwoYears,
                        discount: PRICE_CHART.advanced.discountRate.BDT.perTwoYears
                    },
                    USD: {
                        regular: PRICE_CHART.advanced.regular.USD.perTwoYears,
                        discounted: PRICE_CHART.advanced.discounted.USD.perTwoYears,
                        discount: PRICE_CHART.advanced.discountRate.USD.perTwoYears
                    }
                }
            });
        }
    }, [plan])

    const handleClick = (e) => {
        setPlan(e.target.value);
    };

    return (
        <div className="hosting-plans">
            <Container>
                <SectionHeading
                    heading="Our Hosting Plans"
                    desc="We sorted our web hosting packages are more user-friendly and well configured. Just an easy-as-pie way to get a great website up and running"
                />
                <Row>
                    <Col md={12} className="text-center my-4 hosting-dur-btn">
                        <ButtonGroup onClick={handleClick}>
                            <Button value={schema.perMonth} variant={`outline-secondary ${plan === schema.perMonth ? 'active' : ''}`}>
                                6 MONTH
                            </Button>
                            <Button value={schema.perYear} variant={`outline-secondary ${plan === schema.perYear ? 'active' : ''}`}>
                                1 Year
                            </Button>
                            <Button value={schema.perTwoYears} variant={`outline-secondary ${plan === schema.perTwoYears ? 'active' : ''}`}>
                                2 Year
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <div className="hosting-plan-slider hosting-plan-slider-top text-center">
                    <Container>
                        <Row>
                            <Col md={6} lg={4} className="outer_hosting_plan_card">
                                <div className="hosting_plan_card bg_img01">
                                    <div className="heading_hosting_plan">
                                        <h3>Minimum</h3>
                                        <p>
                                            {/* <strong class="green_clr">Minimum </strong> hosting
                                                package is an easy-as-pie way to get a great website up
                                                and running. <br />. */}
                                        </p>
                                    </div>
                                    <ul className="hosting_plan_list">
                                        <li>SSD Disk space: 750 MB</li>
                                        <li>Monthly Bandwidth: 30 GB</li>
                                        <li>Cores Per cPanel User: 0.5 vCore</li>
                                        <li>Physical Memory Per cPanel User: 1 GB</li>
                                        <li>Entry Processes : 15</li>
                                        <li>Addon Domain Allowed: 01</li>
                                        <li>Email Accounts: 5</li>
                                        <li>MySQL Databases: 02</li>
                                        <li>Subdomain: 02</li>
                                        <li>FTP Accounts: 5</li>
                                        <li>Cloudlinux OS</li>
                                        <li>Free SSL Certificate: YES</li>
                                    </ul>
                                </div>
                                <div className="hosting_plan_price_box bg_f5f5f5">
                                    <div className="price_box">
                                        <span>{currency === currencies.BDT ? "TK" : "$"}</span>
                                        {currency === currencies.BDT ? price.starter.BDT.discounted : price.starter.USD.discounted}
                                        <small>
                                            {plan === schema.perMonth ?
                                                "/per month"
                                                : plan === schema.perYear ?
                                                    "/per year"
                                                    : plan === schema.perTwoYears &&
                                                    "/per 2 year"
                                            }
                                        </small>
                                    </div>
                                    <small className="off-price">
                                        <span>Regular: <del>{currency === currencies.BDT ? "TK" : "$"} {currency === currencies.BDT ? price.starter.BDT.regular : price.starter.USD.regular}/{plan === schema.perMonth ?
                                            "per month"
                                            : plan === schema.perYear ?
                                                "per year"
                                                : plan === schema.perTwoYears &&
                                                "per 2 year"
                                        }</del></span><span className="dis_bg"> {currency === currencies.BDT ? price.starter.BDT.discount : price.starter.USD.discount}% OFF </span>
                                    </small>
                                    <a href="https://my.levohost.com/store/cheap-shared-hosting/minimum" className="hosting_plan_card_btn">Order Now</a>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className="outer_hosting_plan_card">
                                <div className="hosting_plan_card bg_img02">
                                    <div className="recommended">Recommended</div>
                                    <div className="heading_hosting_plan">
                                        <h3>Medium</h3>
                                        <p>
                                            {/* <strong class="green_clr">MEDIUM </strong> cheap hosting
                                                package is ideal for small business owners or who want
                                                to host multiple websites. */}
                                        </p>
                                    </div>
                                    <ul className="hosting_plan_list">
                                        <li>SSD Disk space: 1.5 GB</li>
                                        <li>Monthly Bandwidth: 70 GB</li>
                                        <li>Cores Per cPanel User: 0.5 vCore</li>
                                        <li>Physical Memory Per cPanel User: 1 GB</li>
                                        <li>Entry Processes : 20</li>
                                        <li>Addon Domain Allowed: 02</li>
                                        <li>Email Accounts: 10</li>
                                        <li>MySQL Databases: 05</li>
                                        <li>Subdomain: 05</li>
                                        <li>FTP Accounts: 10</li>
                                        <li>Cloudlinux OS</li>
                                        <li>Free SSL Certificate: YES</li>
                                    </ul>
                                </div>
                                <div className="hosting_plan_price_box bg_f5f5f5">
                                    <div className="price_box">
                                        <span>{currency === currencies.BDT ? "TK" : "$"}</span>
                                        {currency === currencies.BDT ? price.standard.BDT.discounted : price.standard.USD.discounted}
                                        <small>
                                            {plan === schema.perMonth ?
                                                "/per month"
                                                : plan === schema.perYear ?
                                                    "/per year"
                                                    : plan === schema.perTwoYears &&
                                                    "/per 2 year"
                                            }
                                        </small>
                                    </div>
                                    <small className="off-price">
                                        <span>Regular: <del>{currency === currencies.BDT ? "TK" : "$"} {currency === currencies.BDT ? price.standard.BDT.regular : price.standard.USD.regular}/{plan === schema.perMonth ?
                                            "per month"
                                            : plan === schema.perYear ?
                                                "per year"
                                                : plan === schema.perTwoYears &&
                                                "per 2 year"
                                        }</del></span><span className="dis_bg"> {currency === currencies.BDT ? price.standard.BDT.discount : price.standard.USD.discount}% OFF </span>
                                    </small>
                                    <a href="https://my.levohost.com/store/cheap-shared-hosting/medium" className="hosting_plan_card_btn">Order Now</a>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className="outer_hosting_plan_card">
                                <div className="hosting_plan_card bg_img03">
                                    <div className="heading_hosting_plan">
                                        <h3>Maximum</h3>
                                        <p>
                                            {/* <strong class="green_clr">ADVANCED </strong> hosting
                                                package is great for moderate traffic and who is looking
                                                more power and enhanced performance. */}
                                        </p>
                                    </div>
                                    <ul className="hosting_plan_list">
                                        <li>SSD Disk space: 2.5 GB</li>
                                        <li>Monthly Bandwidth: 120 GB</li>
                                        <li>Cores Per cPanel User: 0.5 vCore</li>
                                        <li>Physical Memory Per cPanel User: 1 GB</li>
                                        <li>Entry Processes : 20</li>
                                        <li>Addon Domain Allowed: 03</li>
                                        <li>Email Accounts: 15</li>
                                        <li>MySQL Databases: 10</li>
                                        <li>Subdomain: 10</li>
                                        <li>FTP Accounts: 15</li>
                                        <li>Cloudlinux OS</li>
                                        <li>Free SSL Certificate: YES</li>
                                    </ul>
                                </div>
                                <div className="hosting_plan_price_box bg_f5f5f5">
                                    <div className="price_box">
                                        <span>{currency === currencies.BDT ? "TK" : "$"}</span>
                                        {currency === currencies.BDT ? price.advanced.BDT.discounted : price.advanced.USD.discounted}
                                        <small>
                                            {plan === schema.perMonth ?
                                                "/per month"
                                                : plan === schema.perYear ?
                                                    "/per year"
                                                    : plan === schema.perTwoYears &&
                                                    "/per 2 year"
                                            }
                                        </small>
                                    </div>
                                    <small className="off-price">
                                        <span>Regular: <del>{currency === currencies.BDT ? "TK" : "$"} {currency === currencies.BDT ? price.advanced.BDT.regular : price.advanced.USD.regular}/{plan === schema.perMonth ?
                                            "per month"
                                            : plan === schema.perYear ?
                                                "per year"
                                                : plan === schema.perTwoYears &&
                                                "per 2 year"
                                        }</del></span><span className="dis_bg"> {currency === currencies.BDT ? price.advanced.BDT.discount : price.advanced.USD.discount}% OFF </span>
                                    </small>
                                    <a href="https://my.levohost.com/store/cheap-shared-hosting/maximum" className="hosting_plan_card_btn">Order Now</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </div>
    );
};

export default CheapHostingPlansTop;
