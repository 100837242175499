import React, { useState } from "react";
import { Col, Container, Row, ButtonGroup, Button } from "react-bootstrap";
import SectionHeading from "../SectionHeading/SectionHeading";
import ResellerHostingPlansRow from "./ResellerHostingPlansRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// PLAN SCHEMA
const schema = {
  perMonth: 'perMonth',
  perYear: 'perYear',
  perThreeYears: 'perThreeYears'
}

const ResellerHostingPlans = (props) => {
  // HOSTING PLAN
  const [plan, setPlan] = useState(schema.perYear);
  const handleClick = (e) => {
    setPlan(e.target.value);
  };
  return (
    <div className="reseller-hosting-plans">
      <Container>
        <SectionHeading
          heading="Our Hosting Plans"
          desc="From professional business to enterprise, we've got you covered!"
        />
        <Row className="mb-4">
          <Col md={12} className="text-center mb-4 hosting-dur-btn">
            <ButtonGroup onClick={handleClick}>
              <Button value={schema.perMonth} variant={`outline-secondary ${plan === schema.perMonth ? 'active' : ''}`}>
                Monthly
              </Button>
              <Button value={schema.perYear} variant={`outline-secondary ${plan === schema.perYear ? 'active' : ''}`}>
                6 MONTH
              </Button>
              <Button value={schema.perThreeYears} variant={`outline-secondary ${plan === schema.perThreeYears ? 'active' : ''}`}>
                1 Year
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <div className="div-responsive">
          <Row>
            <Col md={12}>
              <ResellerHostingPlansRow plan={plan} />
            </Col>
          </Row>
        </div>
      </Container>
      <section className="full-technical">
        <div className="header">
          <h3>Advanced Features</h3>
          <p>
            
          </p>
        </div>
        <Container>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <div className="tech-item">
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Unlimited Sub Domains
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Unlimited Parked Domains
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Unlimited MySQL Databases
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Unlimited FTP Accounts
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Unlimited E-Mail Accounts
                </p>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="tech-item">
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  LitesSpeed Web Server
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  cPanel Control Panel
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  WHM Panel
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  SSH Access (Jailed)
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Cloudlinux OS 
                </p>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="tech-item">
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  FREE cPanel Migration
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  24/7 Technical Support
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  99.9% Server Uptime Guarantee
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  30 Day Money Back Guarantee
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  FREE Let's Encrypt SSL Certs
                </p>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="tech-item">
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  CloudFlare CDN
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Spam Protection
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Awstats &amp; Webalizer
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  ionCube PHP Loader
                </p>
                <p>
                  <span className="me-1">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  Softaculous Script Installer
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ResellerHostingPlans;
