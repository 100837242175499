import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import DomainSearchForm from "../DomainSearchForm/DomainSearchForm";

const DomainSearch = () => {
    return (
        <div className="domain-search">
            <Container>
                <Row>
                    <Col md={{span:10, offset:1}}>
                        <div className="search_domain_box">
                            <h2> Find Your Perfect Domain Name </h2>
                            <p> Huge Selection. Low Costs. New Extensions. Register your perfect domain name today. </p>
                            <DomainSearchForm/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DomainSearch;
