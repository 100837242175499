import React from 'react';
import {Container,Row,Col} from "react-bootstrap";

const SslFacility = () => {
    return (
        <div className="ssl-facility">

            <Container fluid>
                <Row>
                    <Col md={12} className="heading text-center mb-4">
                        <h3>ALL SSL Certificates Include:</h3>
                    </Col>
                    <Col md={4}>
                        <div className="card-item">
                        <h3>Encrypted Server and Browser Connenction</h3>
                            <p>The data is encrypted before being sent and then is decrypted upon receipt and before processing.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="card-item">
                            <h3>15 Days Money Back Guarantee </h3>
                            <p>If you are unsatisfied for any reason, simply send us a refund request ticket, and we'll back your money within 7 working day.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="card-item">
                            <h3>99% Browser Compability: </h3>
                            <p>Our trusted SSL certificates are compatible with the most of the browsers around the world,included Mozilla, Chrome,Safari.</p>
                        </div>
                    </Col>
                    
                    <Col md={4}>
                        <div className="card-item">
                            <h3> SSL installation Included</h3>
                            <p>Our support team will take care of your ssl installation so you can focus on your business</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="card-item">
                            <h3>Up to 256-BIT Encryption: </h3>
                            <p>Experiance strong and secure encryption to keep data transmission more secure</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="card-item">
                            <h3> Expert Customer Support: </h3>
                            <p>Our Support team is always here to help our valuable customers. We offer phone, ticket, email, and live chat support on our all products/services</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SslFacility;
