import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import SectionHeadV2 from "../SectionHeadV2/SectionHeadV2";
import img1 from "../../assets/images/dependabl_support.png";
import img2 from "../../assets/images/multi_location.png";
import img3 from "../../assets/images/secured.png";
import img4 from "../../assets/images/control_panel.png";
import img5 from "../../assets/images/click_installation.png";
import img6 from "../../assets/images/multi_year_registration.png";

const DomainAddons = () => {
  return (
    <div className="domain-addons">
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }} className="text-center mb-5">
            <SectionHeadV2
              heading="FREE Add-ons with all"
              headingRed="Domain Names"
              desc="You'll get two Free email account, Domain Theft Protection, Domain Forwarding, DNS Management and many more Premium Features with all of the domain names above."
            />
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <div className="domain-addons-item">
              <div className="icon">
                {/* <span className="material-icons">question_answer</span> */}
                <Image src={img1} />
              </div>
              <p>Free Email Account</p>
            </div>
          </Col>
          <Col md={2}>
            <div className="domain-addons-item">
              <div className="icon">
                {/* <span className="material-icons">language</span> */}
                <Image src={img2} />
              </div>
              <p>Domain Forwarding</p>
            </div>
          </Col>
          <Col md={2}>
            <div className="domain-addons-item">
              <div className="icon">
                {/* <span className="material-icons">verified_user</span> */}
                <Image src={img3} />
              </div>
              <p>Domain Theft Protection</p>
            </div>
          </Col>
          <Col md={2}>
            <div className="domain-addons-item">
              <div className="icon">
                {/* <span className="material-icons">dns</span> */}
                <Image src={img4} />
              </div>
              <p>DNS Management</p>
            </div>
          </Col>
          <Col md={2}>
            <div className="domain-addons-item">
              <div className="icon">
                {/* <span className="material-icons">settings</span> */}
                <Image src={img5} />
              </div>
              <p>Easy to use Control Panel</p>
            </div>
          </Col>
          <Col md={2}>
            <div className="domain-addons-item">
              <div className="icon">
                {/* <span className="material-icons">date_range</span> */}
                <Image src={img6} />
              </div>
              <p>Multi-Year Registration</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DomainAddons;
