import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import ListDescSectionTos from "../Components/SharedComponent/ListDescSection/ListDescSectionTos";
import FaqSecTos from "../Components/Faq/FaqSec/FaqSecTos";
import Footer from "../Components/Footer/Footer";

const Tos = () => {
  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Terms Of Services"
        desc="You will find policies and agreements for services and products available at LevoHost"
      />
      <ListDescSectionTos />
      <FaqSecTos />
      <Footer />
    </Fragment>
  );
};

export default Tos;
