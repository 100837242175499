import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MoreFeatureSliderContext } from "../../../Contexts/MoreFeatureSliderContext";
import { useSelector } from "react-redux";
import { currencies } from "../../../constants/currencies";

const MoreFeatureSliderItem = () => {
  const { currency } = useSelector(state => state.currency);
  const { sliderItems } = useContext(MoreFeatureSliderContext);
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 5,
    centerMode: true,

    responsive: [
      {
        breakpoint: 1230,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: false
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {sliderItems.map((sliderItem) => {
        return (
          <div className="more-feature-slider-item" key={sliderItem.id}>
            <a href={sliderItem.url}>
              <div className="slider-body">
                <h3 className="title">{sliderItem.heading}</h3>
                <p className="sub-title">{sliderItem.subHeading}</p>

                <div className="features">
                  <ul>
                    {sliderItem.listItem.map((list) => {
                      return (
                        <li key={sliderItem.id}>
                          <span className="material-icons">done</span>
                          <span>{list}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <footer>
                <Button variant="info">
                  <span className="from">from</span>
                  <span className="price">
                    {currency === currencies.BDT ? "TK " + sliderItem.Price.BDT : "$ " + sliderItem.Price.USD}<small>/{sliderItem.plan}</small>
                  </span>
                </Button>
              </footer>
            </a>
          </div>
        );
      })}
    </Slider>
  );
};

export default MoreFeatureSliderItem;
