import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import DedicatedPlansPrice from "../DedicatedPlansPrice/DedicatedPlansPrice";

import { Container } from "react-bootstrap";

const DedicatedHostingPlanSlider = () => {
  return (
    <div className="dedicated-hosting-plan-slider">
      <SectionHeading
        heading=" Dedicated Server Hosting plans"
        desc="Big or small, website or application - we have a perfect Dedicated server hosting configuration for you."
      />
      <br />
      <br />
      <div className="dedicated-hosting-plan-slider-inner">
        <Container>
          <DedicatedPlansPrice />
        </Container>
      </div>
    </div>
  );
};

export default DedicatedHostingPlanSlider;
