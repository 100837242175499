import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const ListDescSection = () => {
  return (
    <div className="list-desc-section">
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <h3>
              LevoHost <span>Privacy Policy</span>
            </h3>

            <p>
              This is LevoHost (“LevoHost”, “we”, or “our”) Privacy Policy. This
              Privacy Policy discusses how we collect, use, maintain and
              disclose information collected by us (LevoHost) from our
              customers, visitors to our websites, and, in some cases, visitors
              to our customer’s websites (“Users”). We recommend that you read
              this Policy in full.
            </p>

            <div className="mt-5">
              <h5>Below you will find information about the following:</h5>
              <ul>
                <li>1. Information that we collect from our users </li>
                <li>2. How we utilize information </li>
                <li>3. Cookies &amp; Log Files</li>
                <li>4. Your Rights </li>
                <li>5. Age restriction</li>
                <li>6. Changes to our Privacy Policy</li>
                <li>7. Further questions and contacts</li>           
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListDescSection;
