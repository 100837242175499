import React, { Fragment } from "react";
import Header from "../Components/Header/Header";
import HeaderSmall from "../Components/HeaderSmall/HeaderSmall";
import ExtraFeatures from "../Components/SharedComponent/ExtraFeatures/ExtraFeatures";
import SupportBlock from "../Components/SupportBlock/SupportBlock";
import Footer from "../Components/Footer/Footer";
import BusinessHostingPlans from "../Components/BusinessHostingPlans/BusinessHostingPlans";
import BusinessHostingPlansTop from "../Components/BusinessHostingPlans/BusinessHostingPlansTop";
import FaqBusinessHosting from "../Components/Faq/FaqBusinessHosting/FaqBusinessHosting";
import MoreFeatureSlider from "../Components/SharedComponent/MoreFeatureSlider/MoreFeatureSlider";
import WebHostingSpecs from "../Components/WebHostingSpecs/WebHostingSpecs";
import HostingAwesomeFeatures from "../Components/CommonHostingSections/HostingAwesomeFeatures";
import WhyChooseLevoHost from "../Components/CommonHostingSections/WhyChooseLevoHost";
import OneClickAppInstall from "../Components/CommonHostingSections/OneClickAppInstall";
// IMAGE
import transfer from "../assets/images/hosting-features/website_transfer.png";
import clock from "../assets/images/hosting-features/clock.png";
import clients from "../assets/images/hosting-features/clients.png";
import data_center from "../assets/images/hosting-features/data_center.png";
import backup from "../assets/images/hosting-features/daily_backup.png";
import enterprise_standard from "../assets/images/hosting-features/enterprise_standard.png";

const BusinessHosting = () => {
  // HOSTING FEATURES
  const hostingFeatures = [
    {
      id: 1,
      icon: clients,
      title: "Few Users Per Server"
    },
    {
      id: 2,
      icon: data_center,
      title: "Pure SSD storage"
    },
    {
      id: 3,
      icon: backup,
      title: "Backups: Twice a Week"
    },
    {
      id: 4,
      icon: transfer,
      title: "Free Website Migration"
    },
    {
      id: 5,
      icon: enterprise_standard,
      title: "Enterprise Standard"
    },
    {
      id: 6,
      icon: clock,
      title: "99.99% Uptime"
    }
  ]

  // INFO LIST FOR WEB HOSTING SPECS
  const infoList = {
    one: [
      { id: 1, name: "LitesSpeed Web Server" },
      { id: 2, name: "Cloudlinux OS" },
      { id: 5, name: "Imunify360 Firewall" },
      { id: 4, name: "Backups: Twice a Week" }, 
      { id: 3, name: "SSH Access (Jailed)" },
      { id: 6, name: "Cron Jobs " },
    ],
    two: [
      { id: 4, name: "99.9% Server Uptime Guarantee" },
      { id: 1, name: "30 Day Money Back Guarantee" },
      { id: 2, name: " Free Website Transfer " },
      { id: 3, name: "24/7 Technical Support" },   
      { id: 5, name: "FREE Let's Encrypt SSL Certs" },
      { id: 6, name: "Free .COM domain Registration/Transfer" },

    ],
    three: [
      { id: 1, name: "cPanel Control Panel" },
      { id: 2, name: "Softaculous Script Installer" },
      { id: 3, name: "Node.js Version Selector" },
      { id: 4, name: "ionCube PHP Loader" },
      { id: 6, name: "Spam Protection" },
      { id: 5, name: "Awstats & Webalizer" },
  
    ]
  }

  return (
    <Fragment>
      <Header />
      <HeaderSmall
        heading="Business Web Hosting"
        desc="Optimized for small to high traffic websites."
      />
      <BusinessHostingPlansTop />
      <WebHostingSpecs
        infoList={infoList}
        titlePartOne="Advanced"
        titlePartTwo="Features"
        description=""
      />
      <BusinessHostingPlans />
      <ExtraFeatures
        icon_1="monetization_on"
        icon_2="monetization_on"
        icon_3="monetization_on"
        heading_1="cPanel Control Panel"
        heading_2="Litespeed Web Server"
        heading_3="24/7/365 Live Support"
        desc_1="With our cPanel’s responsive interface, you can manage your web hosting from anywhere, whether you are using a laptop, desktop, tablet, or smartphone."
        desc_2="LiteSpeed web server is the #1 commercial web server and provides better performance than any other web hosting server, like Apache or Nginx. "
        desc_3="We are committed to giving you 24/7/365 technical support, Our LevoHost team is always here to help our valuable customers."
      />
      <HostingAwesomeFeatures
        titlePartOne="Awesome Features Of"
        titlePartTwo="Business Hosting"
        description="We know the value of your website's speed and security. In our Business hosting packages, we've added more awesome features, like- free domain registration/transfer, free SSL certificate, free website migration, few users per server, weekly two times backup, and many more premium features with the enterprise standard."
        hostingFeatures={hostingFeatures}
      />
      <WhyChooseLevoHost />
      <OneClickAppInstall />
      <FaqBusinessHosting />
      <MoreFeatureSlider />
      <SupportBlock />
      <Footer />
    </Fragment>
  );
};

export default BusinessHosting;
