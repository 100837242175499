import React from 'react';
import { Button } from 'react-bootstrap'

const ReadyToStart = () => {
    return (
        <div className="ready-to-start text-center">
            <h3 className="text-white font-42 mb-3">Ready To Get Started?</h3>
            <p className="text-white font-18 mb-4">Choose one of the hosting plan above and order now. We will setup your account instantly. It’s risk-free with our 30-days money back guarantee.</p>
            <a href="#hostingPlans">
                <Button variant="outline-light" className="btn-border-1 mt-4">get started</Button>
            </a>
        </div>
    );
};

export default ReadyToStart;
