import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import { Button, Image } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import comodo from "../../assets/images/brand-comodo.svg";
import digicert from "../../assets/images/digicert.png";
import Thawte from "../../assets/images/brand-thawte.svg";
import geotrust from "../../assets/images/geotrust.png";
import { useSelector } from "react-redux";
import { currencies } from "../../constants/currencies";

const SSLHostingPlanSlider = () => {
  const { currency } = useSelector(state => state.currency);
  const SSLPlanData = [
    {
      id: 1,
      img: comodo,
      title: "Comodo Positive SSL (DV)",
      regular_price: {
        BDT: "TK 1275",
        USD: "$14.99"
      },
      discounted_price: {
        BDT: "TK 765",
        USD: "$8.99"
      },
      plan_features: [
        "1 DOMAIN SECURED",
        "up to 256-bit encryption",
        "Comes with $10,000 Warranty",
        "Fast SSL Issuance"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/comodo-positive-ssl"
    },
    {
      id: 2,
      img: comodo,
      title: "Positive SSL Wildcard (DV)",
      regular_price: {
        BDT: "TK 8415",
        USD: "$99"
      },
      discounted_price: {
        BDT: "TK 5600",
        USD: "$65.99"
      },
      plan_features: [
        "1 + all subdomains",
        "up to 256-bit encryption",
        "Comes with $10,000 Warranty",
        "Fast SSL Issuance"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/positive-ssl-wildcard"
    },
    {
      id: 3,
      img: comodo,
      title: "Comodo EV SSL",
      regular_price: {
        BDT: "TK 5100",
        USD: "$60"
      },
      discounted_price: {
        BDT: "TK 4755",
        USD: "$55.99"
      },
      plan_features: [
        "Secures www and non-www domains",
        "Up to 256-bit encryption",
        "Comes with $1,750,000 Warranty",
        "Issuance Time: 2 Weeks"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/comodo-ev-ssl"
    },
    {
      id: 4,
      img: comodo,
      title: "PREMIUM SSL WILDCARD",
      regular_price: {
        BDT: "TK 14875",
        USD: "$175"
      },
      discounted_price: {
        BDT: "TK 13850",
        USD: "$162.99"
      },
      plan_features: [
        "1 DOMAIN AND ITS SUBDOMAINS SECURED",
        "Up to 256-bit encryption",
        "Comes with $250,000 Warranty",
        "Issuance Time: 2 Day"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/premiumssl-wildcard"
    },
    {
      id: 5,
      img: comodo,
      title: "Comodo Instant SSL",
      regular_price: {
        BDT: "TK 3650",
        USD: "$42.99"
      },
      discounted_price: {
        BDT: "TK 1699",
        USD: "$19.99"
      },
      plan_features: [
        "1 DOMAIN SECURED",
        "Up to 256-bit encryption",
        "Comes with $50,000 Warranty",
        "Issuance Time: 2 Day"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/instant-ssl"
    },
    {
      id: 6,
      img: comodo,
      title: "Positive SSL Multi-Domain",
      regular_price: {
        BDT: "TK 3059",
        USD: "$35.99"
      },
      discounted_price: {
        BDT: "TK 2460",
        USD: "$28.99"
      },
      plan_features: [
        "3-100 SANS SECURED",
        "up to 256-bit encryption",
        "Comes with $10,000 Warranty",
        "Fast SSL Issuance"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/positive-ssl-multi-domain"
    },
    {
      id: 7,
      img: comodo,
      title: "EV Multi-Domain SSL",
      regular_price: {
        BDT: "TK 11475",
        USD: "$135"
      },
      discounted_price: {
        BDT: "TK 11049",
        USD: "$129.99"
      },
      plan_features: [
        "3-100 SANS SECURED",
        "Up to 256-bit encryption",
        "Comes with a $1,750,000 Warranty",
        "Issuance Time: 2 Weeks"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/ev-multi-domain-ssl"
    },
    {
      id: 8,
      img: comodo,
      title: "Comodo Unified Communications",
      regular_price: {
        BDT: "TK 6630",
        USD: "$78"
      },
      discounted_price: {
        BDT: "TK 6200",
        USD: "$72.99"
      },
      plan_features: [
        "3-100 SANS SECURED",
        "Up to 256-bit encryption",
        "Comes with a $250,000 Warranty",
        "Issuance Time: 2 Days"
      ],
      btn_link: "https://my.levohost.com/store/server-sync-tool-auto-created-products/comodo-unified-communications"
    },
    {
      id: 9,
      img: Thawte,
      title: "Thawte SSL Web Server",
      regular_price: {
        BDT: "TK 17850",
        USD: "$210"
      },
      discounted_price: {
        BDT: "TK 10200",
        USD: "$120"
      },
      plan_features: [
        "1-100 domains secured",
        "Up to 256-bit Encryption",
        "Comes with a $1,250,000 Warranty",
        "Issuance Time: 3 Days or less"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/thawte-ssl-web-server"
    },
    {
      id: 10,
      img: Thawte,
      title: "Thawte Wildcard SSL",
      regular_price: {
        BDT: "TK 44200",
        USD: "$520"
      },
      discounted_price: {
        BDT: "TK 34849",
        USD: "$409.99"
      },
      plan_features: [
        "1 domain &amp; all subdomains",
        "Up to 256-bit Encryption",
        "Comes with a $1,250,000 Warranty",
        "Issuance Time: 3 Days or less"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/thawte-wildcard-ssl"
    },
    {
      id: 11,
      img: geotrust,
      title: "GeoTrust TBID EV SSL",
      regular_price: {
        BDT: "TK 26350",
        USD: "$310"
      },
      discounted_price: {
        BDT: "TK 14080",
        USD: "$165.65"
      },
      plan_features: [
        "1 domain secured",
        "Up to 256-bit Encryption",
        "Comes with a $1,500,000 Warranty",
        "Issuance Time: 2 Weeks"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/geotrust-tbid-ev-ssl"
    },
    {
      id: 12,
      img: geotrust,
      title: "GeoTrust TBID Wildcard SSL",
      regular_price: {
        BDT: "TK 44200",
        USD: "$520"
      },
      discounted_price: {
        BDT: "TK 41309",
        USD: "$485.99"
      },
      plan_features: [
        "1 domain &amp; all subdomains",
        "Up to 256-bit Encryption",
        "Comes with a $1,250,000  Warranty",
        "Issuance Time: 3 Days or less"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/geotrust-tbid-wildcard-ssl"
    },
    {
      id: 13,
      img: digicert,
      title: "DigiCert Secure Site Wildcard SSL",
      regular_price: {
        BDT: "TK 186915",
        USD: "$2199"
      },
      discounted_price: {
        BDT: "TK 161415",
        USD: "$1899"
      },
      plan_features: [
        "1-100 domains secured",
        "Up to 256-bit Encryption",
        "Comes with $1.75 Million Warranty",
        "Issuance Time: 3 Days or less"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/digicert-secure-site-wildcard-ssl"
    },
    {
      id: 14,
      img: digicert,
      title: "DigiCert EV Code Signing",
      regular_price: {
        BDT: "TK 60775",
        USD: "$715"
      },
      discounted_price: {
        BDT: "TK 52359",
        USD: "$615.99"
      },
      plan_features: [
        "Displays Publisher Name",
        "Up to 256-bit Encryption",
        "Issuance Time: 1-5 Business Days",
        "15 days refund policy"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/digicert-ev-code-signing"
    },
    {
      id: 15,
      img: digicert,
      title: "DigiCert Secure Site EV SSL",
      regular_price: {
        BDT: "TK 101915",
        USD: "$1199"
      },
      discounted_price: {
        BDT: "TK 80700",
        USD: "$949.49"
      },
      plan_features: [
        "1-100 Domains Secured",
        "Up to 256-bit Encryption",
        "Comes with $1.75 Million Warranty",
        "Issuance Time: 2 Weeks or less"
      ],
      btn_link: "https://my.levohost.com/store/ssl-certificate/digicert-secure-site-ev-ssl"
    }
  ]
  return (
    <div className="dedicated-hosting-plan-slider">
      <SectionHeading
        heading="Our SSL Certificate Plans"
        desc="Security is a basic right. That’s why we offer cheap SSL to help you succeed."
      />
      <br />
      <br />
      <div className="hosting-plan-slider-inner">
        <Container>
          <Row>
            {SSLPlanData.map(plan =>
              <Col md={6} lg={4} xl={3} key={plan.id}>
                <div className="hosting-plan-card">
                  <div className="plan-top">
                    <div className="logo">
                      <Image src={plan.img} />
                    </div>
                    <p>{plan.title}</p>
                    <h3>
                      <span className="rrp_price">{
                        currency === currencies.BDT ?
                          plan.regular_price.BDT
                          : plan.regular_price.USD
                      }</span>
                      <span className="main_price">{
                        currency === currencies.BDT ?
                          plan.discounted_price.BDT
                          : plan.discounted_price.USD
                      }</span>
                    </h3>
                    <a href={plan.btn_link}>
                      <Button>Order Now</Button>
                    </a>
                  </div>
                  <div className="plan-bottom">
                    {plan.plan_features.map(feature => <p key={feature}>{feature}</p>)}
                  </div>
                </div>
              </Col>
            )}

          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SSLHostingPlanSlider;
